import React from "react";
import styled from "styled-components";

import BlockParty from "./images/Expos/BlockParty.png";
import { mediumWidth, bigWidth } from "./ui/Constants";

const ExposStyled = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    img {
        width: 100%;
        height: auto;
    }

    @media only screen and (max-width: ${mediumWidth}) {
        width: 70vw;
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
        width: 40vw;
    }

    @media only screen and (min-width: ${bigWidth}) {
        width: 40vw;
    }
`;

export default () => {
    return (
        <ExposStyled>
            <div className="Title">Expos</div>
            <div>
                <img src={BlockParty} alt="" srcSet="" />
            </div>
        </ExposStyled>
    );
};
