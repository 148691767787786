import React from "react";
import styled from "styled-components";

const OeuvreStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2vh;
    align-self: center;
    justify-self: center;
    transform: rotate(2deg);

    img {
        width: 100%;
        border: 3px solid #826251;
        border-top-left-radius: 255px 15px;
        border-top-right-radius: 15px 225px;
        border-bottom-right-radius: 225px 15px;
        border-bottom-left-radius: 15px 255px;
    }
`;

export type OeuvreType = {
    title: string;
    description: string;
    weight: string;
    height: string;
    width: string;
    imgsS: Array<string>;
    imgsXS: Array<string>;
};

interface OeuvreProps {
    oeuvre: OeuvreType;
    onClick: () => void;
}

const Oeuvre = (props: OeuvreProps) => {
    const { imgsXS } = props.oeuvre;

    return (
        <OeuvreStyled onClick={props.onClick}>
            {/* <div className="Description">{text}</div> */}
            <div className="Imgs">
                <img alt="img" src={imgsXS[0]}></img>
            </div>
        </OeuvreStyled>
    );
};

export default Oeuvre;
