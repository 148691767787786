import React, { ReactNode } from "react";

import {
    FaFacebookSquare,
    FaInstagram,
    FaPhoneSquare,
    FaEnvelopeSquare,
} from "react-icons/fa";
import styled from "styled-components";
import { color5, mediumWidth, bigWidth } from "./ui/Constants";

const ContactStyled = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .Network {
        display: flex;
        flex-direction: row;
        margin-bottom: 3vh;

        a {
            font-size: 4vw;
            color: ${color5};
        }
    }

    @media only screen and (max-width: ${mediumWidth}) {
        width: 70vw;

        .Network {
            span {
                font-size: 8vw;
                margin-right: 1vh;
            }

            a {
                font-size: 5vw;
            }
        }
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
        width: 40vw;

        .Network {
            span {
                font-size: 5vw;
                margin-right: 3vh;
            }

            a {
                font-size: 4vw;
            }
        }
    }

    @media only screen and (min-width: ${bigWidth}) {
        width: 40vw;

        .Network {
            span {
                font-size: 5vw;
                margin-right: 3vh;
            }

            a {
                font-size: 4vw;
            }
        }
    }
`;

const Contact = () => {
    let color = "#cea07e";

    return (
        <ContactStyled className="Contact">
            <div className="Title">Contact</div>
            <Network
                icon={<FaFacebookSquare color={color} />}
                url={
                    <a href="https://www.facebook.com/benoit.bertetto">
                        Benoit Bertetto
                    </a>
                }
            />

            <Network
                icon={<FaInstagram color={color} />}
                url={
                    <a href="https://www.instagram.com/benoit_bertetto_sculpteur/">
                        @benoit_bertetto_sculpteur
                    </a>
                }
            />

            <Network
                icon={<FaPhoneSquare color={color} />}
                url={<a href="tel:0634097179">06 34 09 71 79</a>}
            />

            <Network
                icon={<FaEnvelopeSquare color={color} />}
                url={
                    <a href="mailto:contact@benoitbertetto.com">
                        contact@benoitbertetto.com
                    </a>
                }
            />
        </ContactStyled>
    );
};

interface NetworkProps {
    url: ReactNode;
    icon: any;
}

const Network = (props: NetworkProps) => (
    <div className="Network">
        <span> {props.icon}</span>
        {props.url}
    </div>
);

export default Contact;
