import React from "react";

export const CloseCross = () => (
    <svg
        enableBackground="new 0 0 100 100"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 100 100"
    >
        <polygon points="77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 " />
    </svg>
);

export const TotemFull = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 804.21 2961.72">
        <defs></defs>
        <title>Logo</title>
        <g id="Calque_2" data-name="Calque 2">
            <path
                className="cls-1"
                d="M2490.27,775.75c48.75-30.26,97.18-35.19,115.25-36.28,61.09-3.68,106.48,16.69,129.94,27.55,12.48,5.78,110.7,46.79,150.77,159.33a390.55,390.55,0,0,1,19.75,85.87c1.3,10.87,7.23,43.69,3.67,104.69-2.69,46-8.56,77.39-14.24,124-5.59,45.92-11.65,96.69-8.26,164.39,2.17,43.4,1.14,57.25,15.61,134.53,10.77,57.53,19,90.57,23.88,107,10.05,33.86,30.16,88.32,37.19,106.07,12.24,30.88,21.82,53.44,25.71,77.14,4.82,29.27,6.56,62.21,5.51,72.54-7.66,75.47-4.56,70.7-10.55,147.5,0,0-11.14,149.88-23,279.5,0,0-19.74,276-9.18,577.67,1.55,44.27,3.23,98.15,6.43,142.34,3.86,53.39,5,82.85-.46,126.73-1.17,9.38-1.85,30.86-3.67,52.34-3.15,37.06-2,82.91-3.22,93.21-4.42,38.15-5.57,65.24-37.28,163.33-14.5,44.88-21.89,62.5-39.86,83.25-12.34,14.26-27.92,29.73-55.56,45.91-55.67,32.62-106.46,53.06-132.24,63.83-9.49,4-70.25,26.63-214.46,13.61-74.71-6.74-101.38-37.32-103.28-38.87-12.63-10.28-45.51-44.66-65.66-95-9.19-23-5.26-28.47,0-110.2,6.17-95.89,8.23-98.16,11-134.08,3.74-48.17,4.51-60.74,8.72-127.19.52-8.16,7.56-83.24,6.43-106.53-1.27-26.3-7.8-69.45-9.64-81.27-8.18-52.59-2.44-78.73,0-111.12,3-39.84,5.49-36.87,11-90.46,4.31-41.73,6.05-68.66,6.89-103.31,1-41.8,2.25-76.87-.46-121.22-3.36-54.88-11.56-95.56-16.53-125.35-4.81-28.8-9.09-57.85-22-116.63-7-31.9-17-69.68-31.69-132.24-18.9-80.33-20.85-88.25-34-146-14.91-65.63-22.82-98.44-26.17-121.68-2.85-19.79-13.67-86.38-9.64-171.72,1.49-31.55,8.51-74.57,20.66-158.42,16.4-113.15,18.07-113.7,25.26-172.19s10.32-99.93,16.07-178.61c7.07-96.78,8.77-105.22,8.72-133.62,0-13,3.89-64,6-78.52,1.57-10.94,9.22-62.26,56.48-120.3,13.93-17.11,14-14.49,69.79-66.58C2465.66,789.29,2469.17,788.83,2490.27,775.75Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2293.63,1330.93a522.48,522.48,0,0,1,77.49-50.94c27.74-14.9,53.29-28.62,86-36.21,35.32-8.2,47.87.85,141.06,5.39,75.63,3.67,92,4.39,120.84,9,64.06,10.2,101.59,25.46,127.13,47.62,9.18,8,26.9,23.74,33.69,49.86a85.39,85.39,0,0,1,2.7,23.36l-169.13-21.12c0-2.27.1-10.58.22-21.55.26-24.64.37-30.46-2.7-37.74-1.92-4.57-5.88-11.56-14.82-18.42-23.67-1.74-42.53-4.13-55.18-6-27.45-4-42.29-7.6-59.57-.26a61.94,61.94,0,0,0-19.12,13Q2559.12,1313,2556,1339q-39.53-.1-80-.9Q2382.38,1336.27,2293.63,1330.93Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-1"
                d="M2584.68,1272.68a64.41,64.41,0,0,0-22.41,14.22c-1.21,10.86-3.33,29.31-6.29,52.11-12.38,95.35-26.39,166-30.1,185.09-11.8,60.55-41.4,224.86-117.25,665.31l-1.8,50.31,5,14.67c45.41-5.94,84.32-8.55,114.49-9.73,38.16-1.48,59.24-2.31,89.4,0,44.22,3.39,56.72,9.58,92.54,10.33a328,328,0,0,0,79.51-8.08c-3.37-52.14-8.66-129.45-16.17-223.27-9.6-119.88-17.1-195.11-27-302.33-8-87.53-19.06-211.55-31.22-363.44.13-15.08.25-25.93.32-31.61.16-14.42.25-19.06-1.85-25.15-3.55-10.29-10.69-17-15.77-20.95-22-1.37-39.83-3.66-52.39-5.55C2616.12,1270.43,2602.11,1266.3,2584.68,1272.68Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2411.84,2254.39l17.75,34.08c6.09.67,14.9,1.6,25.51,2.57,36.73,3.36,86,5.6,187.64,4.74,45.29-.37,89-1.84,131-4.17q7-22.35,14-44.7a339.61,339.61,0,0,1-71.36,8.15c-38.14.28-50.65-6.15-95.82-10-35.54-3-61.14-1.89-104,0C2487.84,2246.36,2452.36,2249,2411.84,2254.39Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2441.83,2936.61c.83,12.53,199.23,7.65,275.54,15.08,2.16.21,16.36,1,44.77,2.45,36.42,1.91,48,2.32,49.41-1.89,1.94-5.84-16.86-16.84-19.53-18.41-20.22-11.84-38.62-13.85-65-16.74-65.65-7.22-63.44-7.1-65.86-7.24-35.46-2.06-62-.77-84.87.35-24.61,1.2-40.41,2.92-52.22,4.51a344.3,344.3,0,0,0-35.13,6.11C2469.39,2925.39,2441.52,2931.88,2441.83,2936.61Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-1"
                d="M2812.27,2951.53a87.33,87.33,0,0,0-22.6-18.8c-10.58-6.09-20.49-8.72-50.09-13.58-20-3.28-30-4.93-42.39-6.29a728.43,728.43,0,0,0-96.52-4c-6.8.16-24.64,1.37-60.33,3.8-41.57,2.83-66.15,4.92-92.28,20.11-2.7,1.57-4.86,2.95-6.23,3.86-.09-1.94-.18-4.73-.14-8.09.1-8.64,1.43-20.52,2.8-25.95,5.57-22.11,66.75-28.74,66.75-28.74a442.89,442.89,0,0,1,85.42-10.25,414.29,414.29,0,0,1,63.56,3.62c56.2,7.5,156.76,32.55,158.07,68.23C2818.42,2938.86,2817.7,2944.42,2812.27,2951.53Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-1"
                d="M2821.54,2999.53c-.63,8.15-6.06,13.82-7.92,15.77-29,30.35-81.78,42.88-81.78,42.88-76.27,21.81-112.3,11.25-163.13,26.08a98.61,98.61,0,0,1-32.27,4.42c-6-.2-19.67-1.23-43.33-11.49a121.55,121.55,0,0,1-15.55-8c-22.59-13.95-34.79-19.38-40.15-28.68-10.11-17.55-5.29-44.06,4.49-47.73,3.86-1.44,7.91.89,10.35,2.18,14.81,7.85,45.6,8.33,107.18,9.3,5.83.09,13.67.1,23.68-.68,2-.15,4.84-.4,9.14-.88,16.06-1.8,24.76-3.8,32.7-4.93,7.3-1,11.83-1,32.62-.59,40.12.85,52.51,1.29,52.51,1.29,25.31.9,49.08,1.8,78.79-2.17,0,0,23-1,25.64-9.73.37-1.24.78-3,1.68-3.12C2818.2,2983.21,2822.14,2991.72,2821.54,2999.53Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-1"
                d="M2816.19,2983.44"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-1"
                d="M2810.63,2951.52"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2778.08,2968.05"
                transform="translate(-2193.63 -736.53)"
            />
        </g>
        <g id="Calque_3" data-name="Calque 3">
            <path
                className="cls-2"
                d="M2468.33,2852.72c-.59-.1.94-13.34,12.8-94.16,5.48-37.35,9.85-68.16,12.8-89.12l28.7-176.54c21.25-77.76,27.51-93.39,29.25-93,3.61.84-10.83,71.17-50.76,283.88C2482,2785.52,2469.64,2853,2468.33,2852.72Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2717.64,3157.77c-.29,12.43-2.44,23.56-6.74,45.82-15.1,78.28-62.57,142.78-66,238.53-.38,10.59-1.23,33.93,4,35,5.42,1.15,14.56-21.82,55.26-144.2,4.78-14.38,1.35-4.14,48.51-186,6.12-23.61,13.54-52.16,2.7-59.3-8.33-5.47-26.69,2.14-35,14.83C2711.52,3115.9,2718.35,3128.07,2717.64,3157.77Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2495.28,3641.1c7.83,7.1,23.72,4.23,47.17,0,14.61-2.63,30.48-5.49,41.77-13.47,28.4-20.06,8.35-58.59,24.26-138.81,10-50.27,25.18-72.1,10.78-97-6.6-11.44-21.17-26.55-35-24.26-23.51,3.88-22.06,54-49.86,138.81C2508.76,3584.42,2479.62,3626.93,2495.28,3641.1Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2775.34,788.51c40.43,51.47,60.23,100.08,70.33,131.67,14.19,44.37,29.53,92.36,10.78,141.51-10.69,28-19.91,23.51-29.65,51.21-18.92,53.85,2.91,108,17.52,144.2a336.81,336.81,0,0,0,42.61,75.5c24.92-105.78,26.8-194.23,23.84-254.72-6.5-132.82-39.09-186.76-48.05-200.84C2834.68,833,2799.87,805,2775.34,788.51Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2995.3,1890.47c-20.23,85.3-36.28,155.29-47.21,203.52-62.17,274.31-63.13,283.23-78.16,319.4-26,62.59-35.94,92.37-38.33,119.17a190.11,190.11,0,0,0,.59,37.16c11.46,129.27,35,485,32.78,623.32-1.71,107.18-21.71,263.24-111,455.48,36.71-14.42,94.17-43.61,136.22-102.4,37.64-52.63,45.35-104.74,59.24-206.82a1425.28,1425.28,0,0,0,12.6-220.6c-12.74-187.51-14.33-347.37-12.36-470.89,1.56-98.17,6.76-204.79,20.52-418C2979.52,2085.81,2988.83,1968.09,2995.3,1890.47Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2819.54,2000.62c-8.31-299.51-36.19-504.43-43.91-559.27-4.67-33.2-8.88-60.22-11.59-77.16l-50.6-6.32c7.7,120.65,15.18,221,20.89,293.89,11.22,142.9,19,199.23,24.76,310.13q7.39,142.65,14.78,285.3-.06,22.21-.11,44.42l46.94-2.11C2823,2174.48,2821.64,2076.39,2819.54,2000.62Z"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M3116.34,3016"
                transform="translate(-2193.63 -736.53)"
            />
            <path
                className="cls-2"
                d="M2491.09,3076.29c-.05.16,36.43,17.86,88.56,24.38,27.32,3.42,103.23,12.93,171.94-29.13,35.93-22,58.9-51.36,58.06-52.34-.61-.72-14.85,13.06-38.88,25-13.46,6.69-26,10.82-42.26,14.9-52.34,13.12-66.08,6.3-118.9,17.34-31.32,6.54-34.7,10.65-55,11.08A168.5,168.5,0,0,1,2491.09,3076.29Z"
                transform="translate(-2193.63 -736.53)"
            />
        </g>
    </svg>
);

export const Art = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327.05 232.83">
        <title>Fichier 1</title>
        <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_2-2" data-name="Calque 2">
                <path
                    className="art"
                    d="M2.14.52C44.79.37,37.68,1,85.22,4.25,119.47,6.6,207.83,13.9,240.5,17.07c29.2,1.8,78.82-1.88,83.56,4,3.16,3.95,2.76,11.88,1.79,16.62a26.46,26.46,0,0,1-2,6.07c-6.39-.3-7.82-.06-7.86.22-.06.53,4.63.67,5.62,3,1.24,3-3.92,8-4.5,8.19-11.9,4-21.85-1-51,.68,0,0-24.65.22-32.58-2.94-3.64-1.45-4.78-3.09-11.45-4-6-.84-16.82.2-27.51-2.58-1.17-.3,3.79,2.58,2.58,4.27-1.39,1.94-7.55,2.13-8.84,1.85-10.76-2.27-21.75-.87-25.08-.84C122.77,52.08,17.51,58,10,48.52c-.82-1.06.14-4.57-1.34-9.44C7,33.54,4.79,32,5.55,30.1c1.75-4.3,11.68.89,12.92-3.2-9-5.66-13.49.34-16.06-3.09-1.53-2-.33-4.59.22-10C3.49,5.44-2.18,4.28,2.14.52Z"
                />
                <path
                    className="art"
                    d="M79.08,28.45c-2-.47-6.49-1.53-8.67,1.09a6.13,6.13,0,0,0-.36,6.5c2.41,4.51,10.81,5.13,15,1.8.58-.46,2.16-1.72,2.16-3.43C87.21,31.35,82.1,29.17,79.08,28.45Z"
                />
                <path
                    className="art"
                    d="M86.3,10.93c-2.94-.58-6.58,1.2-6.68,3.25C79.54,16,82.1,17.81,84.5,18c2.75.19,5.86-1.79,5.77-3.8C90.21,12.54,88,11.27,86.3,10.93Z"
                />
                <path
                    className="art"
                    d="M119.17,43.26c-3.58-1.73-8.73.15-8.85,1.81-.08,1.17,2.36,2.36,4.15,2.71,3.16.61,6.78-.88,6.87-2.35C121.41,44.34,119.53,43.43,119.17,43.26Z"
                />
                <path
                    className="art"
                    d="M35.7,53.32a323.35,323.35,0,0,0-.33,54.23c-.95,54.18,1.27,87.59,1.27,87.59a72.86,72.86,0,0,1,26.18-7.58v-78q.55-27.84,1.09-55.67Z"
                />
                <path
                    className="art"
                    d="M96.6,63.67c13.72-2.71,117.75,1.62,124.61,1.08,3.25,3.61.9,22.22.9,22.22a50.49,50.49,0,0,1-3.25,10.11c-24.2-1.27-110-.72-120.64-4.34C88.89,87.87,86.93,76.51,89.37,74c1.14-1.18,3.76.23,5.78-1.44C97.76,70.36,96.41,65.53,96.6,63.67Z"
                />
                <path
                    className="art"
                    d="M81.25,105c-3.81.75-4.88,2.86-4.7,3.44.66,2.16,25.82,1.44,25.82,1.44a6,6,0,0,0,.55,2.71c6.5,2.2,19.86,0,19.86,0s-7.75,2.64-22,3.25c-12.3.52-19.69-1-22,3.07-.92,1.59-1.12,4.12,0,5.42,1.27,1.48,3.5.47,6.68,1.62a13.64,13.64,0,0,1,5.24,4s41.94,2,60.86,1.26c22.12-.84,33.18-1.27,47.13,2.53,8.13,2.21,26.83,2.88,32.33,1.17,1.48-1.73-.32-4.12,3.61-8,2.55-2.54,4.58-3.68,4.34-5.24-.38-2.37-5.63-3.4-5.42-4.15.14-.52,2.52.2,3.43-.91,1-1.26-.49-4.09-.72-4.51-3.15-5.84-17.95-6.25-21.67-6.32-31.14-.61-54-1.63-54-1.63s-4.08-3.7-8.31-3.79c-3.89-.08-6.56,2.75-7.77,2.89C127.48,105.21,105.08,100.33,81.25,105Z"
                />
                <path
                    className="art"
                    d="M89.55,148.73c3.5-5,16.62,1.08,32.15-.9,3.22-.41,3-1.63,6.32-2.53,8.33-2.28,15.29.43,18.6.54,26.31.9,34.35.53,53.82,2.17,1.48.12,8.41,2.73,10.65,0,1.34-1.63,0-3.84,1.45-5.06,1.86-1.6,6.66.06,9.57,2.89,2.15,2.08,2.82,4.34,4.15,8.85a46.54,46.54,0,0,1,1.81,10.65c.29,4.25,1.21,7.15,0,7.59-7.4,2.72-34-1.22-70.61-1.63-11.16-.12-54.72.18-67.19-.54-1.55-.09-5.51-2.79-5.77-6-.15-1.7.9-2.68.36-4.69-.62-2.27-2.69-1.54-2.43-2.76S85.05,155,87,153.24,88.66,150,89.55,148.73Z"
                />
                <path
                    className="art"
                    d="M255.92,55.77S294.16,54,296,56.33s1.59,8.57,2,11a62.94,62.94,0,0,1,.9,11C298.79,92,295.79,210,295.79,210s-4.05,5.28-6.86,4.7c-2.2-.46-2.84-3.39-4.33-6.5-5.68-11.87-18.76-16.32-20.23-16.8A30.81,30.81,0,0,0,253,190.09Z"
                />
                <path
                    className="art"
                    d="M34.29,198.39c3.82-4.94,14.13-10.21,31.06-10.47,22.23-.34,21.85.9,39.55,2.53,2.07.19,11.82-1,16.26-1.81,10.72-1.85,44.76.69,47.13.72,17,.22,27.25,1.25,43,1.54,13.58.25,24.08-2.52,41.66-.81,9.23.89,15.23,2.25,21.1,6.17,8.34,5.57,12.29,13.57,14.07,18.1l-48.53-1.7s20.77,1.13,35.22,3.61c2.19.38,3.72,2,7,3.07,8.66,2.78,12.95.73,17.7,4.16,2,1.4,6.86,6.14,4.69,7-5.58,2.33-28.37,2.33-52.91.36-25.93-2.07-52-1.19-78-1.8-68.91-1.62-103.37-2.43-106-1.81-6.45,1.53-13.51.9-27.63-.36-2.1-.19-6.28-.61-7.59-3.25-1.38-2.81,1.73-5.58.54-10.84-.57-2.57-1.49-2.66-1.8-4.87C30.24,203.64,33.15,199.87,34.29,198.39Z"
                />
                <path
                    className="art"
                    d="M230.06,26.47c.39-1.9,28,.85,28,2.88,0,1.29-11.1,3.49-21.85,0C235.11,29,229.88,27.31,230.06,26.47Z"
                />
                <path
                    className="art"
                    d="M51.09,55q-.26,34.68-.19,69.89.06,31.72.37,63Z"
                />
                <path className="art" d="M278.28,59q-.82,46.69-1.63,93.37Z" />
                <path className="art" d="M287.67,208.51,288.93,90v-.19Z" />
                <path className="art" d="M265.27,190.45q1-53.37,2-106.74Z" />
                <path className="art" d="M61.38,201.46l107.09,2.71Z" />
                <path className="art" d="M253.9,219.34l-86.33-1.08Z" />
                <path className="art" d="M209.11,75.59,111.59,72.7Z" />
                <path className="art" d="M132,85.16l59.24,2.35Z" />
                <path className="art" d="M152.76,113.33l58.88.91Z" />
                <path className="art" d="M121.34,121.64l53.27.54Z" />
                <path className="art" d="M128.2,155.23l68.09,1.27Z" />
                <path className="art" d="M95.15,163.72l37.38.18Z" />
            </g>
        </g>
    </svg>
);

export const Mask1 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 998.71 1433.08">
        <g id="Mask1" data-name="Calque 2">
            <g id="_2-tracé-head" data-name="2-tracé-head">
                <path
                    className="cls-1"
                    d="M469,1430c4.76-1,13.66-3,21.34-10.41,5.28-5.06,7.84-10.58,10.29-17,7-18.36,8.36-31.29,13.63-59.28,2.11-11.22,2.07-10.07,5.14-26.22,0,0,3.38-17.81,6.43-35.36,5.86-33.77,11.75-112.85,15.94-154.29,6.73-66.5,12.19-120.57,29.83-171.77a629.81,629.81,0,0,0,20.57-71c2.64-11.65,5.15-24.28,9.26-49.88,5.32-33.19,10.15-63.3,13.88-101.83,2.79-28.82,1.35-25,5.15-148.12,1.56-50.78,2.58-76.72-10.29-109.54-11.81-30.1-26.22-42.83-30.34-46.28-22.62-19-48.19-21.3-74.06-23.66-29.33-2.67-54.18-4.94-78.17,10.28-23.11,14.67-32.75,37.51-37.54,48.86-6.3,14.93-8.58,28.26-10.29,60.17-1.68,31.24-5.56,103.57,1.54,151.2a186.84,186.84,0,0,1,2.06,41.66c-.61,9.56-1.65,14-2.57,22.11-2.53,22.29-.38,39,1,52.46,5.58,53.54.44,67,8.74,150.69,3.45,34.68,5.37,42.87,6.3,68.65.9,25-.27,35.25.9,63.52,1,23.23,2.46,33.64,4.63,58.11,5.13,57.85,2.84,68.48,7.71,139.37,1.05,15.27,2.79,38.55,5.53,67.24.73,12.63,3.28,48.61,12.47,73,1.34,3.55,3.57,8.86,8.75,13C447.83,1434.5,463.55,1431.12,469,1430Z"
                />
                <path
                    className="cls-1"
                    d="M457.56,1314.63c-5.78,1.08-15.86,2.95-23.32,11.32-13.78,15.46-8.94,42.24,3.35,57.17,2.64,3.2,10.09,12,22.2,13.88a33.79,33.79,0,0,0,21.94-4.39c.18-.11.84-.49,1.63-1,10.93-7.14,15.44-19.7,17.4-25.37,2.08-6,4.73-14,3.43-24.34-.55-4.37-1.23-9.79-4.63-14.91C490.81,1313.78,470.49,1312.23,457.56,1314.63Z"
                />
                <path
                    className="cls-1"
                    d="M448.13,1340.52c11.52-9.16,27.72-3.25,34.29-.86,4.48,1.64,6.78,3.25,8.4,5.49,3.65,5.06,2.43,11.41,1.88,14.23a23.83,23.83,0,0,1-8.4,14.22c-5.94,4.71-12.37,4.88-15.94,5s-13.5.36-21-7.12c-1.7-1.69-8.66-9.15-6.94-18.77C441.65,1345.79,446.65,1341.7,448.13,1340.52Z"
                />
                <path
                    className="cls-1"
                    d="M509.33,972.12c-1.07,5.5-5.88,9.31-10,12.6a31.65,31.65,0,0,1-9.68,5.38c-4,1.31-6,2-8.36,1.35-4.86-1.28-5-5.73-12.33-11.52-1.08-.86-1.57-.89-4.83-2.9-4.27-2.63-6.41-3.95-7-4.67-3.52-4.28,2.47-14.85,4.8-18.69,0,0,.79-2.17,2.75-9.25,17.05-61.88,15.88-163.1,15.88-163.1-.79-68.72-1.49-114.39-2.06-147.6a21.18,21.18,0,0,1,41.66,2.06A1194.5,1194.5,0,0,0,510.36,768c-.79,46.2,1.39,85.8-3.09,119.31-2,15.16-1.53,30.58-3.25,45.77-1,9.18-2.1,15.81.68,24.18C507,964,510.37,966.77,509.33,972.12Z"
                />
                <path
                    className="cls-1"
                    d="M442,688.41a29.44,29.44,0,0,0-14.14.7,36.26,36.26,0,0,0-18.6,12.5c-9.54,11.92-14.19,34.51-1,49.37,8.45,9.54,23,14.55,36.17,10.28,13.74-4.44,19.41-16.82,20.74-19.71.61-1.32,7.86-17.88-1-34.63C462.4,703.76,455.81,691.35,442,688.41Z"
                />
                <path
                    className="cls-2"
                    d="M436.33,715c-4.65.18-10.43,4.06-10.15,9.33.22,3.9,3.67,7,7.33,7.91a9.45,9.45,0,0,0,10.14-3.81,9.21,9.21,0,0,0-1.55-11.59A8.42,8.42,0,0,0,436.33,715Z"
                />
                <path
                    className="cls-1"
                    d="M529.73,709c-2.51,6-10.91,26.17.17,43.71,5.9,9.33,18.56,19.91,33.77,18,16.61-2.08,24.84-17.61,26.4-20.57,6.2-11.7,4.71-22.89,3.95-28.63-1.23-9.22-3.38-25.38-17.15-32.74-12.57-6.72-28.32-2.61-37.54,5.48C533.88,699,531.64,704.4,529.73,709Z"
                />
                <path
                    className="cls-2"
                    d="M560.42,718.41c-4.84-1.15-9.27,2.24-11.07,5.89-1.36,2.76-2.11,7.48.91,10.73S558,738,561,736.69c3.86-1.64,7.19-5.92,6.37-10.73A9.47,9.47,0,0,0,560.42,718.41Z"
                />
                <path
                    className="cls-1"
                    d="M549.79,700.54c-12.08,6-19,20.61-15.87,34.34,3,13,14.93,24.59,28.23,23.46C578,757,584.68,738.6,585.39,736.57c.57-1.65,7.29-22-5.72-33.41C570.94,695.5,558.33,696.26,549.79,700.54Z"
                />
                <path
                    className="cls-1"
                    d="M408.14,724.67c.57-9.71,6.45-22.56,19.26-26.31A26.28,26.28,0,0,1,455.75,708c7,9.74,4.24,21,3.63,23.52-.48,2-4.82,18.51-19.62,21.86-12.27,2.78-23.84-5.29-28.71-14.63A27.66,27.66,0,0,1,408.14,724.67Z"
                />
                <path
                    className="cls-1"
                    d="M533.53,701.14q-.18-9.63-.37-19.26l10.14,9.7"
                />
                <path
                    className="cls-1"
                    d="M550.38,687.9l6.78-14.59,5.15,12.08"
                />
                <path
                    className="cls-1"
                    d="M569.37,685.59l8.42-11.51q.57,7.93,1.13,15.88"
                />
                <path
                    className="cls-1"
                    d="M582.45,692.72l13.92-13.5q-3.68,11.29-7.36,22.6"
                />
                <path
                    className="cls-1"
                    d="M591.44,706.54l10.33-.25-8.45,7.47"
                />
                <path className="cls-1" d="M595,725.19l8,4.17-7.68,2.32" />
                <path className="cls-1" d="M594,741.1l4.14,7.68-6.67-.51" />
                <path className="cls-1" d="M583.35,760.37l7.43,3.77-2.55-10" />
                <path className="cls-1" d="M566.9,770.2l14,14.13-1.32-20.46" />
                <path
                    className="cls-1"
                    d="M552.33,769.72q1,5.09,2.06,10.17l7.84-9"
                />
                <path
                    className="cls-1"
                    d="M538.91,762.34l-1.1,21,10.35-15.49"
                />
                <path
                    className="cls-1"
                    d="M527.36,747.89q-2.4,9-4.79,18l10-9.5"
                />
                <path className="cls-1" d="M524.52,736.86l-5,7.35,6.1-1.22" />
                <path
                    className="cls-1"
                    d="M527.65,714.36l-5.75-8.9,8.73,1.38"
                />
                <path
                    className="cls-1"
                    d="M524.44,729.3l-6.11-4.81L525.5,722"
                />
                <path className="cls-1" d="M401.29,717.26l-5.79-5.45,8-.85" />
                <path
                    className="cls-1"
                    d="M406.47,706.83l-7.88-13.45L412,697.66"
                />
                <path
                    className="cls-1"
                    d="M415.73,694.83l-4.29-16.2,12.82,11.8"
                />
                <path
                    className="cls-1"
                    d="M427.73,688.32q1-9,2-17.91l7.63,16.8"
                />
                <path
                    className="cls-1"
                    d="M441.53,687.55l12.77-15.86q-.42,10.59-.86,21.17"
                />
                <path
                    className="cls-1"
                    d="M456.52,696.48l11.32-3.79L462,703.37"
                />
                <path className="cls-1" d="M402,740.43l-7.15,10,10.92-1.3" />
                <path className="cls-1" d="M409.26,752.13l-3.82,13,11.54-7" />
                <path className="cls-1" d="M421,760l1.71,16.58,10-13.86" />
                <path
                    className="cls-1"
                    d="M437.16,762.69l11.91,14.43q.58-9.23,1.16-18.46"
                />
                <path
                    className="cls-1"
                    d="M455.16,755.16l12.08,4.13-5.58-11.52"
                />
                <path className="cls-1" d="M400.54,734.55l-9.67-4,8.66-4.37" />
                <path
                    className="cls-1"
                    d="M466.39,1350.63a12.79,12.79,0,0,0-7.46,1.29c-1.28.72-3.21,1.8-3.6,3.86-.53,2.77,2.18,5,2.31,5.14,1.75,1.41,3.69,1.39,7.46,1.28,5.13-.13,7.69-.2,9-2a6.34,6.34,0,0,0-.51-7.2C472,1351,469.63,1350.84,466.39,1350.63Z"
                />
            </g>
            <g id="Calque_16" data-name="Calque 16">
                <path
                    className="cls-1"
                    d="M443.67,1402.06l7.2,13.72,6.35-12.69Z"
                />
                <path
                    className="cls-1"
                    d="M463.56,1404.29l7.71,13.2q2.06-7.29,4.12-14.57Z"
                />
                <path
                    className="cls-1"
                    d="M482.07,1400.35l12.17,7.71q-1.37-7.55-2.74-15.08Z"
                />
                <path
                    className="cls-1"
                    d="M497.16,1386.46l4.63,3.77q.34-6.07.68-12.17Z"
                />
                <path
                    className="cls-1"
                    d="M430.64,1384.23l7.55,10.8-12.17-.51Z"
                />
                <path
                    className="cls-1"
                    d="M423.79,1362.12c.63,3.26,1.25,6.51,1.88,9.77l-5.31-3.26Z"
                />
                <path
                    className="cls-1"
                    d="M421.56,1350.12q.42-5.91.86-11.83l-5,5.31Z"
                />
                <path
                    className="cls-1"
                    d="M417.62,1314.12l4.8,14.91,4.11-9.77Z"
                />
                <path
                    className="cls-1"
                    d="M430.47,1297.83q1,7.64,2.06,15.26l10.29-7Z"
                />
                <path
                    className="cls-1"
                    d="M453.1,1288.92l-1.71,13.37,12.34-1Z"
                />
                <path
                    className="cls-1"
                    d="M481.56,1292l-8.4,10.46,14.23,2.23Z"
                />
                <path
                    className="cls-1"
                    d="M505.73,1304.69,495.27,1310l8.92,7.2Q505,1310.95,505.73,1304.69Z"
                />
                <path
                    className="cls-1"
                    d="M514.3,1316.69l-8.4,4.46,3.09,11.14Z"
                />
                <path className="cls-1" d="M508.82,1339l-.35,9.08,3.95-9.77Z" />
                <path
                    className="cls-1"
                    d="M506.59,1360.75c-.29,2.4-.57,4.8-.86,7.2l3.6-4.12Z"
                />
            </g>
            <g id="Calque_15" data-name="Calque 15">
                <path
                    className="cls-2"
                    d="M418.46,733.05a2.13,2.13,0,0,0-1.93,2.1A2,2,0,0,0,417.9,737a1.64,1.64,0,0,0,1.92-.85,2.41,2.41,0,0,0-.27-2.63A1.44,1.44,0,0,0,418.46,733.05Z"
                />
                <path
                    className="cls-2"
                    d="M425.33,739.89a2.12,2.12,0,0,0-1.94,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.41,2.41,0,0,0-.27-2.63A1.35,1.35,0,0,0,425.33,739.89Z"
                />
                <path
                    className="cls-2"
                    d="M435.08,742.33a2.13,2.13,0,0,0-1.93,2.11,2,2,0,0,0,1.36,1.8,1.64,1.64,0,0,0,1.92-.85,2.39,2.39,0,0,0-.27-2.63A1.43,1.43,0,0,0,435.08,742.33Z"
                />
                <path
                    className="cls-2"
                    d="M445.32,738.93a2.12,2.12,0,0,0-1.93,2.1,2,2,0,0,0,1.37,1.81,1.67,1.67,0,0,0,1.92-.85,2.43,2.43,0,0,0-.27-2.64A1.38,1.38,0,0,0,445.32,738.93Z"
                />
                <path
                    className="cls-2"
                    d="M414.81,724.49a1.54,1.54,0,0,0-1.51,1.41,1.42,1.42,0,0,0,1.07,1.23,1.4,1.4,0,0,0,1.51-.57,1.45,1.45,0,0,0-.22-1.78A1.21,1.21,0,0,0,414.81,724.49Z"
                />
                <path
                    className="cls-2"
                    d="M418,715a2.11,2.11,0,0,0-1.93,2.1,2,2,0,0,0,1.36,1.81,1.64,1.64,0,0,0,1.92-.85,2.39,2.39,0,0,0-.27-2.63A1.35,1.35,0,0,0,418,715Z"
                />
                <path
                    className="cls-2"
                    d="M424.55,706.45a2.11,2.11,0,0,0-1.93,2.1,2,2,0,0,0,1.36,1.81,1.64,1.64,0,0,0,1.92-.85,2.41,2.41,0,0,0-.26-2.64A1.4,1.4,0,0,0,424.55,706.45Z"
                />
                <path
                    className="cls-2"
                    d="M433,702.54a2.14,2.14,0,0,0-1.94,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.39,2.39,0,0,0-.27-2.63A1.43,1.43,0,0,0,433,702.54Z"
                />
                <path
                    className="cls-2"
                    d="M451.31,729.78a2.13,2.13,0,0,0-1.93,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.41,2.41,0,0,0-.27-2.63A1.44,1.44,0,0,0,451.31,729.78Z"
                />
                <path
                    className="cls-2"
                    d="M442.31,704a2.13,2.13,0,0,0-1.94,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.39,2.39,0,0,0-.27-2.63A1.39,1.39,0,0,0,442.31,704Z"
                />
                <path
                    className="cls-2"
                    d="M449.71,709.92a2.12,2.12,0,0,0-1.94,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.39,2.39,0,0,0-.27-2.63A1.35,1.35,0,0,0,449.71,709.92Z"
                />
                <path
                    className="cls-2"
                    d="M453.55,719.82a2.11,2.11,0,0,0-1.93,2.1,2,2,0,0,0,1.36,1.81,1.65,1.65,0,0,0,1.92-.85,2.41,2.41,0,0,0-.26-2.64A1.4,1.4,0,0,0,453.55,719.82Z"
                />
                <path
                    className="cls-2"
                    d="M542.77,736.78a2.12,2.12,0,0,0-1.94,2.1,2,2,0,0,0,1.37,1.81,1.67,1.67,0,0,0,1.92-.85,2.43,2.43,0,0,0-.27-2.64A1.37,1.37,0,0,0,542.77,736.78Z"
                />
                <path
                    className="cls-2"
                    d="M549.63,743.61a2.12,2.12,0,0,0-1.93,2.1,2,2,0,0,0,1.36,1.81,1.65,1.65,0,0,0,1.93-.85,2.41,2.41,0,0,0-.27-2.63A1.46,1.46,0,0,0,549.63,743.61Z"
                />
                <path
                    className="cls-2"
                    d="M559.38,746.06a2.12,2.12,0,0,0-1.93,2.1,2,2,0,0,0,1.37,1.81,1.65,1.65,0,0,0,1.92-.85,2.43,2.43,0,0,0-.27-2.64A1.38,1.38,0,0,0,559.38,746.06Z"
                />
                <path
                    className="cls-2"
                    d="M569.63,742.65a2.12,2.12,0,0,0-1.94,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.41,2.41,0,0,0-.27-2.64A1.37,1.37,0,0,0,569.63,742.65Z"
                />
                <path
                    className="cls-2"
                    d="M539.12,728.21a1.53,1.53,0,0,0-1.51,1.42,1.39,1.39,0,0,0,1.07,1.22,1.37,1.37,0,0,0,1.5-.57,1.46,1.46,0,0,0-.21-1.78A1.23,1.23,0,0,0,539.12,728.21Z"
                />
                <path
                    className="cls-2"
                    d="M542.27,718.73a2.13,2.13,0,0,0-1.93,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.41,2.41,0,0,0-.27-2.63A1.44,1.44,0,0,0,542.27,718.73Z"
                />
                <path
                    className="cls-2"
                    d="M548.85,710.17a2.12,2.12,0,0,0-1.93,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.41,2.41,0,0,0-.27-2.63A1.4,1.4,0,0,0,548.85,710.17Z"
                />
                <path
                    className="cls-2"
                    d="M557.28,706.27a2.11,2.11,0,0,0-1.93,2.1,2,2,0,0,0,1.36,1.81,1.67,1.67,0,0,0,1.93-.85,2.43,2.43,0,0,0-.27-2.64A1.4,1.4,0,0,0,557.28,706.27Z"
                />
                <path
                    className="cls-2"
                    d="M575.62,733.51a2.12,2.12,0,0,0-1.94,2.1,2,2,0,0,0,1.37,1.81,1.65,1.65,0,0,0,1.92-.85,2.41,2.41,0,0,0-.27-2.64A1.37,1.37,0,0,0,575.62,733.51Z"
                />
                <path
                    className="cls-2"
                    d="M566.61,707.71a2.12,2.12,0,0,0-1.93,2.1,2,2,0,0,0,1.36,1.81,1.65,1.65,0,0,0,1.93-.85,2.41,2.41,0,0,0-.27-2.63A1.46,1.46,0,0,0,566.61,707.71Z"
                />
                <path
                    className="cls-2"
                    d="M574,713.64a2.12,2.12,0,0,0-1.93,2.1,2,2,0,0,0,1.36,1.81,1.65,1.65,0,0,0,1.93-.85,2.41,2.41,0,0,0-.27-2.63A1.46,1.46,0,0,0,574,713.64Z"
                />
                <path
                    className="cls-2"
                    d="M577.85,723.54a2.12,2.12,0,0,0-1.93,2.1,2,2,0,0,0,1.37,1.81,1.64,1.64,0,0,0,1.92-.85,2.41,2.41,0,0,0-.27-2.63A1.36,1.36,0,0,0,577.85,723.54Z"
                />
            </g>
            <g id="Calque_7" data-name="Calque 7">
                <path
                    className="cls-1"
                    d="M604.51,815.24a14.63,14.63,0,0,0,6.31,5.51c3,1.3,4.25.4,8.91,1.71,3,.85,3.35,1.47,7.54,3.09,3.9,1.5,4.16,1.18,8.92,2.74a78.51,78.51,0,0,1,14.4,6.17c4.07,2.3,3.15,2.34,5.48,3.43,6.85,3.2,10.09.6,17.15,2.74,4.58,1.4,3.3,2.52,11,6.18,6.38,3,7,2.15,13.71,5.14,7.64,3.42,7.36,4.84,13.72,7.2s7,1.13,12.34,3.43c5.77,2.49,7.08,4.83,11.66,7.54,3.76,2.23,9.64,4.63,18.51,4.8"
                />
                <path
                    className="cls-1"
                    d="M606.41,803A33.54,33.54,0,0,0,618,808.75c4,1.08,4.82.46,8.91,1.71A41.2,41.2,0,0,1,639.27,817c1.66,1.18,1.55,1.25,7.2,5.83,4,3.21,6.12,4.89,6.86,5.48,6.54,5.3,8.08,11.47,18.51,28.46,5,8.09,6.67,10,7.55,14.74,1,5.25-.55,6,.68,13,1.12,6.36,2.87,8.63,1.72,12.69-.92,3.21-2.42,3.19-4.12,6.51-2.69,5.27-.31,8.06-.68,18.17a84.22,84.22,0,0,1-2.4,16.12c-.45,1.94-1.46,6.09-2.75,12.34-.29,1.44-.53,2.63-.68,3.43"
                />
                <path
                    className="cls-1"
                    d="M608.09,791.71c2.48.47,6.28,1.18,10.95,1.95,11.85,2,12.27,1.55,17.49,2.75,7,1.6,6,2.3,18.17,5.82,8,2.32,8.85,2.14,12,3.78,2.57,1.33,2.16,1.53,11.66,7.88,4.22,2.83,6.07,4,8.91,4.8,3.19.94,3.75.35,5.83,1.37,3.91,1.93,3.41,4.74,7.2,7.55,3.28,2.42,4.73,1.13,8.92,3.42,3.2,1.76,4.21,3.54,7.54,7.2a100.59,100.59,0,0,0,13.37,12,112.34,112.34,0,0,0,13,8.92"
                />
                <path
                    className="cls-1"
                    d="M609.75,780c1.57,2.34,3.88,5.07,6.21,4.76,2.87-.39,2.93-5,6.51-6.17s8.22,1.84,10.63,4.45,2.31,4.77,4.46,5.49c2.86,1,4.51-2.18,7.54-1.37,2.21.59,2,2.46,6.17,6.51,2.35,2.3,3.52,3.44,5.15,3.77,3.41.7,4.67-2.27,8.22-1.71,2.71.43,2.73,2.28,6.18,3.43s4.36-.42,7.2.34c5,1.34,4.47,6.8,10.28,10.29,3.29,2,3.72.38,8.92,2.4,6.44,2.5,6.13,5.07,12.68,8.23,5.89,2.83,7.15,1.23,12.34,4.45a27.9,27.9,0,0,1,7.55,6.86,27.57,27.57,0,0,1,4.45,8.91"
                />
                <path
                    className="cls-1"
                    d="M611.35,767.93a31.8,31.8,0,0,0,10.44,4.82c6.19,1.56,8-.1,14.4,1.71,5.24,1.49,5.23,2.92,10.63,4.12,4.91,1.08,5.3,0,9.94,1,5.34,1.21,5.74,2.9,11.66,4.8,4.27,1.37,4.26.55,11.65,2.05,3.64.74,7.11,1.75,14.06,3.77,4.81,1.4,8.46,2.47,13.37,4.12,9.45,3.18,9,3.65,15.43,5.48,5.05,1.45,6.49,1.5,9.94,3.09,4.73,2.18,4.51,3.24,8.23,4.46,5.09,1.66,7.58.37,9.94,2.74,1.78,1.78.86,3,2.75,4.8,2.13,2.06,3.79,1,8.23,2.74,3.91,1.56,3.5,2.75,7.88,4.46,3,1.19,3.89.87,6.17,2.06a16,16,0,0,1,5.49,4.8"
                />
                <path
                    className="cls-1"
                    d="M613,754.33a17.82,17.82,0,0,1,6.38,3c4.23,3.18,4.06,6.23,7.2,7.2s4.23-1.87,9.25-1.37a15.73,15.73,0,0,1,7.2,2.74c1.8,1.25,1.63,1.72,4.46,4.12s4.17,3.53,5.83,3.77c2.76.39,3.69-1.52,6.17-1s2.8,2.42,4.8,4.11c4,3.4,8.79,1.07,15.43,2.06a26.34,26.34,0,0,1,13,6.17"
                />
                <path
                    className="cls-1"
                    d="M615.21,733.64a26.08,26.08,0,0,0,7,2.06c4.55.65,6.75-.27,10.11,1.37,1.95.95,1.41,1.42,3.6,2.57,3.89,2.05,5.63.57,8.57,2.23,3.16,1.78,1.89,3.89,5.14,5.83,3,1.78,4.76.4,8.23,2.57a27.13,27.13,0,0,1,3.6,3.26c1.55,1.52,3.24,3.28,5.49,5.83,4.67,5.29,4.58,5.83,6.17,6.51,2.32,1,3.76.36,5.49,1.89a7.15,7.15,0,0,1,2,3.77c2.09,7.67,2,9.52,2.92,12,1.94,5.36,4.94,4.05,8.23,10.28,1,2,1.73,5.28,3.08,11.83,1.41,6.81,2.11,10.22,2.4,13.89.62,7.79-.72,8,.17,14.57.81,5.93,2,6.59,2.06,11.48,0,2.29-.49,5.13-1.54,10.8-1.64,8.86-2.72,11.15-1.2,14.06.63,1.22,1.6,2.31,1.54,4.12s-1.07,2.07-1.89,4.62c-1,3.12.13,4-1,5.66-1.29,1.92-3.51,2.11-3.43,3.26,0,.7.86.65,1.37,1.71.86,1.82-.34,4.46-.34,4.46l2,2.57"
                />
                <path
                    className="cls-1"
                    d="M613.6,749.08c1.37.3,3.57.75,6.3,1.13,5.94.82,7.33.36,11.57,1.28,4.71,1,4,1.83,7.72,2.32,3.22.42,4.49-.08,9.77.25,2.56.17,2.44.3,5.14.52,7,.57,8.28-.26,14.14.25a38.91,38.91,0,0,1,8.75,1.55c5.63,1.74,5.22,3.2,8.74,3.85,6.14,1.14,8.42-3.11,14.4-1.54,2.3.61,2.17,1.29,6.17,3.09a42.74,42.74,0,0,0,10,3.08"
                />
                <path
                    className="cls-1"
                    d="M616,725.24a24.49,24.49,0,0,0,10.34,1.82c7-.34,11.79-3.86,15.43-1.28,1.21.85,1.15,1.58,2.83,2.57,2.24,1.32,3.78.88,6.94,1.28a32.3,32.3,0,0,1,10,3.35,33.26,33.26,0,0,1,5.14,2.83c4.67,3.18,4.14,4.77,7.2,6.17,4.06,1.85,6.81-.11,8.49,2,1.2,1.56.12,3,1.54,5.4,1,1.73,2,1.64,3.86,3.35,1.23,1.11,1.44,1.7,5.65,9.51,2,3.68,2.22,4.07,2.58,5.14,1.14,3.47.25,4.08,1.28,6.43,1.12,2.56,2.34,2.24,5.66,6.43,1.89,2.39,2.84,3.62,3.08,5.4.39,2.8-1.36,3.3-1.28,5.92.1,3.19,2.73,3.34,6.17,9.77,1.54,2.88,2.31,4.32,2.31,6.43,0,1.31-.3,2.26.26,3.34.8,1.54,2.73,1,3.86,2.31.95,1.13.62,2.56.51,4.37-.21,3.47.63,6.43,2.32,12.35,1,3.5,1.22,3,2,5.91,2.39,8.47,0,10.93,2.58,15.94,1.09,2.12,2.7,3.73,5.91,7,1.6,1.6,2.68,2.48,5.91,5.65,2.06,2,3.09,3,3.35,3.35,4.32,5.36,1.54,9.59,5.14,12.85,2.57,2.33,5.39,1.44,6.94,4.38.44.83.18.82,1,3.6s1.34,4.32,2.32,5.65c1.72,2.34,2.92,1.6,3.85,3.35,1.49,2.78-1.18,5.44.26,8,.53.92,1.6,1.83,3.86,2.31"
                />
                <path
                    className="cls-1"
                    d="M616.32,721.13a11,11,0,0,1,5.38-.75c4,.48,5.89,3.17,8.74,2.31,1.13-.34.79-.74,2.32-1.54,3.8-2,8.41-.77,11.31,0,1.37.36,1.58.53,2.83.77,1.44.28,2.27.26,5.92.26h6.42c2.58.8,4.69,1.39,6.18,1.8,1.33.36,1.76.46,2.82.77,2.46.71,3.93,1.28,5.4,1.8a61.33,61.33,0,0,0,8,2.31c4.2.86,5-.39,7.2.77,2.44,1.3,2.15,2.89,4.37,3.6,2.68.87,4.23-1.11,6.17,0,1.69,1,.85,2.64,2.57,4.12,2.19,1.87,4.44-.05,8,1.8a15.38,15.38,0,0,1,3.6,3.08c2.6,2.62,2.31,3.28,4.12,4.63,2.4,1.8,3.75,1.25,4.62,2.83s-.23,3,.78,4.12,2.93-.14,4.62,1,.65,3,2.32,4.89c1.93,2.14,4.39.78,6.17,2.83,1.31,1.51.17,2.48,1.29,5.66,1,2.92,2.17,2.64,4.37,6.94,1,1.93.9,2.26,2.05,4.37a38.15,38.15,0,0,0,2.83,4.37c3.43,4.56,4.6,3.92,5.66,6.43,1.38,3.27-.44,4.79,1.29,6.94,1.47,1.84,3.39,1.49,4.37,3.6.68,1.47,0,2.09.77,3.35,1,1.5,2.22,1,3.86,2.57,1.45,1.42,1,2.38,2.31,3.6a6.46,6.46,0,0,0,4.63,1.54"
                />
                <path
                    className="cls-1"
                    d="M617.84,709.49c2.38,0,5.81,0,9.95,0,11.72-.1,14-.47,19,.34,2.17.36,1,.3,9.77,2.23,6.4,1.41,9.61,2.11,11,2.23,6.57.57,8.75-.81,12.17,1.2,2.56,1.51,1.77,2.55,4.29,3.94,3.8,2.11,5.88-.11,12.17,1.72,3.35,1,4.8,2.19,5.65,3.26,1.33,1.64.77,2.31,1.72,3.42,2.91,3.45,9.75-1.13,15.08,2.4,1.79,1.18,1.05,1.71,4.12,4.46,2.08,1.86,4.32,3.87,7.37,4.8,5.74,1.75,10.45-1.5,12.17.86.35.48.1.53,1,2.91a13.84,13.84,0,0,0,1.71,3.6c1.25,1.5,2.28,1.17,4.46,2.75a14,14,0,0,1,2.92,2.91c2.74,3.46,1.74,5.22,3.42,7.2,3.22,3.78,9.59.6,11.66,3.94,1,1.67-.55,2.46.17,6.52.41,2.3,1.31,4,3.09,7.54.26.51,1.14,2.23,2.57,4.63,0,0,5.31,8.94,4.84,9.89,0,0,0,0,0,0-.7.91,0,2.53.26,3.23a11,11,0,0,0,1.45,2.43,46.16,46.16,0,0,1,3.09,4.8,52.33,52.33,0,0,1,4.29,8.23,29.41,29.41,0,0,1,2.22,7.37c.2,1.34.25,2.48.69,4.8.67,3.5,1.2,4.23,1.54,4.63,1.25,1.43,2.55,1.2,3.09,2.4s-.13,2.62-.86,3.77"
                />
                <path
                    className="cls-1"
                    d="M616.62,716.66c2.55.1,6.56.21,11.51.2,1.78,0,6.76,0,14.23-.17,7.09-.13,9.43-.23,13,.69s3.26,1.49,5.65,1.71c5.29.5,8.3-2.23,11.32-.34,1.06.66.66,1,2.23,2.23a15.2,15.2,0,0,0,6.51,2.74c4.41.94,4.91-.15,8.06.86,1.65.52,1.66.87,5.48,3.08,4.21,2.44,6.31,3.66,8.4,4.29,4.86,1.46,6.35-.09,9.09,1.88,2.16,1.56,1.66,2.84,4.11,4.46s3.52.65,6.35,2.23c3,1.68,2.37,3.11,5.31,4.8s4.26.75,6.86,2.4a10.87,10.87,0,0,1,3.77,4.29"
                />
                <path
                    className="cls-1"
                    d="M617.32,699.73c6-2,9.86-1.46,12.35-.52a15,15,0,0,0,4.29,1.37c3.42.37,5.09-1.15,8.06-1.89,3.16-.79,3.63.36,12.34,1.72a116.56,116.56,0,0,0,11.83,1.2c3.9.19,5.14,0,11.48,0,7,0,10.54-.05,13,.17a50.86,50.86,0,0,1,20.06,5.65c5.52,3,9.82,5.28,13.37,10,1.74,2.28,3.18,5,6.34,5.83,2.78.74,5.09-.43,6.35,1,1.09,1.27-.28,2.62.51,4.29,1.82,3.83,11.35,1.48,13,5.31.49,1.13-.2,1.65.51,2.75,1.42,2.2,4.89,1.22,5.83,3.08.65,1.27-.67,2.32-.34,3.6.39,1.55,3,3,11.66,3.26"
                />
                <path
                    className="cls-1"
                    d="M618.87,694.06a17.24,17.24,0,0,1,5.49-1c2.84,0,4.06.7,7.37.86a30.84,30.84,0,0,0,5.49-.34c6.43-.63,7.85,1.57,14.91,1.88,6.41.29,6.44-1.48,13.37-1,2.16.14,2.4.33,9.43,1.54,11.82,2,10.72,1.44,12.51,2.06,7,2.4,5.9,5.36,15.09,9.6,4.09,1.88,6,2.06,8.06,4.45,1.89,2.17,2.24,4.19,5,6,1.88,1.25,2.28.67,4.28,1.89,3.37,2,2.86,4,5.83,6.34s4.06.74,7.89,3.26c2.71,1.78,2.29,2.64,5.48,4.8,3,2,3.59,1.44,5.83,3.26s1.86,2.62,4.63,5.31a47.91,47.91,0,0,0,5.32,4.11c2.43,1.75,3.91,2.82,6,4.12,4.61,2.87,6.91,4.31,9.25,4.8,4,.84,6-.42,7.89,1.37.24.24.71.75,1.88,4.11.81,2.32,1.36,4.28,1.72,5.66"
                />
                <path
                    className="cls-1"
                    d="M617.64,687.39c1.92,0,4.92-.08,8.6-.18,8.74-.24,9.12-.4,11.32-.18,5.93.61,6.25,2.08,11.48,2.23,4.15.12,4.75-.78,8.92-.85,5.47-.1,6.29,1.41,12.17,2.22,7.78,1.09,8.61-1.24,19.71-.51,4.22.28,6.33.42,9.09,1.37,4.47,1.55,5.3,3.3,9.77,4.12,3,.54,3.68,0,6.34.85,3.49,1.16,3.59,2.58,6,3.26,3.79,1.07,4.89-2,11-2.74a23.47,23.47,0,0,1,11.65,1.88,25.08,25.08,0,0,1,4.46,2.4"
                />
                <path
                    className="cls-1"
                    d="M618,670.55a334.14,334.14,0,0,1,49.35-1c32.35,1.73,48.53,2.59,61.37,10.28,19.12,11.46,34.94,34,49,34.63,1.81.09,7.46,0,11.31,3.77,2.08,2,2.17,3.76,5.14,8.23a62.9,62.9,0,0,0,5.83,7.2,167.07,167.07,0,0,1,12,15.09c4.92,7.17,7.38,10.76,8.57,15.08,1.34,4.84,1.22,8.83,4.46,11,2.19,1.45,3.39.39,6.17,2.05,3.88,2.33,5.41,6.7,5.83,7.89,1.56,4.46.2,6.55,2.4,8.91,1.57,1.68,2.68,1.08,3.77,2.75,1.69,2.58-.51,4.72,1,7.54,1.24,2.26,3,1.56,4.46,4.11,1.15,2.08.29,3.14,1.37,5.49.91,2,2.12,2.5,3.77,4.11,2.05,2,2.21,3.15,6.17,11.32,3.29,6.78,2.91,5.4,3.77,7.54,1.65,4.05,1.35,4.82,4.12,13.37,1.54,4.75,2,5.78,2.74,6.86,1.15,1.75,2,2.4,2.4,4.11.55,2.45-.77,3.14-.34,5.15.65,3,3.93,2.64,5.14,5.82s-.68,7.89-.68,7.89h0s2.1,5.48,4.45,10.29c.84,1.71,2.08,4.09,3.77,6.85"
                />
                <path
                    className="cls-1"
                    d="M618.18,664.84a113.45,113.45,0,0,1,14.92-1.46c11.5-.37,15.07,1,26.4,1.37,9.33.3,8.44-1.1,23-1.72a232.53,232.53,0,0,1,25,.35c13.17.81,22.72,2.41,34.63,4.45,14.58,2.5,21.87,3.75,30.17,6.52,8.12,2.71,17.14,5.72,27.09,12.68,6.23,4.37,6.27,5.7,16.45,13,4.74,3.41,7.36,5,15.09,10.63,4.92,3.58,4,3,9.26,6.86,11.82,8.61,12.62,8.58,14.74,11.31,3.76,4.83,2.65,6.74,6.86,11,3,3.06,4,2.15,8.57,6.18a83.56,83.56,0,0,1,7.88,8.22c3.87,4.41,3.4,4.38,4.8,5.49,4,3.21,6.52,1.79,10.29,5.14s2.56,4,5.49,6.17c2.55,1.87,8,3.19,12,5.15a26.51,26.51,0,0,1,4.45,2.74"
                />
                <path
                    className="cls-1"
                    d="M618.49,652.48c3.47.35,8.58.75,14.78.78,5,0,9.63-.19,25.2-1.8,14-1.44,21-2.16,25.72-2.83,12.17-1.71,14.42-2.59,20.31-2,6.27.57,6.48,1.81,17.49,4.11a135.53,135.53,0,0,0,20,2.83c10.51.59,11.44-1,21.35-1,11.3,0,19.37,2.11,32.91,5.66,16,4.19,20.27,7.39,21.6,8.48a72.46,72.46,0,0,0,7.2,5.4c2.56,1.69,3.5,2.06,5.92,3.6a85.49,85.49,0,0,1,8.22,6.18c9.26,7.47,9.65,8.86,12.35,9,3.1.15,5.38-1.56,7.45,0,1.05.78,1.2,1.78,1.8,3.08,1.45,3.13,3.95,4.72,7.46,6.94,6.48,4.11,9.37,2.65,11.57,6.18.94,1.5.93,2.59,2.57,4.62,1.41,1.75,2.33,2.08,4.12,3.6,2.3,2,2.35,2.76,6.17,7.46,3.61,4.45,3.37,3.48,4.89,5.66,3.82,5.47,2.6,7.57,5.65,9.77,2.41,1.73,4,1,6.17,3.34,1.53,1.62,1.2,2.4,2.83,3.86,2,1.79,3,1,5.4,2.83,1.32,1,1,1.12,4.12,4.37,2,2,2,1.9,3.08,3.09a35.43,35.43,0,0,1,3.6,5.14c1,1.52,2.43,3.66,4.37,6.17"
                />
                <path
                    className="cls-1"
                    d="M618.31,659.71c5.43.18,13.67.32,23.71,0,5.86-.19,7.36-.39,33.17-2.31,23.83-1.78,26.88-1.94,31.37-2.06,7-.19,8.72,0,13.37-.51,11-1.14,13.07-3.16,24.17-5.15,9.7-1.73,16.76-3,26-1.8,6.77.88,10.55,2.55,18.26,1.8,4.86-.47,5.42-1.33,10.54-1.54a47.31,47.31,0,0,1,16,2.06c5.89,1.88,5,3,10.28,4.37,6.71,1.74,9.64.34,12.6,3.08,2.2,2,1.13,3.32,3.86,7,2.17,2.88,3.18,2.52,7.71,6.43a58.71,58.71,0,0,1,8.75,9,20.85,20.85,0,0,0,4.11,4.62c2.37,1.85,3.25,1.48,5.14,3.09,2.81,2.39,2,4.15,4.37,6.43,2.75,2.62,4.58,1,8,3.34,3.83,2.63,2.56,5.41,6.42,9,2.9,2.69,3.9,1.4,9.52,4.63a31.71,31.71,0,0,1,10,8.49,38,38,0,0,1,4.11,7.45c2.33,5.06,2,6.2,4.12,9.26A33.94,33.94,0,0,0,919,742c1,.92,2.29,2,4.88,4.11,2.88,2.35,4.44,3.52,4.89,3.86,1.45,1.08,2.69,1.95,3.6,2.57"
                />
                <path
                    className="cls-1"
                    d="M618.87,681.81c5.52,0,10.31,0,14.15,0,8.64,0,10.78.1,13.62.51,5,.72,8.07,1.91,12.35,3.34,14.51,4.85,14.7,3,28,7.46,9.72,3.29,10.13,4.47,18.51,6.69,7.06,1.86,8.11,1.38,13.11,3.34,7,2.74,7.52,4.7,14.92,7.2,4.62,1.56,5.7,1.24,9.51,3.08a49.11,49.11,0,0,1,8.75,5.66c3.71,2.9,3.12,3.1,5.91,5.14,5.22,3.83,7.5,3.29,10.54,6.43a16.08,16.08,0,0,1,3.86,7"
                />
                <path
                    className="cls-1"
                    d="M618.63,647.36a54.32,54.32,0,0,1,11.81-2.07c7.13-.44,10.2.81,17.23,1.54,8.93.94,15.72.16,26-1,9.8-1.13,8.93-1.87,18.26-2.83,8.64-.89,9.19-.24,23.14-1s14-1.09,18.78-.77c8.72.57,9.9,2,17,1.8,6.82-.23,7.78-1.65,15.94-2.57,5.95-.68,6.36-.2,18.26,0,6.37.1,12.56-.19,24.94-.78,11.41-.53,11.72-.8,17-.77a107.45,107.45,0,0,1,19,1.29c11.94,2.05,13.64,4.93,20.06,3.86a30.15,30.15,0,0,1,10-.52c2.31.31,2.1.59,5.14,1,3.74.54,6.71,1,10.28.26,2.07-.42,2.81-.91,4.63-.77,2.17.15,3,1,4.63,1.54,5.13,1.75,7.95-2.67,14.92-1.54.69.11,1.75.41,3.85,1,5.68,1.65,5.75,2.26,8.23,2.58s2.3-.3,7.46-.26c2.32,0,4.1.15,5.4.26,3.8.3,5.57.69,8.23,1a68.54,68.54,0,0,0,10,.52"
                />
                <path
                    className="cls-1"
                    d="M619.9,639.63c1.93-.26,4.88-.65,8.49-1,13-1.36,17.07-.79,29.05-1.55,16.82-1.06,16.3-2.67,25.46-2,2.9.19,3.17.37,17.23,1.8,10.89,1.1,10.78,1,12.6,1.28,7.62,1.17,10.83,3.12,22.89,8,7.74,3.12,14.93,5.68,29.31,10.8,13.39,4.77,13.85,4.66,17.23,6.43,4.81,2.52,9.39,5.63,16.46,8,3.08,1,5.91,1.8,5.91,1.8,6.78,1.87,6.78,1.21,9.26,2.32,4.19,1.87,4,3.7,9,5.91a44,44,0,0,0,5.4,1.8c6.31,1.88,6.57,1.33,9,2.32,4.72,1.92,4.76,4.45,10,7.71a27.74,27.74,0,0,0,8,3.34"
                />
                <path
                    className="cls-1"
                    d="M619,634.21c6.67-.88,12.33-1.43,16.61-1.78a210.22,210.22,0,0,1,22.11-1c9.47.14,9.71,1,15.69.51,8.69-.66,8.81-2.45,17-3.34a66,66,0,0,1,24.17,2c6.1,1.72,6.82,3,12.86,4.12,5.07,1,6.73.51,15.17.77,14.43.44,15.33,2,23.91,1.29,9.11-.72,8.22-2.43,14.15-2.32,10.3.2,11.06,5.37,24.68,9,8.52,2.28,10.17.77,24.43,4.12A103.7,103.7,0,0,1,840,650.43c9.32,3.14,8.67,4.53,13.37,5.15,5.3.7,7.54-.89,15.43.25,1.73.26,3.15.55,4.11.78"
                />
                <path
                    className="cls-1"
                    d="M619.12,629.08c3.59-.23,9-.62,15.7-1.27,7.06-.69,11.1-1.25,19.54-2.32,14-1.77,5.69-.6,33.43-3.86,10.35-1.21,14.52-1.73,20.83-1,4.54.5,6.28,1.21,10.8,1.28,6.36.11,8.59-1.2,17-2.83,12.17-2.37,21.5-4.06,27.77.77,2.28,1.76,1.61,2.42,4.11,4.12,4.56,3.1,7.3,1.27,17,3.86a47.87,47.87,0,0,1,8.49,3.08c1.9.91,3.08,1.62,3.86,2.06,5.36,3,11.08,3.3,13.88,3.6,7.77.82,8.93,3.15,24.95,8.23,9.6,3,13.48,3.56,22.62,5.91a315.34,315.34,0,0,1,39.6,13.12"
                />
                <path
                    className="cls-1"
                    d="M620.52,603.41c12.52-2.55,23.08-4.25,30.89-5.38,16.53-2.39,25.09-3.63,37-3.6,9.8,0,6.43.67,28.28,2.06,18,1.14,21.58.77,29.06,3.08,9.52,2.95,7.83,4.8,18,8,12.22,3.82,15.87,1.52,27.77,5.92,8,2.95,6,3.87,14.4,6.94,11.54,4.24,16.56,3,25.72,7.72,3,1.56,5.61,3.61,10.8,7.71,14.21,11.23,24.31,19.2,31.88,31.89,2.41,4,4,7.57,8.23,12.6,4.09,4.84,4.79,4.12,10.54,10.54,2.87,3.2,3.31,4.06,10.29,12.34,4,4.72,3.52,4.07,4.37,5.14,8,10.18,7.51,16.18,13.89,23.15a32.29,32.29,0,0,0,9.77,7.2"
                />
                <path
                    className="cls-1"
                    d="M622.35,588c4.77.41,12.2.93,21.35,1,15.56.18,17.31-1,28.8-.51,11.66.54,11.43,1.89,29,3.08,14.5,1,18.46.33,21.09-.26,5.14-1.15,7.4-2.56,12.86-2.57,4.66,0,5.68,1,13.11,1.8,3.4.36,2.78.1,12.34.52,8.57.37,12.86.56,16.46,1,4.14.54,5.48,1,9,1,4.85.11,5.48-.61,8.74-.25,5.68.62,5.62,3,10.55,3.6,5.25.63,5.95-2,12.85-1.8,3.47.1,2.8.75,8.74,1.28,2,.19,2.48.14,10,.52,4.85.24,4.65.25,5.4.25a61,61,0,0,0,10.55-1c4.92-.84,4.49-1.13,8.48-1.8a89.92,89.92,0,0,1,13.63-1c2.44-.07,5.78-.11,9.77,0"
                />
                <path
                    className="cls-1"
                    d="M620.12,617a152.84,152.84,0,0,0,15.6-1c16.22-1.87,17.78-4.68,29.58-4.89,8.64-.14,11.23,1.3,19-.25,5.18-1,6.52-2.17,11.06-2.32,6.83-.21,7.38,2.24,15.43,3.09s9.41-1.33,17,.26a45.45,45.45,0,0,1,8.49,2.82c6.71,2.74,9.39,5,15.17,8,5.63,2.94,9.42,4.92,14.14,5.91,10.83,2.27,12.78-3.58,28.54-3.34,5.42.08,12,.87,17.49,1.54,6.66.81,7.86,1.21,11.06,1.29a46,46,0,0,0,18.51-3.6"
                />
                <path
                    className="cls-1"
                    d="M620.35,609.32a86.83,86.83,0,0,0,15.12,1c5.74-.1,8.51-.73,18.77-2,4.37-.57,12-1.54,20.83-2.32,10.5-.92,18.67-1.19,21.85-1.28,20.42-.58,20.14,1.37,33.18.51,16.39-1.08,16.56-4.14,31.11-4.37,4.55-.07,12,.81,27,2.57,23.81,2.8,24.46,4,30.86,3.09a48.87,48.87,0,0,1,15.68-.26,45.56,45.56,0,0,1,8.23,2.06"
                />
                <path
                    className="cls-1"
                    d="M621.16,582.33a75,75,0,0,1,23.31-5.39,104.18,104.18,0,0,1,10.8,0c6.6.4,7.51,1.19,13.11,1.29,7,.13,7.07-1.08,11.06-.26,5.86,1.21,5.75,3.81,10.8,4.12,3.87.23,6.15-1.17,9.26.51,1.43.78,1.47,1.36,3.08,2.57,2.84,2.14,5.41,2.34,8.74,3.09,5.77,1.29,10.89,2.43,14.15,6.17,2.67,3.06.89,4.21,3.6,7.2,3.64,4,7.43,2.59,12.6,7.2,2.15,1.92,1.33,2,4.11,4.89,4.93,5.06,7.45,4.67,10.8,8.22s2.25,5.62,5.66,11.83a40.46,40.46,0,0,0,9,11.06"
                />
                <path
                    className="cls-1"
                    d="M620.78,594.92a268.61,268.61,0,0,1,32.69-2.8c1,0,7-.17,13.37,0,19.71.52,35.77,3.88,52.46,7.45,30.62,6.56,24.22,8.13,47.57,12.6,7.59,1.46,17,3,27.25,9.26a113.93,113.93,0,0,1,13.63,10.29,148.86,148.86,0,0,1,12.6,12.34c13.6,14.53,14.66,17,25.46,28.54,9.81,10.47,10.87,10.51,14.66,15.94,2.76,4,1.85,3.46,11,18.78,2.59,4.31,3.59,5.88,4.63,8.74,2.24,6.13.93,7.79,3.09,13.88A50.14,50.14,0,0,0,885.15,741c3.18,4.9,4.67,6,6.43,9.77a30.25,30.25,0,0,1,2.57,9.26"
                />
                <path
                    className="cls-1"
                    d="M621.52,570.51c10.83-1.51,19.85-2.47,26.29-3.08,22.19-2.09,33.29-3.14,44.23-2.06,9.61,1,13.06,2.39,21.08,1.55,6.39-.68,10.11-2.21,15.43-.52a20.93,20.93,0,0,0,8,1.54c2.52-.07,3-.64,5.66-.77,4-.19,5,1,8.49,1.29,4.79.39,4.88-1.67,10.8-2.06,4.45-.29,4.54.86,11,1a67.15,67.15,0,0,0,9.78-.51c6.44-.8,8.32-2,11.57-.78,2.43.89,2.44,1.93,4.88,3.09,3.25,1.55,5.89,1,11.83.26,15.19-1.85,19.75-.77,21.6-.26,2.46.69,2.07.93,5.14,1.8,6.37,1.81,10.63,1.51,14.92,1.8,7.08.47,5.5,2,17,4.11a173.52,173.52,0,0,0,20.06,2.32c3.67.27,6.76.42,9,.51"
                />
                <path
                    className="cls-1"
                    d="M621.35,576.14c1.86-.2,4.61-.48,7.95-.74,13.09-1,18.58-.48,27.51-.77,14.29-.46,23.07-.74,33.43-4.89,9-3.62,8.93-6,17-8,6.9-1.66,12.75-1.27,24.17-.51,9.65.64,12,1.59,19,1,6.43-.51,5.47-1.37,13.11-2.06,11.08-1,18,.36,20.83,1,3.33.78,2.9,1,5.4,1.54,9,2,14.61-.91,23.4-2.57,15.78-3,17.15,2.56,27.52-1.8a49.8,49.8,0,0,0,14.4-9.51c3.34-3.37,2.91-4.14,5.65-6.18,6.61-4.9,11.79-2.46,15.18-6.94,2.18-2.9.42-4.45,3.08-8.23,1.71-2.43,3.33-3,8-6.17,5.62-3.79,5-4.07,9-6.68,5.16-3.38,7.38-3.68,14.66-7.2a147.43,147.43,0,0,0,13.11-7.2"
                />
                <path
                    className="cls-1"
                    d="M620.44,606.23a88.27,88.27,0,0,0,9.11-.23c6.31-.49,8.75-1.37,13.63-1a37.71,37.71,0,0,1,7.2,1.29c22.67,5.86,29.06,7.53,36,8.74,10.32,1.81,8.43.26,14.4,1.8,9.68,2.5,12.22,6,27.26,12.09,7.06,2.88,7.06,2.35,11.06,4.37a62,62,0,0,1,17.48,12.86c8.18,8.71,7,13,15.17,18.77,1.52,1.06,3.75,2.34,8.23,4.88,8.57,4.88,11.78,6.05,12.86,6.43,5.36,1.89,5.11.79,8,2.32,5.25,2.8,6,6.5,10.8,7.71,2.33.59,2.77-.13,5.66,0,3.48.16,6.45,1.38,16.2,8.23,10.49,7.37,10.34,8.27,13.88,9.26,4.23,1.17,6.29.4,11.06,2.31a29.5,29.5,0,0,1,8.23,5.14c10.05,8.2,18.56,3.38,33.43,11.57,9.46,5.22,9.43,9.06,18.77,12.35a43.6,43.6,0,0,0,16.71,2.31"
                />
                <path
                    className="cls-1"
                    d="M621.07,562.48c4.35-.71,11.12-1.84,19.4-3.33,19.53-3.51,23.08-4.63,38.32-7.2,18.44-3.11,16.11-2,26.48-4.11,14.44-3,18-5.05,27.26-4.89,7.1.12,12.68,1.46,20.57,3.34a188.67,188.67,0,0,1,20.57,6.43c17.33,6.23,26,9.35,31.37,13.11,9.12,6.38,10,10.49,21.6,17.75A93.61,93.61,0,0,0,840,590.52"
                />
                <path
                    className="cls-1"
                    d="M621.2,556.31A149.4,149.4,0,0,1,641.5,555c12,.07,16.42,1.54,27,2.06,20.86,1,19.17-3.92,31.89-1.8,15.81,2.65,18.92,10.4,36.25,14.92a27.62,27.62,0,0,1,11.32,5.4c2,1.62,2.07,2.23,6.94,8.22,6.11,7.52,6.7,7.49,9.26,11.32a48.64,48.64,0,0,1,5.4,10c1.26,3.28,1.09,4,3.08,13.11,2.11,9.58,2.54,9.82,2.83,13.12a42.22,42.22,0,0,1-1,13.62"
                />
                <path
                    className="cls-1"
                    d="M621.29,549.88c5.46-1.27,13.59-3,23.55-4.36,5.73-.8,11.68-1.44,35.49-2.83,33.1-1.93,34.8-1.47,40.89-3.85,5.77-2.27,10.78-5.25,20-6.69,7.57-1.18,9,.08,14.92-1.8,8.82-2.78,9-6.54,16.71-7.71,5.26-.81,7.18.62,12.34-1.29,2.68-1,3.22-1.76,7.72-3.6,2.92-1.19,4-1.43,8.23-2.83,6.32-2.13,6.8-2.63,10.54-3.86,6.8-2.22,8-1.47,13.89-3.08,6.54-1.81,6.75-3.23,16.45-7.46,9-3.9,7.83-2.27,17.75-6.43,8.57-3.59,12-5.88,19-6.68,3.78-.43,4.24.06,7.72-.26,5.3-.48,11.88-2.32,19.28-7.46"
                />
                <path
                    className="cls-1"
                    d="M621.31,541.67c10-2.1,18.26-4,24.31-5.41,9.34-2.19,17.92-4.34,24.17-5.91,15.25-3.83,15.46-4,19-4.63,9.62-1.59,10.42-.42,24.42-1.54,16.32-1.3,18.39-3.14,28.29-2.32,2.9.24,7.16,1.11,15.69,2.83,11.76,2.38,17.64,3.57,24.94,6.17a79.9,79.9,0,0,1,17.23,8,59.74,59.74,0,0,1,14.91,12.59c2.12,2.56,2.3,3.26,8.74,13.89,8.1,13.37,7.83,12.27,9.78,15.94a104.24,104.24,0,0,1,8.74,22.89c3.1,11.84,2.12,16.12,6.43,25.2a56.62,56.62,0,0,0,5.91,9.77"
                />
                <path
                    className="cls-1"
                    d="M621.23,536a86.24,86.24,0,0,0,19.24-3.88c12-3.89,14.72-7.73,30.09-13.89,9.16-3.67,8.41-2.39,20.06-6.94a196.22,196.22,0,0,0,23.91-10.8c8.83-4.8,9.65-6.26,18.51-10.54,5.52-2.66,6.12-2.54,13.63-6.17,9.82-4.75,9.81-5.46,13.63-6.43,6.7-1.71,9.11-.16,13.89-2.83,3.54-2,3.59-3.6,6.94-6.43,5.54-4.68,8.35-2.76,18.77-6.94,7.33-2.94,6-3.92,18.77-10,1.29-.62,4.58-2.17,8.75-4.63a52.94,52.94,0,0,0,8.48-5.91c2.69-2.36,2.23-2.53,4.37-4.37,4.44-3.82,7.24-3.79,11.57-6.69a27.93,27.93,0,0,0,7.46-7.46"
                />
                <path
                    className="cls-1"
                    d="M620.63,522.55c17.15-7.81,38.56-18.31,62.53-32,28.41-16.2,48.71-30.16,64.11-40.8,22.53-15.57,44.21-30.55,70.29-53.49,19.72-17.34,18-18.16,37-34.28,24.53-20.8,39.55-33.31,62.74-42.52,14.77-5.86,38.42-14.88,60.34-5.14,4.45,2,13.83,6.3,17.83,15.77,3.81,9,.75,17.95-4.11,31.54a140.52,140.52,0,0,1-16.8,32.58"
                />
                <path
                    className="cls-1"
                    d="M616.82,513.25c22.38-16.06,41.75-29.21,56.74-39.13,17-11.23,37-24.44,64.11-40.46,15.83-9.33,32.7-19.23,55.89-30.85,31.74-15.91,43-21.61,56.23-24.69,8.27-1.93,13-2.14,28.54-5.91,12.37-3,15.14-4.29,21.34-3.86,7.35.51,7.65,2.59,23.15,6.43,11,2.74,12.07,2,16.71,4.37s8,5.66,14.66,12.09A115.14,115.14,0,0,1,965,402.81c3.94,4.92,8.8,11,12.15,19.66.89,2.3,1.93,5.46,3.79,17.37,1.22,7.84,2.74,17.59,3.26,26.74,1.35,24-4,48.14-6.17,57.26"
                />
                <path
                    className="cls-1"
                    d="M619.87,514.37c21.18-13.43,39.31-23.73,52.66-31,25.62-13.93,37.91-19,41.83-20.57,33.54-13.56,51-13.8,56.91-13.72a105.36,105.36,0,0,1,25.37,3.43c11.77,3.14,29.69,7.91,41.83,23.66,9.48,12.28,11,25.48,12.35,37a111.28,111.28,0,0,1-2.75,37.71A126.75,126.75,0,0,1,821.33,603"
                />
                <path
                    className="cls-1"
                    d="M618.9,507.22c11.85.91,30,2.18,52.26,3.24,39.93,1.92,39.16.59,58.28,2.4a525.76,525.76,0,0,1,76.46,13.38c11.52,2.8,22.47,5.92,23.32,6.17,14,4,21,6,27.42,8.57,12.78,5.08,14.29,7.42,20.58,7.2,4.18-.15,8.64-1.38,12.68,1.37,2.45,1.66,2,2.89,4.46,5.48,2.06,2.14,4.21,3.19,16.8,6.86,5.78,1.69,7.4,2.07,12,3.43,5.14,1.52,9.3,2.87,12,3.77"
                />
                <path
                    className="cls-1"
                    d="M620,598.2c30-9.63,47.76-13.79,58.69-15.74,12-2.14,16.75-1.79,25.37-5.48,3.54-1.52,9.38-4.41,17.83-5.15a62.14,62.14,0,0,1,7.89,0c10.41.1,14.81-4.84,39.08-16.45a156.88,156.88,0,0,1,14.75-6.52c5.91-2.17,8.37-2.56,13.37-4.8,5.66-2.53,8.06-4.52,12-6.85,11.16-6.62,13.71-3.61,27.77-10,12.87-5.8,12.55-9.14,20.91-10.28,6.25-.85,9.05.66,16.8-1.37,2.68-.7,5.64-1.75,11-2.75a46.81,46.81,0,0,1,7.55-1,30.74,30.74,0,0,1,13.37,2.74"
                />
                <path
                    className="cls-1"
                    d="M943.73,664.75a106.54,106.54,0,0,1-7.2-9.26C925.27,639.26,927.8,633.27,919,626c-3.94-3.27-8.55-5.46-8.91-10-.25-3.12,1.8-4.28,1-6.85-1.17-3.87-6.29-2.83-11-7.89-2.69-2.91-1.77-4.09-5.15-8.23a31.33,31.33,0,0,0-6.85-6.17c-10.59-7.37-14.22-12.45-25-19.54-13.28-8.71-25.43-12.6-35.32-15.77-7.24-2.32-25-4.9-60.34-10-7.76-1.11-13.47-1.88-21.26-1-12.74,1.41-14,5.33-26.74,7.21-6.28.92-5.36-.12-29.83-.69-11.36-.26-17.16-.39-23,0-4.62.31-7.9.66-23.66,2.74l-21.78,2.91"
                />
                <path
                    className="cls-1"
                    d="M620.92,527.28a119.46,119.46,0,0,1,23.41-3.87c12.4-.81,21.76.52,32.14,2.05,15.18,2.25,22.77,3.37,31.89,7.2,14.34,6,23.53,14.51,29.83,20.32a123.14,123.14,0,0,1,18.25,21.08c6,8.82,5.3,10.24,12.86,23.92,7.67,13.87,8.35,12.28,23.14,36.51,4.41,7.22,7.38,12.33,13.12,21.09,4,6.12,7.44,11.08,9.77,14.4"
                />
                <path
                    className="cls-1"
                    d="M614.24,500.91c76.29-66.8,104.81-91.05,112.12-96.56a131.78,131.78,0,0,0,12.08-9.77c5.81-5.34,7.13-7.6,12.6-11.83,4.64-3.58,5.78-3.57,10.55-6.94,5.47-3.87,6-5.36,14.4-13.12,7.1-6.6,9-7.67,21.34-18.77,1.34-1.21,4.45-4,8.23-8a66.12,66.12,0,0,0,6.17-6.94c3.07-4.4,3.18-6.55,6.17-8.49,2.27-1.47,3-.76,4.89-2.31,2.88-2.41,1.65-4.59,4.11-7.2,3.08-3.27,6.68-1.66,8.74-4.89,1.32-2.05,0-2.95,1-5.4,2-4.74,7.75-3.56,12.09-8.48,3-3.37.84-4.57,4.11-9.78,3.17-5,5.33-4.16,10.55-10.54,3.21-3.93,3-5,6.17-8.23,2.93-3,4.94-3.93,7.71-5.91,8.19-5.87,6.62-9,14.92-15.94,5.83-4.86,6.6-3.29,12.34-8.49,4.52-4.1,5.67-6.56,10-9.51a31.47,31.47,0,0,1,10.8-4.63"
                />
                <path
                    className="cls-1"
                    d="M608.72,491.65c10-7.83,18.95-13.93,25.84-18.39,17.07-11,39.12-22.55,50.14-26.74,7.35-2.79,11.8-4,19.8-8,11.29-5.64,19.8-10.8,19.8-10.8a64.15,64.15,0,0,1,8-4.37c5.79-2.6,8.48-2.63,12.86-4.12,6.64-2.25,5.88-4,16.46-10a129.49,129.49,0,0,1,17-8A118.53,118.53,0,0,1,794,396.38c10.12-2.4,20.29-2.2,40.63-1.8a144,144,0,0,1,18.25,1.28,109.62,109.62,0,0,1,12.35,2.32c6.48,1.63,5.75,2.14,21.08,7.71,6,2.17,9.19,3.23,13.12,6.17a46.75,46.75,0,0,1,5.65,5.15c2.82,2.94,4.7,4.9,5.92,7.71,1,2.26,1.15,4.18,1,10-.09,3.83-.28,8.9-.78,14.91"
                />
                <path
                    className="cls-1"
                    d="M610.9,496c58.28-40.33,91.53-62.13,111.34-74.7,5.85-3.7,22.62-14.28,44.49-29.31,15.4-10.59,27.54-19.42,35.49-25.2,23-16.77,34.53-25.15,38.31-28.8,23.42-22.68,34.56-47.66,39.34-58.37a185.2,185.2,0,0,0,8.23-22.12A182.08,182.08,0,0,0,894,229.24c2.44-18.7,3.56-28.15-1.8-33.43-5.77-5.68-15.51-4.82-17.23-4.63-1.53.16-6.1.81-13.37,5.66-5.82,3.87-10.54,8.56-21.6,23.65-3.26,4.45-7.62,10.55-12.6,18"
                />
                <path
                    className="cls-1"
                    d="M612.15,480.75c13-9.35,23.28-17.26,30.38-22.91A195.4,195.4,0,0,1,658.22,446a126.59,126.59,0,0,1,24.94-13.37c6.4-2.47,29.17-10.92,54.26-4.89a82.52,82.52,0,0,1,18.51,6.94c4.93,2.61,9.86,5.23,14.91,10.29,3.45,3.45,4.66,5.74,9.26,9.26,3,2.32,4.18,2.59,8,5.14,2.53,1.7,4.69,3.36,10.8,8.74,10.39,9.13,15.63,13.77,17.75,17.23a54.59,54.59,0,0,1,5.4,12.34,50.12,50.12,0,0,1,1.28,5.66"
                />
                <path
                    className="cls-1"
                    d="M596.76,475.71a154.54,154.54,0,0,1,31.37-24.56c11.93-7,23.42-11.6,46.54-20.31,9.44-3.56,13.63-4.91,15.17-5.4A263.86,263.86,0,0,1,719.67,418c6.32-1.22,13.3-2.19,27.26-4.12,9.17-1.26,13.89-1.91,20.31-2.57,11.31-1.15,10.71-.6,23.66-1.8,12.57-1.16,15.56-1.9,24.43-2.05a122.46,122.46,0,0,1,13.11.25c7.36.59,9.66,1.52,18.52,2.06,2.57.16,5.42.32,9.26.26,6.75-.12,6.52-.78,10.54-.52,5,.34,6.71,1.47,11.06.77a26.19,26.19,0,0,0,4.62-1.28c3-1,7.36-2.34,12.86-3.86"
                />
                <path
                    className="cls-1"
                    d="M610.13,475.32A300.18,300.18,0,0,1,631,437.52c6.38-9.81,8.22-11.35,13.88-20.57,9.71-15.79,11-22.25,18.78-37.29,15.31-29.81,19.81-23.48,26.48-42.17,7.31-20.46,3.94-33.74,16.2-43.71,4.45-3.62,7.72-4.17,12.6-9.77,3-3.43,3.72-5.46,12.34-20.57,1.68-2.95,2.94-5.11,4.89-8.49,5-8.56,6.18-10.56,10-17.23,7.16-12.4,8-14.24,8.74-15.94,3.28-7.68,2-7.83,4.63-12.34,3.86-6.69,6.77-6.63,11.31-14.15,3.33-5.51,1.78-5.56,4.89-10,4.25-6.1,8.38-7.77,8.49-12.34.06-2.81-1.48-3.37-.78-5.66.79-2.55,3-2.61,6.43-6.94a21.4,21.4,0,0,0,2.57-3.86c1.45-2.78,1.14-3.69,2.06-5.65,1.25-2.65,2.8-3,6.94-6.69a61.75,61.75,0,0,0,5.66-5.66"
                />
                <path
                    className="cls-1"
                    d="M589.94,455.39a394.23,394.23,0,0,1,42-32.53c12.11-8.1,16-9.76,36.25-23.14C684.8,388.8,685,388,697.3,380.18c11.73-7.44,12.54-7.33,33.69-19.8C758.8,344,762.2,341,775,335.44c9.87-4.27,17.6-7.55,28.8-9.52,15.09-2.65,27.11-1.26,38.06,0,15.21,1.76,22.82,2.63,32.14,6.94,12.29,5.69,16.54,12,29.06,15.95A74.4,74.4,0,0,1,915.87,354c4.4,2.26,4.26,2.75,9,5.4,11.51,6.42,13.27,4,17.75,8.49,3.48,3.47,5.35,7.85,9.25,8,1,0,2.26-.25,3.6.51,1.71,1,1.89,2.6,3.09,4.37a11.2,11.2,0,0,0,5.66,4.12"
                />
                <path
                    className="cls-1"
                    d="M579,440.86c3.8-7.87,9.7-19.51,17.74-33.17,10.33-17.54,18.82-29.37,25.2-38.31,30-42.1,30.66-49.1,58.11-85.37,17-22.44,27.22-33.59,42.17-43.46,10.84-7.15,28.21-18.61,42.18-14.14,4.89,1.56,13,6.24,21.34,5.4a19.43,19.43,0,0,1,9.26.77c2.67,1,2.64,1.75,5.91,3.08,4.35,1.78,5.67.9,9,2.58,2.39,1.2,2.14,1.87,5.4,4.37,3.62,2.77,4.6,2.46,10.54,5.91s5.21,3.93,9,5.66c5.08,2.32,6,1.29,9.52,3.6,2.36,1.53,2.48,2.32,6.94,5.91,2.13,1.72,3.78,2.89,5.14,3.86a150.7,150.7,0,0,0,14.4,8.74c11.45,6.47,17.18,9.7,19.29,11.83,3.44,3.48,4.72,6.06,8.74,7.46,3.3,1.15,5.2.37,9,2.31,2.84,1.45,2.57,2.29,5.66,3.86s4.18,1.22,8,2.57a51.79,51.79,0,0,1,6.94,3.34c10.19,5.42,11.39,7.82,15.17,7.72a7.45,7.45,0,0,1,4.38.77c1.31.78,1.26,1.48,2.82,2.57s2.34.78,4.12,1.8c2,1.13,1.86,2,3.6,3.34.91.71,2.11,1.37,6.43,2.32,5.06,1.11,5.38.63,7.71,1.28,6,1.68,6.77,5.66,14.4,9a32.11,32.11,0,0,0,4.37,1.55"
                />
                <path
                    className="cls-1"
                    d="M594.48,445.49a47.37,47.37,0,0,0,13.34-16.2c2.16-4.43,1.79-5.67,4.37-9.77,3.28-5.21,6.91-8,13.11-12.86,5.52-4.28,7.55-4.91,9.52-4.88,1.76,0,3,.57,6.94,2.83,8.34,4.82,12.52,7.23,14.66,9.77,3.2,3.79,2.94,5.75,6.94,9.26,2.48,2.17,3.19,2,5.66,3.85,4.17,3.21,3.93,5.23,9.51,13.12,4,5.72,5.52,6.57,8.23,11.57a42.05,42.05,0,0,1,2.83,6.17c1.61,4.49,1.09,5.57,2.31,8.74,1.74,4.51,3.34,3.74,5.92,8.75,2.86,5.54,1.2,7.08,4.11,10.54,2.05,2.43,3.7,2.66,5.14,5.4a12.2,12.2,0,0,1,1.29,6.17"
                />
                <path
                    className="cls-1"
                    d="M574.64,443.82c11.48-26.8,19.07-48.72,23.92-63.9,3.93-12.32,5.59-18.67,11.31-35.74,5.79-17.27,10.58-29.92,16.2-44.74,6.93-18.29,10.4-27.43,13.63-33.95,10.78-21.72,21.41-34.1,24.69-37.8,8.84-10,14.12-15.95,23.91-20.31,2.66-1.19,17.52-7.81,31.37-4.37,4.69,1.16,10,3.66,17.23,4.37,2.28.22,4.38.23,6.94,1.54,2.87,1.47,3.73,3.24,6.43,3.6,2,.27,2.47-.54,4.63-.77,3.78-.4,4.85,1.79,10.29,3.6s5.9.21,11.31,2.31a21.86,21.86,0,0,1,6.43,3.35c2.38,1.89,2.72,3.17,4.89,3.85s2.86-.14,4.88.78c1.81.81,1.57,1.63,3.34,2.57,2.17,1.14,3.42.38,6.18,1a13,13,0,0,1,5.14,2.57"
                />
                <path
                    className="cls-1"
                    d="M584,443.31a316.91,316.91,0,0,1,28.16-41c2.21-2.73.95-1.17,29-32.65,16-17.87,23.92-26.81,28.55-32.4,6.84-8.29,8.67-10.93,13.88-16.2,8.89-9,16.82-14.74,24.17-20.06A201.85,201.85,0,0,1,732,285.55c7.07-3.8,12.94-6.37,19.54-9.26,5.5-2.41,11.36-5,19-7.71,11.68-4.18,21-6.41,31.88-9,14.79-3.51,12.36-2.19,21.35-4.63,17.64-4.78,20-7.95,30.6-8.23,4.48-.12,8.06.35,15.17-.51,5.69-.69,5.88-1.29,12.34-2.06,2.43-.29,6.88-.54,15.69-1,6.32-.35,9.48-.52,9.77-.51,5.82.23,9.33,1.35,13.63-.52a14.5,14.5,0,0,0,3.34-2.05"
                />
                <path
                    className="cls-1"
                    d="M559.47,431c3.11-8.51,7.84-22.29,12.35-39.72,6.76-26.17,5-28.19,10.8-48.09,7.7-26.37,12.84-27.54,14.4-45.51.79-9.23.6-22.36.51-28.29-.23-15.69-1.18-20.66,1.54-29.31,1.61-5.1,3.21-7.4,4.37-13.89a40.71,40.71,0,0,0,0-17.49c-1.19-5-2.22-4.34-3.6-9.77-1.94-7.68.2-8.67-1.54-16.45-1.48-6.66-3.53-8.11-2.57-12.09.91-3.77,3.06-3.74,3.86-7.46.94-4.39-1.86-5.44-2.57-12.34a25.43,25.43,0,0,1,1.54-12.09c.77-1.9.91-1.49,3.34-5.91,2.59-4.71,4.25-8.5,5.14-10.54,1-2.37.6-1.55,3.6-9,2.28-5.63,2.39-5.75,2.58-6.69.8-4-.11-7.27-1.29-11.31a45.35,45.35,0,0,0-5.4-11.83"
                />
                <path
                    className="cls-1"
                    d="M593,467.09c9.32-8.51,16.83-14.95,22-19.28,6.55-5.5,8.73-7.35,10.54-11.32,3.45-7.57-.45-11.89,3.86-18.25,1-1.53,1.11-1.07,7.2-6.43,6.48-5.72,9.73-8.58,11.31-10.8.9-1.27,2.84-6.69,6.69-17.49,2.47-6.93,3.06-8.7,4.88-11.31,2.87-4.12,4.32-3.91,6.69-8,1.92-3.29,1.16-3.75,4.11-10.8a59.76,59.76,0,0,1,3.86-8,69.66,69.66,0,0,1,4.88-6.94,82.66,82.66,0,0,0,7.2-11.31c3.88-8.24-.93-10.46,1.8-21.35,2.26-9,5.83-8.67,7.46-18.25.84-4.93.3-7.37,2.57-10,3.19-3.73,7.38-2.6,9.52-6.43.73-1.32.56-2,.77-8,0,0,.17-4.81.51-9.52.65-8.95,1-13.43,3.86-17,2.6-3.19,4.56-2.53,6.94-5.91a16.45,16.45,0,0,0,2.57-10.54"
                />
                <path
                    className="cls-1"
                    d="M603.57,481.62c11.24-4.05,14.39-8.49,15-12,.6-3.18-.77-5.62,1-8.22,1.66-2.42,4-1.37,9.26-4.12,3.05-1.58,5.84-3,7.2-5.91,1.9-4-1.12-6.32.77-10,1-1.85,1.85-1.58,8-5.92s6.87-5.75,7.2-6.42c1.71-3.44-.12-4.87,1.29-7.72,1.5-3,4.44-3.18,13.89-6.94,9.84-3.92,11.22-5.59,11.82-7.2,1.14-3-.95-4.61.52-7.46s4.53-3.71,9-5.14c4.8-1.55,5.07-.65,8.48-2.06a19.88,19.88,0,0,0,8.75-6.68c2.05-2.92,1.23-3.79,2.83-6.43,3.6-6,9.3-4,13.62-9s.16-8.59,4.63-16.2c3.21-5.47,5.54-3.93,10-10.8,3.05-4.68,2.82-6.68,6.43-11.06,1.57-1.91,3.38-3.44,6.94-6.43,9.22-7.74,11.17-7.62,12.6-11.06,2-4.67-.93-6.56,1.29-11.05,2-4.08,5.43-4.63,9.77-8,3.18-2.45,7.23-6.68,10.29-14.4"
                />
                <path
                    className="cls-1"
                    d="M569.37,434.44a93.74,93.74,0,0,0,5.15-17.36c1.87-9.66.74-12,2.7-21.22.92-4.33,1-2.93,6.68-20.31,3.57-11,5.37-16.54,6.43-20.57,1.82-6.94,1.88-8.59,3.6-13.12,2.67-7,4.06-7.11,6.94-13.88,3.52-8.27,2.3-10.13,5.66-18.77,2.52-6.47,3.79-6.93,7.2-13.89,5.28-10.77,4.5-14.28,8.74-25.71s5.28-8.69,12.35-24.43c6.21-13.84,6-17.38,12.34-29.06,1-1.87.8-1.39,9-14.66,18.92-30.62,13.28-22.27,18.77-30.85,6.34-9.91,7.89-11.71,9.51-16.72,2.83-8.72.53-10.75,3.6-17.23,4.65-9.77,11.8-9.2,12.86-16.71.78-5.49-2.76-7.84-1-14.4.9-3.39,1.92-3.07,3.86-7.71,2.34-5.6,1.79-8.3,3.34-15.69a83,83,0,0,1,3.86-12.86,84.76,84.76,0,0,1,9.26-17.74"
                />
                <path
                    className="cls-1"
                    d="M583.9,456.16c9.66-11.61,13.88-22,15.94-29.44,3.3-11.8,2-16.9,7.2-27.51s9.21-9.26,10.8-17.23c1.54-7.67-1.6-12,1.8-19.8a35.47,35.47,0,0,1,5.15-8c5.29-6.77,8.58-7.56,10-12.6.64-2.25,0-2.27,1-7.46a45.76,45.76,0,0,1,2.06-8c2.21-5.73,4.3-5.23,5.92-9.52,2.29-6.1-1.56-8.13.77-14.4,1.71-4.61,4.06-4.22,5.14-9,.62-2.74,0-3.74,1-8.74a26.27,26.27,0,0,1,1.54-5.4,22.15,22.15,0,0,1,3.34-5.4c2.69-3.35,2.43-5.76,6.18-16.71,3.54-10.36,5-11.88,5.14-17.23.09-4.21-.82-3.9-.77-8,.1-9.56,5.14-17.16,6.94-19.8,3.87-5.69,6.59-6.66,8.74-12.6,1-2.68.52-2.74,1.55-6.95,2.82-11.55,6.73-13,7.2-20.82.27-4.6-1-5-.26-9.52.71-4.25,2-4.69,3.6-10.28,1-3.51,1.37-6.28,2.06-11.83a72.19,72.19,0,0,0,.77-9,62.74,62.74,0,0,0-1-11.32c-1.27-7.79-2.49-8.84-2.32-13.63.11-3,.62-3.62,1.29-7.71a68.59,68.59,0,0,0,.77-14.91"
                />
                <path
                    className="cls-1"
                    d="M572.84,435c18.32-34.64,26.54-51.48,30.6-60.43,1.07-2.35,4-9,8.75-17.48,2.86-5.17,5.2-8.93,10.28-17,8.64-13.66,13-20.49,14.4-22.62,12.54-18.53,15.46-16.79,30.6-37.29,9.32-12.61,10.22-16,20.32-28.29,6.46-7.87,12.12-13.84,15.43-17.22a285,285,0,0,1,22.88-20.83c6.4-5.24,11.55-9,16.46-12.6,8.94-6.55,13.41-9.82,16.2-11.32,13.72-7.37,21.88-6,30.34-12.08a20,20,0,0,1,7.2-3.6c3.6-.9,4.88,0,8.23-.77s3.63-2.1,8.74-4.89a61.79,61.79,0,0,1,12.6-4.89c4.84-1.47,7.27-2.21,9.52-2.31,5.91-.26,8.44,1.85,12.34,0,2.24-1.06,1.69-1.89,4.37-3.6,2.87-1.82,5.31-2,12.34-2.83,2.14-.24,5.89-.68,10.8-1.28"
                />
                <path
                    className="cls-1"
                    d="M602.8,477.12c10.66-8.95,19.11-13.33,25.07-15.68,3.12-1.24,10.2-3.77,15.69-10.55,1.49-1.83,1-1.67,3.08-4.63,7.08-9.93,10.86-9.07,14.66-15.94,3.19-5.77,2.18-9.38,5.66-11.06,3.13-1.5,5,.93,8.23-.51,4.42-1.95,3.08-7.27,8-11.57,2.88-2.54,4.09-1.34,10.54-4.12a49.35,49.35,0,0,0,13.63-8.74c6.49-6,5.46-8.88,11.31-12.34,3.67-2.17,4.92-1.53,8.49-4.12,4.52-3.27,3.93-5.32,7.71-8.74,6.52-5.88,11.66-2.85,15.69-8.23,3-3.93.66-6.16,3.86-10,4-4.77,8.47-2.6,11.57-6.94s-.34-7.94,2.83-12.6c1.93-2.84,3.63-2.14,10.28-5.91a61.91,61.91,0,0,0,13.63-10.55c3.92-4,6-6.21,7.72-10,2.24-5.07,1.51-8,4.37-10s5.2-.25,8-2.57c2.08-1.75,1.71-3.55,3.34-6.69,2.09-4,4.84-5.19,8.49-8.23a44.5,44.5,0,0,0,10.28-12.6"
                />
                <path
                    className="cls-1"
                    d="M596.12,468.64c22.64-14.3,37.55-27.93,47.18-38.06,6.34-6.68,13.08-14.58,25.46-22.37,11.24-7.08,13.48-5.43,25.2-12.86,16.86-10.7,14.68-15.68,27.26-20.83,6.75-2.77,6.15-.82,27.51-6.68,8.7-2.39,10.41-3.16,15.43-3.86,8.5-1.2,10.38,0,21.34-1,8.18-.8,8.14-1.59,15.43-2.06a114.49,114.49,0,0,1,16.71.26,120.82,120.82,0,0,1,19,2.57c11.15,2.51,13.38,4.94,22.89,5.66,5.53.41,5.64-.34,8.48.51,7.09,2.14,8.82,7.57,16.2,8.75a20.53,20.53,0,0,1,4.38.77c3.58,1.19,4,2.82,7.45,4.63,4.31,2.23,5.6.7,8,2.82,2.66,2.38,1.42,4.62,3.86,6.43,2.91,2.16,5.45-.43,9,1.55,2.84,1.57,2.2,3.77,5.66,7.45,3.13,3.34,4,1.87,7.46,5.15a36.29,36.29,0,0,1,8.22,12.6,8.44,8.44,0,0,0,1.55,2.82c1.74,2.05,3.21,1.58,4.88,3.09,2.05,1.85.79,3.42,3.09,7.71,1.36,2.55,2.1,2.55,3.08,4.63a13.78,13.78,0,0,1,1,7.72"
                />
                <path
                    className="cls-1"
                    d="M552.4,432.25a183.71,183.71,0,0,0,6.17-22.37c3.69-17.64,3.39-29.31,3.9-39.3.42-8.13,2.28-41.13,18.86-72.34,1.33-2.51,9-14.59,24.34-38.75,7.64-12,8.28-12.91,14.4-22.63,16.64-26.4,15.32-25.26,18.86-29.82,10.48-13.51,14.87-14.22,25-28.8,4.83-6.94,3.76-6.67,9.94-16.46,17.59-27.85,25.1-28.21,31.54-45.6,2.41-6.48,3-10.77,7.2-18.52,4.87-8.9,7.45-9.33,8.92-15.77,1.34-5.87.23-10,3.43-15.08.59-.93,2.24-3.35,3.77-5.49,2.11-3,2.72-3.65,3.77-5.14,2.12-3,2.84-4.76,5.49-9.94,1.24-2.43,3.08-6,5.48-10.29"
                />
                <path
                    className="cls-1"
                    d="M873.1,69.89c-2.48,2.06-6.18,5.14-10.63,8.92-10.56,9-11.45,10-15.77,13.37a152.22,152.22,0,0,1-18.17,12c-5,2.83-9.72,4.9-18.86,8.91-17.39,7.64-18,7-29.14,12.35-6.39,3.08-8.25,4.29-13.37,6.17-2,.74-5.36,1.87-17.14,4.8-10,2.47-9,2-14.4,3.43a196.57,196.57,0,0,0-22.29,7.2c-6,2.33-6.8,3.2-12.69,5.82-18.3,8.16-23.62,5.79-41.48,13.72a107.87,107.87,0,0,0-16.46,8.91,109.94,109.94,0,0,0-15.08,12.35,151.86,151.86,0,0,0-12.35,13.37C606.12,212,599,220.46,592.64,232.75c-1.41,2.75-3.11,6.41-6.51,13.71s-5.79,12.7-6.86,15.09c-7.09,15.85-9.29,18.13-16.45,33.6-6.64,14.34-10,21.67-13.38,32.57-2.87,9.38-3.32,13.62-7.2,23.32a95,95,0,0,0-4.8,13.37c-1.26,4.86-1.21,6.65-2.74,13-1.36,5.68-2.58,9.26-3.43,12a129.69,129.69,0,0,0-5,26.35"
                />
                <path
                    className="cls-1"
                    d="M538.64,423.89c3.6-24.81,5.2-45.06,6-58.8,2.68-45.83-1.11-55.43,5.83-78.17,2.31-7.56,6.32-18.32,8.57-35,1.45-10.67.42-10.8,1.72-23.31,1.13-10.87,2.66-18,8.91-45.95,6.63-29.6,5.81-25.58,6.86-30.51,8-37.18,6.26-38.57,11.31-54.17s7.67-17.08,12-32.92a189.55,189.55,0,0,0,5.83-31.54"
                />
                <path
                    className="cls-1"
                    d="M544.56,419.52q2.79-16.06,5.57-32.14c4.21-24.3,6.36-36.54,7.2-41.14,5.13-28,5.68-25.64,6.51-35,1.51-16.87-.73-19.65,1.72-35.31,2-12.51,3.67-12.59,5.48-25.71,2-14.07.18-15.4,2.06-29.15,1.38-10.11,2.36-9.49,6.17-27.77,4.29-20.52,2.61-19.15,5.83-31.2,3.06-11.46,5.33-15.5,7.54-27.77,1.05-5.81.51-4.72,2.06-14.74,2.2-14.22,3.3-21.33,5.83-28.46,3.38-9.53,5.29-9.45,6.86-17.14,1-5,.34-5.69,1.71-16.46a139.3,139.3,0,0,1,2.4-14.74c1.47-6.42,2.47-8.15,3.43-13.72a71,71,0,0,0,1-11.31"
                />
                <path
                    className="cls-1"
                    d="M667.73,9.89A110.3,110.3,0,0,0,662.93,24c-2.86,10.7-1.72,12.91-4.46,19.89-3.26,8.32-5.59,6.95-9.94,16.8-2.5,5.66-1.78,6.22-5.83,17.48-2.76,7.68-3,7.2-5.14,13.37-3.3,9.59-2.5,10.22-5.49,18.86-3.71,10.72-5.2,10.48-5.83,16.11-.79,7.08,1.42,8.79-.34,14.4-1.65,5.29-4.07,5.26-6.86,11.32-1,2.13-1.16,3.19-3.08,15.08-2.16,13.38-2.46,15.36-3.77,18.86-2.29,6.1-3.33,5.33-4.46,9.6-1.93,7.3,1.06,9.79,1.37,20.57.1,3.43-.5,8.11-1.71,17.49s-1.82,14.11-2.75,16.11c-3.26,7.08-7.35,7.59-13,17.49a57.47,57.47,0,0,0-3.78,7.88c-4.32,11.44-1.06,15.48-4.45,29.15-1.35,5.43-2.12,5.82-3.43,12-1.36,6.41-1.07,8.56-2.4,13.37-1.64,5.92-3.23,6.85-5.49,12.34-3.24,7.88-1.56,9.82-4.45,26.06a129.12,129.12,0,0,1-3.78,16.46c-3.46,10.84-6.69,13.85-12.21,26.91-2.65,6.27-4.4,11.48-5.4,14.66"
                />
                <path
                    className="cls-1"
                    d="M594.86,459.42c8.72-5.2,11.32-10.16,11.93-13.93.36-2.2,1.1-9.86,3.34-14.14,2.43-4.63,4.69-3.71,9-10a41.39,41.39,0,0,0,4.63-9.26c2.91-8.21.75-10.3,3.6-14.14,1.33-1.79,4.4-3.51,10.54-6.94,4.83-2.7,6.65-3.24,7.46-5.4,1.22-3.24-1.52-5.62,0-8s4.88-.83,6.94-3.09c2.52-2.77-1-6.76.26-11.83,1.52-6.16,7.93-5.13,13.11-12.34,2.17-3,3.16-8.7,5.15-20.06,1.73-10,1-10.8,2.82-16.2,1.28-3.85,2.53-6.14,3.09-10.8.45-3.71-.07-4.66.77-7.45s1.93-4,5.14-8.49c3.66-5.14,5.48-7.7,5.92-8.74,2.1-5,.54-7.37,1.8-14.4a40.13,40.13,0,0,1,3.34-10.29"
                />
                <path
                    className="cls-1"
                    d="M923.84,158c-8.38.16-12.16,2.69-14.05,5.14-2.31,3-1.89,6-4.8,7.54-3.22,1.72-5.49-1-9.26.69-2.61,1.17-2,2.69-5.83,6.17a20.25,20.25,0,0,1-8.57,5.14c-3.51.93-4.32-.18-7.2,1s-3.17,2.69-5.83,4.8c-3.31,2.63-4.46,1.64-11,4.46-4.82,2.08-7.94,3.43-11,6.51-2.17,2.2-2.42,3.37-6.17,7.2a58.06,58.06,0,0,1-4.46,4.12c-2.56,2.08-4.59,3.73-7.2,4.8-4.51,1.84-6.2.1-10.63,1.37-2.9.83-4.12,2.14-8.57,5.83-10.11,8.36-15.16,12.55-20.57,14.4a50.14,50.14,0,0,0-8.92,3.43,44.36,44.36,0,0,0-6.85,4.45c-6.79,5.31-6,7.45-11,11.32-5.42,4.24-7.15,2.31-15.09,7.54-1.73,1.14-4,3.06-8.57,6.86-5.72,4.78-8.8,7.55-12.34,10.63-13.55,11.72-12.68,9.42-16.12,13.37-2.7,3.1-9.38,10.78-11,21.6-.63,4.29,0,6.28-1.37,12.34-1.21,5.53-2.27,6.5-3.43,11.66-1.73,7.71.08,8-1.37,14.4a35.77,35.77,0,0,1-8.57,16.45c-1.43,1.51-1,.72-7.2,6.18-4.47,3.91-6.71,5.87-8.92,8.22a66.19,66.19,0,0,0-7.88,10.63c-5.06,7.58-8.37,6.7-21.26,17.49-10.67,8.93-17.67,14.78-22.63,23.31-.83,1.43-1.59,2.63-2.41,3.8,0,0-.5.71-1.3,1.74a28.11,28.11,0,0,1-3.15,3.72c-3.36,3.35-6.22,4.48-12,8-1.49.91-3.79,2.34-6.56,4.25"
                />
                <path
                    className="cls-1"
                    d="M533.67,26.69c-.18,4-.5,10.35-1,18.17-.84,12.56-1.2,13.71-1.37,20.23-.32,12.39.86,12.42,0,20.57-.84,8-2.09,9.12-2.4,16.46s.91,7.77,1,15.43c.1,6-1.07,12.67-3.43,25.71-1.6,8.91-1.79,8.57-3.08,16.12-1.45,8.41-1.5,10.47-3.09,21.26-.91,6.16-1.86,11.88-3.77,23.31-2.75,16.44-3.23,18.32-3.43,23.66-.25,6.59.36,6.91,0,15.77-.25,6.28-.65,8.17-1,16.8-.11,2.57-.28,6.49-.34,11.66-.12,11.09.47,12.39-.34,17.14-.44,2.56-.6,2.08-3.43,11.31-2.89,9.42-4.34,14.18-4.46,17.15-.21,5.4,1.19,5.3,2.06,15.08a115.79,115.79,0,0,1,.34,13.72c-.24,10.62-1.42,11.63-1,19.2.27,5.19.82,4.52,1.37,12a103,103,0,0,1,.35,14.74c-.42,7-1.4,8.07-1.89,14.48a70.76,70.76,0,0,0,.52,15.3"
                />
                <path
                    className="cls-1"
                    d="M523.73,173.44a21.71,21.71,0,0,0,9.94-4.8c2.48-2.19,3.06-3.87,6.86-7.2,2.4-2.11,3.71-3.26,5.83-4.12,3-1.22,8.36-2.14,11,.34,2.07,2,1.31,5,.34,12.69-.31,2.51-.89,9.9-2,24.69-.74,9.41-.54,10.43-1.38,14.74-1.26,6.56-2.4,7.65-3.77,13.71-1.71,7.61-.92,10.31-1.71,19.2-.88,9.79-2.18,10.33-3.77,20.92-1.83,12.11-.25,12.28-1.72,31.88-.9,12-1.34,18.05-2.74,25.37-1.3,6.85-2.63,11.27-3.09,19.2-.37,6.56.35,6.73.35,14.4,0,2.77-.09,5.11-.25,7.28-.84,11.88-3.18,26-3.18,26a179.63,179.63,0,0,0-2.4,30.9"
                />
                <path
                    className="cls-1"
                    d="M386.59,224.86a194.79,194.79,0,0,0,22.28,1.72c18.41.35,24.36-2.16,28.12-4.46,3.53-2.16,2.49-2.52,6.17-4.8,9.26-5.75,15.71-3.38,27.43-6.51,8.16-2.18,6-3.59,16.45-7.2,9-3.11,11.27-2.3,28.12-5.49,18.34-3.47,19.36-5.12,24.34-3.77,11.56,3.14,17.55,15.19,19.2,18.51,3.46,7,3.79,17.21,4.46,37.72a124.38,124.38,0,0,1-3.77,38.4c-2.29,9.25-3,8.27-4.12,14.06-1.69,8.77-.17,11.64-.68,24.34-.11,2.66-.65,7.6-1.72,17.48-1.29,12-2,18.26-3.43,26.75-1.16,6.87-1.51,7.49-2.74,14.4-1.87,10.52-2.84,19.13-3.37,24.8"
                />
                <path
                    className="cls-1"
                    d="M752.07,114.81a80.73,80.73,0,0,1-7.88,8.23c-2.78,2.5-4.11,3.34-7.55,6.51s-5.18,4.78-6.17,6.17c-1.25,1.77-2,3.43-4.11,16.46-.78,4.71-1,6.33-2.06,12.68-.77,4.63-1.16,6.94-1.37,7.89-1.53,6.76-3.61,8.92-6.17,15.09-5.29,12.71-1.49,15.65-6.52,21.25-3.51,3.92-6.2,3.41-9.94,8.57-2.2,3-1.79,3.94-4.46,9.6-1.44,3.06-4,7.19-9.25,15.43-3,4.73-3.75,5.8-7.2,11.32-3.16,5.05-3.48,5.68-4.46,7.2-6.24,9.68-9.92,9.78-14.06,16.11-4.74,7.26-.73,8.41-6.51,24.34a171.8,171.8,0,0,1-6.86,15.78c-3.34,7-4.47,8.52-6.51,12.68-6.37,13-4.49,16.72-11.32,35.66-3.21,8.91-3.82,8.62-6.17,16.11C619.71,394,621,395.62,618,402.81c-3.39,8.11-6.33,9.08-13.72,20.23a142.42,142.42,0,0,0-10.88,19.62"
                />
                <path
                    className="cls-1"
                    d="M542.24,17.44c1.58,6.8.61,11.39-.68,14.4s-3.06,4.72-3.43,8.57c-.33,3.47.86,4.56.69,7.88-.23,4.35-2.52,7.39-3.78,9.26-4,5.93-6,12.75-9.94,26.4-1.86,6.4-.67,4.14-5.14,23.31-1.49,6.39-2.08,8.6-3.43,14.75s-2.35,10.7-3.43,16.8c-2,11.08-1.46,11.89-3.08,17.83-2.12,7.73-3.08,6.75-4.12,12.34-1.49,8,.37,10.53-2.06,16.8-1.76,4.57-3,3.93-4.45,8.23-2.18,6.56-.76,12.66,0,15.77,1.74,7.11,3.88,6.52,4.8,12.34,1.05,6.63-1.71,7.5-.69,14.74.88,6.24,3.09,6.78,2.74,11.66-.3,4.29-2.07,4.79-3.08,10.29-.71,3.86-.42,6.76,0,11,.92,9.16,2.82,9.68,2.74,15.08-.08,5.57-2.13,6.65-4.11,14.06-1.44,5.38-1.53,9.53-1.72,17.83-.32,14.7,1.87,17.43,0,27.77-.76,4.24-2.12,9.27-2.4,10.29-2.51,9.23-4,10.88-4.11,15.43-.21,6.08,3.17,6.71,3.08,13-.05,4.39-1.66,7.29-2.87,10.8-1.27,3.72-1.4,7.36-1.67,14.64-.14,3.86,0,7.15.13,9.66"
                />
                <path
                    className="cls-1"
                    d="M510.36,1.32a14.35,14.35,0,0,0-6.52,6.52c-1.94,4.35.31,6.75-1,13.37-.8,3.94-1.65,3.38-4.12,10.63a102,102,0,0,0-3.08,11.31c-2.07,9.07-1.08,8.6-2.4,12.34-2.44,6.87-5.23,6.83-7.89,13.72-2.09,5.4-.42,5.54-2.4,12.68-2.8,10.11-6.16,9.87-6.86,17.15-.51,5.38,1.1,7.83-1,12-1.58,3.11-3.07,2.88-5.14,5.82-1.87,2.67-2.37,5.28-2.74,17.15-.4,12.73.07,12.63-.69,15.43-2,7.16-5,7.37-5.83,13.37-.91,6.39,2.4,7,2.4,14.4,0,7.91-3.75,8.91-4.8,19.54a65,65,0,0,0,0,10.63c.51,8.89,1.82,10,1.38,15.08-.53,6-2.47,6.15-3.78,13-1.09,5.79.18,6.21,0,15.77-.18,9.83-1.55,10.63-1.71,21.26-.1,6.59.44,5.33.34,12.69-.1,7.54-.69,11,.69,16.45.9,3.55,1.65,4,2.74,7.2,3.1,9-.35,12.71.34,23.32.76,11.51,5.08,11.06,5.15,22.63,0,6.24-1.21,8.07-.35,14.4s3.95,7.85,4.12,14.05c.09,3.51-.7,4.22-1,9.6a70.39,70.39,0,0,0,.33,10.83c.3,3.54.72,6.53,1.08,8.76"
                />
                <path
                    className="cls-1"
                    d="M458.59,9.21a56.19,56.19,0,0,0,2.74,8.91c1.36,3.4,2.32,4.84,3.09,6.52,2.48,5.43.8,8.65.68,18.85-.07,6,.45,10.32,1.37,18.17,1,8.37,1.48,8.1,2.06,14.4a153.06,153.06,0,0,1,.34,18.86c-.29,13.4-1.08,11.4-.68,15.43.95,9.71,4.58,11.8,8.23,25.71.58,2.22,1.18,5.48,2.4,12,4.61,24.82,3.63,29.44,3.08,31.55-1.86,7.11-4.16,5.83-5.83,13.37-1.42,6.46-.71,11.82.69,22.28a131,131,0,0,0,2.74,14.4c1.84,7.42,2.45,7.13,3.09,10.63,2,11-4.08,13.35-3.43,25.72.43,8.3,3.3,9.5,2.06,16.45-.82,4.57-2.43,6.17-2.4,11.32,0,4.6,1.32,5.49,1,9.94-.36,5.54-2.47,5.75-2.4,9.6.14,6.52,6.21,7.31,6.86,14.06.49,5-2.79,5.53-2.4,12,.25,4.07,1.62,5,1.72,9.6.07,3.79-.82,5.44-1.38,8.57-.81,4.55,1.57,11.52,2.4,18.86.58,5.07-.28,6.57-1.09,11.85-.32,2-.54,4-.83,8a142.72,142.72,0,0,0,0,19"
                />
                <path
                    className="cls-1"
                    d="M515.89,418a562.14,562.14,0,0,1,2.7-57.69c2.58-26.42,4.28-26.64,6.17-50.4,2.72-34.14-.59-41.2,3.43-61.37a127.68,127.68,0,0,0,3.08-24.34c.08-3.23,0-3.7-.34-18.17-.33-16.24-.15-14.76-.34-17.83-.86-13.87-2.65-15.29-2.06-24.34.52-8,2-7.69,1.71-13.38-.3-7.27-2.85-12.4-7.2-20.91-4.95-9.71-7.43-14.57-13-19.2s-11.67-6.85-16.46-8.57a71.52,71.52,0,0,0-13.72-3.43"
                />
                <path
                    className="cls-1"
                    d="M520.39,421.84a100.91,100.91,0,0,1,.77-14c1-7.73,2.06-9.19,2.91-17,1-9.12-.28-8.87,1-14.06,1.89-7.5,4.62-8.21,4.46-13.71-.13-4.45-2-6.82-.34-9.6,1.43-2.41,3.46-1.73,4.8-4.12.87-1.56.54-2.83-.69-10.28-1.05-6.38-1-6.87-.69-7.55,1.91-3.66,8-1.7,10.29-5.48,1.45-2.37-.59-3.7,0-8.23.69-5.3,3.71-5.34,6.51-12.69.47-1.21,1.23-4.35,2.75-10.63.86-3.58,1-4.34,1.71-7.2,1.44-5.73,2.16-8.6,2.74-9.94,3.27-7.47,9.48-5.17,14.4-12.68,2.68-4.08.94-4.91,5.49-16.12,1.79-4.41,1.93-4,3.43-7.88,2.82-7.39,4.24-11.09,4.11-15.78-.15-5.67-2.31-7.39-.68-10.62,1.36-2.72,3.53-2.81,4.8-5.83.94-2.25.48-3.94,0-7.55h0c2.41-4.82-1.34-9.39-.69-16.11.47-4.8.84-8.44,1-10.29"
                />
                <path
                    className="cls-1"
                    d="M460.6,424.15a39.39,39.39,0,0,0-.64-5.53,39.85,39.85,0,0,0-1.8-6.43c-2.46-6.8-2.45-17.76-1.63-32,.75-12.86-1.24-9-4.8-52.46-.58-7.14-.22-3.76-2.4-33.94-1.2-16.68-1.7-23.09-3.09-32.23-1.52-10.1-3.23-17.56-4.8-24.34-3.55-15.4-7-26.58-11-39.09-5.94-18.9-11.6-34.43-14.05-41.14-4.78-13-6.37-16.49-9.26-25.37-1.46-4.49-2.69-7.8-5.14-14.4a65.67,65.67,0,0,0-3.43-8.23c-3.24-6.2-4.78-5.93-6.52-9.94-2.76-6.39.59-8.38-2.05-15.09-1.61-4.08-2.85-3.36-6.52-9.94-2-3.68-1.66-3.92-4.8-10.63a113.09,113.09,0,0,0-6.17-12c-3-4.88-4-5.5-6.51-10.29a35.06,35.06,0,0,1-3.43-7.88,45.59,45.59,0,0,1-1.37-10.29c0-2.89.29-4.56-.69-6.86a11,11,0,0,0-2.74-3.77"
                />
                <path
                    className="cls-1"
                    d="M439.39,94.58c4.61,4.46,8.35,5.45,11,5.48,1.77,0,2.63-.39,5.83-.34a33.68,33.68,0,0,1,7.88,1c2.1.55,3.46,1.18,7.89,3.43,7,3.58,10.59,5.39,12,6.51,2.7,2.16,5.47,4.37,7.2,8.23,2.11,4.72.08,6.17,2.4,11.32,1.88,4.16,3.62,4.13,4.8,8.22,1,3.4,0,4.06,1,7.55,1.19,3.89,2.85,4.33,4.11,7.54,1.68,4.28-.45,5.58,0,11.31.61,7.7,4.68,8.33,5.14,16.12.28,4.7-1.19,4.49-1,9.6.19,5.89,2.3,10.31,4.8,15.43,3.94,8.08,5.83,8,7.54,13.71,1.31,4.39.12,4.16,1.37,12.34,1.46,9.57,3.22,10.82,2.74,16.46-.37,4.34-1.42,3.68-2.4,9.94-.72,4.66-.38,6.56-.68,14.4,0,0-.21,5.51-.69,11-.39,4.51-1,9-3.43,21.6-3.54,18.39-3.67,16.19-3.77,18.85-.4,11.57,3.39,14.71,1,24.35-1.33,5.45-2.79,5.45-3.43,10.62-.7,5.69.8,7.78,1.37,16.8.26,4-.11,2.8,0,12,.1,7.42.32,9,.22,13.46a116.76,116.76,0,0,1-.9,12"
                />
                <path
                    className="cls-1"
                    d="M413,5.09a44,44,0,0,1,1.37,15.43c-.57,6-2.38,10.67.34,14.06,1.64,2,3.67,2.09,4.12,4.11.5,2.3-1.79,3.61-3.09,6.86-.81,2-.85,3.84.34,11,1.83,10.95,2.55,10.52,2.75,14.4.36,7.24-2.23,7.19-2.4,15.09-.16,7.4,2.11,8.22,2.4,17.14.22,7-1.16,6.49-1,13,.19,10.46,3.73,11.58,3.77,21.26,0,7-1.82,7.62-1.37,14.74.35,5.72,1.71,8,3.08,13.37,3.52,13.81-.82,16.86,2.06,34.29.08.46,1.08,5,3.09,14,3.23,14.63,3.42,15.09,4.11,18.86,1.71,9.3,2.43,16.53,2.74,20.23s.4,5.9.69,12.68c.66,15.45,1,23.18,1.37,28.46,1,14.6,1.83,12.12,2.74,26.06.7,10.57.44,14.84,1.72,25.37.54,4.45.57,3.59,2,13.71,2,13.33,2.15,16.56,3.43,25,1.8,11.83,2.66,17.83,3.91,21.53a82,82,0,0,1,2.48,9.2c.92,4.17,1.4,7.63,1.67,9.9"
                />
                <path
                    className="cls-1"
                    d="M473.72,410a66.28,66.28,0,0,1-.52-10.93,105.52,105.52,0,0,1,1.16-10.67c.89-8.1-1.74-9.88-2.06-18.86-.38-10.67,2.28-11.87,2.74-27.09a101.94,101.94,0,0,0-.34-13.71c-.57-6-1.59-10.79-3.77-19.2-5.26-20.33-6.45-20.18-8.23-30.17a108.53,108.53,0,0,1-2.06-20.57c0-11.3,2-12.32.69-18.52-2-9.82-7.37-9.59-11-20.57-1-3.07-1.47-9.36-2.4-21.94-.79-10.74-.47-13-2.4-17.49-1.59-3.67-3.34-5.65-4.12-10.28-.7-4.22.37-4.79,0-9.6-.33-4.39-1.34-5.49-3.77-13.72-1.83-6.22-1.2-5.43-3.08-12-2.86-10-3.76-9.88-4.8-15.43-1.32-7,.1-7.2-1.37-14.05-1.11-5.18-2.13-6-4.12-13-1.56-5.5-1.65-7.5-2.74-12.34-3-13.19-5.51-12.82-9.26-27.09-2.23-8.51-1.94-11-4.8-16.46-2.18-4.19-3.6-5.13-4.45-8.91-1.06-4.66.19-7.12-1-12.34a25.08,25.08,0,0,0-2.75-6.86"
                />
                <path
                    className="cls-1"
                    d="M349.22,10.92a21.68,21.68,0,0,1,2,8.23c.23,5.67-2.08,8.21-.68,12.34,1.14,3.37,3,2.63,4.46,5.83,3,6.52-3.7,11.75-.69,18.52,1.22,2.74,2.37,2,4.11,5.48,1,1.93,1.79,4.5,1.72,12.69-.08,9-1.08,9.52-.34,13.71C360.77,93,362.39,92.29,364,98c2.43,8.89-.86,12.91,2.74,19.54,1.48,2.71,2,2,3.77,4.8,5.83,9.21-.58,16.33,3.43,28.8,1.82,5.66,3.19,4.32,6.17,12.34A81.51,81.51,0,0,1,384.19,181c1.17,8.05.32,8,1.37,14.4,1.38,8.48,3.06,9.8,5.14,18.51s.69,8.44,2.74,20.92c1.9,11.5,2.95,10.94,4.8,22.28,1.94,11.88.91,13.28,3.09,20.92,2.73,9.56,4.72,8.7,6.51,16.11,2.67,11-1.57,13.55,0,27.77a69.56,69.56,0,0,0,4.8,19.2c3.57,8.63,6,9.17,8.23,17.15,2,6.87.57,7.83,3.09,15.77,1.75,5.51,2.83,6.34,4.46,12.34,1.57,5.78,1.1,7,2.4,11.31,1.58,5.3,5.11,10.58,6.44,12.79a26.34,26.34,0,0,1,3,5.86c1.09,3.28.43,4,1.67,6.68,1.16,2.47,2.17,2.75,3.47,5.53a17.85,17.85,0,0,1,1.41,4.5"
                />
                <path
                    className="cls-1"
                    d="M396.19,4.41a167.23,167.23,0,0,0-17.83,26.05c-8.11,14.66-11.91,26.73-15.09,37-5.33,17.3-8,25.94-7.2,37a101.65,101.65,0,0,0,5.49,25.37c1.55,4.11,3.39,8,6.17,16.12a109.22,109.22,0,0,1,3.77,12.34c2.09,9.68.57,12.21,3.43,18.17,2.19,4.56,3.52,4,5.14,8.23,2,5.22.87,8.38,1,14.74.07,2.87,1.31,8.08,3.77,18.52,2.38,10.05,3,11,4.8,19.54.69,3.19,1.26,6.12,2.4,12,1.51,7.77,2.47,13.09,3.09,16.46,2.81,15.26,4.21,22.89,5.14,26.05,5.18,17.55,10.62,15.85,15.09,32.92,2.41,9.22,1.59,12.67,5.48,20.57s6.4,7.87,8.92,15.09c3.35,9.59.13,13.08,4.45,18.85,3.49,4.65,6.92,4.19,8.92,9.26,1.11,2.83.56,4.29,1.71,9.94.5,2.44,1,3.34,2.29,8.17.65,2.4,1.15,4.39,1.48,5.72"
                />
                <path
                    className="cls-1"
                    d="M354,164.52a25,25,0,0,1,3.43,8.57c.72,3.82,0,5.15.68,9.6s1.67,4.56,2.74,9.26c1.21,5.28.2,6.1,1,12.34A61.43,61.43,0,0,0,365,216.64c2.65,7.87,3.84,7.32,5.48,13,2.15,7.44.34,9.1,2.4,16.12,1.88,6.39,3.71,6.14,6.18,12.34,4,10.13-.12,12.7,3.08,24,2.53,8.93,5.29,8,7.89,18.17,1.6,6.3.93,8.16,3.08,14.75,2.22,6.77,3.34,6.07,5.83,13,3.27,9.17,1.77,11.66,5.49,18.52,2.21,4.09,2.78,3.27,5.48,7.88,3.69,6.31,3,8.51,6.52,17.83,4.11,10.94,6.05,10.61,7.2,17.49.87,5.25,0,7.12,1.37,12.34,1.07,4.17,2.44,8,5.54,11.76a19.28,19.28,0,0,1,4,6.28,16.61,16.61,0,0,1,.9,3.86"
                />
                <path
                    className="cls-1"
                    d="M329,186.46c3,3.23,6.9,7.13,11.66,11.32,5.63,5,10.19,8.31,13.37,10.63a196.21,196.21,0,0,0,17.83,11.65c3,1.73,5.13,3,8.22,4.46,13.18,6.41,17.05,4.89,21.6,9.94,3,3.33,1.25,3.92,5.83,10.29,1,1.36,1.76,2.31,9.26,10.63,6.08,6.75,6.6,7.27,7.2,8.91,1.69,4.66,0,7.23,2.4,9.95,2.18,2.43,4.32,1.21,6.51,3.77,1.89,2.2,2,5,2.06,8.91.15,5.9-1.32,6-.69,8.92,1.15,5.27,6,5.09,8.23,11,1.38,3.56-.23,4,.69,10.28a37.42,37.42,0,0,0,4.46,13c3.65,6.31,6.28,5.87,8.91,11,2.17,4.2,1,5.59,2.06,14.75,1.67,13.86,4.91,14.33,4.45,23.65-.23,4.78-1.15,6.19-1.37,13.37a83.05,83.05,0,0,0,.37,11.19c.28,2.76.67,5.09,1,6.86"
                />
                <path
                    className="cls-1"
                    d="M571.39,196.06a81.85,81.85,0,0,0-12.69.35c-7.1.75-13.34,1.4-20.23,4.8-5.34,2.63-6.93,4.87-12.68,6.17-3.47.78-3.85.18-7.89,1-5.2,1.09-8.9,3-13,5.14-6.53,3.41-6,4.2-12,7.2s-6.8,2.3-10.63,4.8-6.14,5.23-9.25,8.91c-5.64,6.69-4.26,7.28-7.55,10.29-5.72,5.23-9.24,2.82-16.11,8.23a25.84,25.84,0,0,0-8.23,10.63c-.92,2.27-3,7.48-1.37,13,1.4,4.66,4,4.56,5.14,9.25,1,3.94-.49,5.37-.68,11a37,37,0,0,0,2.05,13,43.58,43.58,0,0,0,8.92,15.43c4.55,5.3,6.9,4.1,9.6,8.57,1.9,3.16,2.42,6.19,3.43,12a77.34,77.34,0,0,1,1,10.63c.18,6.33-.52,7-.35,12,.27,7.78,2,7,2.06,13.72.06,5.6-1.13,7.43-1,15.08,0,1.79.11,3.75.35,6.19.42,4.41.92,6.17,1.41,9.58a76.77,76.77,0,0,1,.77,10.29"
                />
                <path
                    className="cls-1"
                    d="M557,242.35a95.35,95.35,0,0,0-20.92-2.74c-11.11-.21-20,1.61-27.08,3.08-11.27,2.36-11.24,3.62-19.89,4.8a86.18,86.18,0,0,1-21.6.35c-8.73-1.06-10.58-2.85-20.23-5.15-6.82-1.62-17.34-4.13-28.45-3.08-6.08.57-21.58,2-32.58,14.4C376.55,264.9,372,282.46,378,293.78c1.3,2.43,3.72,5.16,8.57,10.63,7.7,8.68,9.61,8.88,13.37,13.71a47.7,47.7,0,0,1,9.26,23.32c1,9.18-1.86,9.67-1.38,23.31.31,8.63.47,12.95,2.75,17.83,3.7,7.91,8.49,8.72,9.6,16.11.6,4.05-.44,6.41,1.37,10.29,1.32,2.82,3.83,3.14,6,6.68a17.62,17.62,0,0,1,2.18,5.53"
                />
                <path
                    className="cls-1"
                    d="M674.93,130.24a75.45,75.45,0,0,1-.34,13.37c-.81,7-1.86,7.48-2.06,11.65-.42,8.75,4,10,5.83,21.26a36.11,36.11,0,0,1-.69,17.14,39.78,39.78,0,0,1-7.88,13.72c-3,3.55-4,3.72-7.2,7.2a60,60,0,0,0-9.26,14.06c-3.6,8-1.08,10.65-2.4,27.42-.9,11.43-1.58,19.07-6.17,27.43a52.57,52.57,0,0,1-8.92,11.66c-7,7.18-10.64,7.34-18.85,15.43-3.86,3.8-6.43,6.33-8.92,10.63-2.37,4.1-2.66,6.34-6.85,18.51-3,8.69-2.71,7.13-3.78,10.63-2.24,7.33-2.07,9.45-4.8,21.94-2.53,11.6-3.68,14.4-4.45,16.12a48,48,0,0,1-6.86,11.31c-5.14,6.22-6.36,5.09-9.26,9.6-2.22,3.47-2,5.18-5.53,17.79-1,3.69-1.89,6.67-2.44,8.48"
                />
                <path
                    className="cls-1"
                    d="M261.1,936.63a24.41,24.41,0,0,1-1-5.48c-.12-1.79-.06-3.79,1-9.6,1.24-6.57,1.56-6.16,1.72-8.57.29-4.56-.95-4.54-1-9.6-.09-5.89,1.59-6.33,1.37-12.69-.12-3.33-.61-4,0-5.83,1.3-3.92,4.62-3.92,5.14-6.85s-2.42-3.63-4.11-9.26a14.49,14.49,0,0,1-.69-7.54c.7-3.24,2.21-3.32,3.77-7.55,1.32-3.56.44-4,1.72-7.2a18.34,18.34,0,0,1,5.14-7.2c4-3.34,6.44-2,8.92-5.14,2.21-2.77.67-4.31,2.74-6.86,2.29-2.83,4.77-1.66,11.66-4.45,5.24-2.13,4.89-3.26,9.25-4.46s5.35-.25,8.23-1.72c3.78-1.92,3.38-4.21,6.86-6.17s4.75,0,10.28-1.37c6.38-1.53,6.66-4.58,12.69-5.48,3.54-.54,3.23.55,8.57.34,7.62-.3,8.34-2.52,13-1.72,4.21.72,4.82,2.71,9.26,3.09a16.9,16.9,0,0,0,7.31-1.07"
                />
                <path
                    className="cls-1"
                    d="M203.5,793a93.91,93.91,0,0,1,17.49.68c9.78,1.32,11.91,3.22,19.2,2.75s7.91-2.53,13.37-1.72c6.15.92,7.53,3.86,13,3.77,4.46-.07,4.45-2,10.28-3.43s7.14.26,19.55.35a96,96,0,0,0,12-.35c6.74-.7,9.31-1.86,13.71-.68,4,1.08,3.65,2.52,7.2,3.43,6,1.51,8.24-2.25,16.46-3.09,7.86-.8,7.58,2.46,15.43,1.37,5.21-.72,7.65-2.49,13.71-2.4a31.21,31.21,0,0,1,7.11.94"
                />
                <path
                    className="cls-1"
                    d="M223.73,846.46A17.49,17.49,0,0,0,232.3,841c2.45-3,1.69-4.54,4.12-8.92,3.49-6.3,6.36-5.47,8.91-11,2.2-4.75.32-5.94,2.74-10.63s4.46-3.88,7.2-8.57c3.17-5.41.8-7.06,4.12-12.34,2.56-4.09,4.76-4.34,5.83-8.23.83-3-.28-3.7.68-6.86.9-2.94,2.62-4.83,5.15-7.54,3.35-3.6,4.54-3.77,6.85-6.17,3.76-3.89,3.91-6.84,6.17-11,3.53-6.45,8.88-9.75,14.75-13.37,8.68-5.36,9.33-2.46,20.91-8.23,11.29-5.63,12.66-9.37,20.23-9.95,4.1-.31,6.51.58,11-1,4.75-1.71,5-3.77,9.6-5.15,2.15-.64,3.46-.6,11.66-.34l11.41.36"
                />
                <path
                    className="cls-1"
                    d="M146.25,801.89c2.24-2.2,5.54-5.56,9.25-9.94,4.48-5.29,4.55-6.12,8.92-11.32,6.94-8.25,8-7.53,12-13.37,3.34-4.81,3.13-6,6.85-10.63,2.71-3.37,3.72-3.86,6.86-7.88,3.7-4.73,3.41-5.46,5.83-8.23,3.81-4.36,5.94-4.19,11-8.23,5.76-4.63,4.32-6,10.63-12,1.93-1.85,6.11-4.8,14.4-10.63,10.35-7.28,9.74-6.54,12.69-8.91,5.2-4.2,6.48-6,11-8.57a48.24,48.24,0,0,1,9.6-4.12c7.29-2.39,8.81-1.45,16.8-3.43,9-2.22,8.85-3.86,15.43-4.8,6.07-.86,6.1.55,14.4.35,11.76-.29,12.71-3.15,22.28-2.4,5.15.4,6.23,1.34,11,1,6-.4,6.82-2.07,12-2.06,5.36,0,6.51,1.81,12.35,2.4a33.06,33.06,0,0,0,12.15-1.1"
                />
                <path
                    className="cls-1"
                    d="M234.7,789.55a51.47,51.47,0,0,0,4.8-7.2,56.1,56.1,0,0,0,4.12-9.6c1.55-4.78,1.2-5.76,2.74-11.32a60.48,60.48,0,0,1,3.43-9.94c3.43-7.41,6.05-7.83,8.23-14.4,1.21-3.67.42-3.63,1.71-7.2a32.55,32.55,0,0,1,7.54-11.66c2.3-2.28,4.76-4,11.66-7.54,12.44-6.32,14.47-5.73,23.66-10.63,9-4.8,8.77-6.28,14.4-7.88,3.68-1,5-.77,16.8-1.72,12.27-1,10.64-1.27,15.08-1.37,9.69-.2,12.12,1.29,21.26,1a79.79,79.79,0,0,0,12.77-1.38"
                />
                <path
                    className="cls-1"
                    d="M58.47,760.41a54.39,54.39,0,0,1,17.15-12c6.11-2.68,10.48-3.17,24-5.15,7-1,20.42-3,28.8-4.45,16.61-2.93,17.43-4.66,31.88-7.55,13.34-2.67,24-3.26,45.26-4.45,18.76-1,28.14-1.58,39.77-.69s14.14,2.1,29.83,3.09c7.27.45,10.11.4,15.09,1.71,10.06,2.64,10.66,6.06,18.85,6.86,5.76.56,8.33-.85,14.4,1,3.73,1.15,4.08,2.09,7.55,3.42,6.47,2.5,8.7.55,18.51,1.38,8.74.73,9.66,2.51,20.23,3.42a96.94,96.94,0,0,0,10.26.37"
                />
                <path
                    className="cls-1"
                    d="M1.22,742.92a40.26,40.26,0,0,1,5.83-6.51,26.12,26.12,0,0,1,5.48-4.12c3-1.59,3.64-1.08,5.14-2.4,2.2-1.94,1.57-3.77,3.43-6.51,1.37-2,3.08-2.94,6.52-4.8,6-3.28,7.58-2.12,10.63-4.8,3.21-2.83,1.88-4.46,4.8-6.52,4.16-2.94,7.92-.35,11-3.43,1.75-1.77.62-2.75,2.74-5.82,2.25-3.27,4-2.83,6.86-6.18s1.6-4.19,4.11-6.85c3.33-3.53,5.72-2.16,8.57-5.49,2.44-2.84,1.37-4.62,3.77-7.54,2-2.44,4.3-3.06,7.89-4.46,4-1.55,8.24-3.89,16.8-8.57,7.34-4,6.25-4.19,12-7.2,8.31-4.36,10.76-4.08,17.14-7.2,9.27-4.53,9.09-7.54,18.17-13.37,8.05-5.17,8.93-3.29,25.38-10.29,12-5.09,12.44-6.49,27.08-13a228.3,228.3,0,0,1,21.94-8.91c15.3-5.11,27.61-6.71,39.78-8.23,17.39-2.16,26.08-3.25,32.91-1.71,10.55,2.37,15.69,6.81,25.37,4.8,5.94-1.23,6.41-3.4,14.4-4.8,7.5-1.32,8.68.32,17.14-1.37,4.79-1,6.61-1.92,11.66-2.4a49.51,49.51,0,0,1,9.61,0"
                />
                <path
                    className="cls-1"
                    d="M31.39,820.41c1-2.1,2.41-5.31,4.11-9.26,2.78-6.45,3.27-8,4.8-11.32,3-6.37,4.69-10.14,7.55-13.37,4.52-5.12,7.54-4,17.48-9.25,8.67-4.59,7.56-6.06,20.92-14.75,10.78-7,10.41-5.34,16.45-9.94,6.72-5.12,6.85-6.94,18.86-18.51,5.15-5,8.27-8,13-12,9.11-7.72,12.37-9.13,16.8-15.09,3.9-5.26,2.34-5.47,6.51-11,4.79-6.32,10.09-10.3,15.09-14.06A74.43,74.43,0,0,1,183.27,675c9.39-5,12.25-3.46,18.52-7.88,5.27-3.71,4-5.36,9.94-10.29,4.46-3.71,8.57-5.44,16.8-8.91,2.73-1.15,9.75-3.41,23.66-7.89,6.93-2.23,10.48-3.34,14.4-3.43,6.93-.14,7.61,2,14.06,2.4,8.79.5,10.86-3.38,23.65-5.82,5.35-1,3.9-.14,16.12-1.38,17.8-1.79,17.82-3.36,26.4-3.08,6.33.21,7.48,1.11,18.51,1.71,5.16.29,9.41.34,12.25.34"
                />
                <path
                    className="cls-1"
                    d="M21.1,804.63a45.14,45.14,0,0,1,5.49-7.2c4.27-4.55,6.43-4.92,8.91-8.22,3.7-4.92,1-7.19,4.12-12.69,4.12-7.3,9.19-5.55,15.43-13.71,4.06-5.32,2.23-6.48,6.85-12.35,4.32-5.48,6.22-4.85,11-10.63,5.07-6.16,3.21-7.23,7.89-12.34s7.64-5.13,11.31-10.63c3-4.49,1.55-5.25,3.78-7.88,6-7.07,16.29-1.57,23.31-8.23,4.21-4,1-6.44,5.49-12,5.1-6.29,10.22-4.4,22.62-12.34,6.09-3.91,5.14-4.55,8.92-6.18,7.83-3.38,11.34-.38,20.57-3.42,4.65-1.54,3.68-2.28,11.66-5.49,4.64-1.87,8.27-3.33,13-4.11,5.22-.86,6.09,0,15.08,0,5.09,0,4.29-.31,16.8-1s12.73-.4,17.49-1c9.4-1.26,9.2-2.58,15.08-2.74,7.35-.21,9.56,1.79,18.52,3.08,6.3.91,9.23.5,18.85.34,6.45-.1,14.36.17,30.18.69a434.67,434.67,0,0,1,46,4.08"
                />
                <path
                    className="cls-1"
                    d="M89.33,856.41c1.34-1.91,3.14-4.59,5.14-7.89a108.7,108.7,0,0,0,6.18-11.31c2.55-5.51,3.82-8.26,4.45-12.35.73-4.69-.32-5.07.69-8.23,1.48-4.66,4-4.55,7.2-9.94,3.51-5.9,1.25-7.29,4.8-11.66a38.35,38.35,0,0,1,7.88-7.2,10.31,10.31,0,0,0,2.75-2.05c2.4-2.83.94-5.54,2.05-8.57,1.75-4.78,7.2-3.09,12.35-8.92,3.31-3.75,4.55-8.39,5.14-10.63,1.47-5.53.24-7.29,2.4-9.94s4.88-2.44,6.86-5.83c.41-.7.58-1.2,1.37-4.8,2-9,1.84-10.11,3.08-12,1.79-2.72,2.83-1.5,6.18-4.8,4.37-4.32,5.9-9.67,6.85-13,1.25-4.37.34-4,1.37-7.2s2.91-6.29,11-14.4c3.17-3.19,4.94-4.79,8.22-7.88,9.38-8.85,10.53-10.55,13.72-13.37,2.45-2.17,6.87-5.69,23.31-12.69a336,336,0,0,1,31.89-11.66c19.49-6.07,34.87-9.41,50.06-12.68,11.64-2.51,17.46-3.76,25-4.8a231.38,231.38,0,0,1,38-2.08"
                />
                <path
                    className="cls-1"
                    d="M130.05,703.17a119,119,0,0,0-12.6-8.59c-8-4.74-8.7-3.87-11.66-6.52-5-4.49-3.18-7.11-10.67-21-3.88-7.22-5.82-10.83-6.47-11.86-2.1-3.28-4-5.81-4.51-9.84-.28-2.19,0-3.33.39-6.28,1-8.42-.37-10,.34-14.74.77-5,2.57-4.58,7.55-14.4,5.69-11.22,4.16-13.42,8.57-17.83,3.76-3.76,5.42-2.69,12-7.88,4.42-3.49,5.52-5.43,9.6-9.6a88.72,88.72,0,0,1,19.88-15.43c4.92-2.69,10-4.31,20.23-7.54,9.86-3.13,16.09-5.1,24.69-6.52,6.86-1.13,13.37-1.32,26.4-1.71,13.53-.41,14.32,0,26.06-.35,12-.39,13.61-.95,27.77-1.71,22.45-1.21,20.39.07,31.88-1,20.67-2,20.07-5.41,38.06-6.17,11.95-.5,24.33.08,28.8.34,6.26.37,11.54.87,15.43,1.29"
                />
                <path
                    className="cls-1"
                    d="M96.53,420.29a28.37,28.37,0,0,1,6.52,3.09c2.84,1.84,4,3.38,5.82,3.08s2.06-2.28,3.78-2.4c2.26-.15,2.94,3.24,5.48,3.43,2.24.17,2.84-2.39,5.83-3.08.84-.2,2-.26,6.86,1.71,7.1,2.85,8.57,4.51,11,3.77,2.07-.63,1.85-2.13,4.11-3.43,2.94-1.68,5.66-.5,11.66.69,5,1,11,2.18,17.83,1.37,4.86-.58,4.29-1.49,8.23-1.71,7.82-.46,10.15,3.14,16.45,3.08,6.73-.05,6.45-4.17,13.72-5.48,10.12-1.83,13.34,5.65,26.06,4.8,5.44-.37,4.5-1.72,12.34-2.75,5.63-.74,7.23-.19,21.26.35,13.29.5,14.48.11,18.51,1.37,6.14,1.92,5.85,3.6,12.34,5.48,7.4,2.15,8.8.26,16.46,2.4,2.27.64,2.06.78,12.69,5.49,8.33,3.69,12.54,5.55,14.74,6.17,8.79,2.48,10.13.41,15.43,3.09s5.5,5.39,11.31,9.94c5.48,4.29,11.61,6.32,13.72,7.15s3.73,1.37,5,1.76"
                />
                <path
                    className="cls-1"
                    d="M101.33,486.81a14.9,14.9,0,0,0,7.89,4.11c4.7.73,7.67-1.6,11.31.34,1.79,1,1.48,1.74,3.43,3.43,4,3.45,8.07,2.59,14.4,4.12,6.76,1.62,5.58,3.44,11.31,4.8,6.52,1.54,8.12-.78,21.6-1.72,4.22-.29,7.84-.31,15.09-.34,10.41-.05,9.78.35,13,0,10.11-1.08,11.06-4.37,17.83-3.77,6.62.58,7.1,3.84,12.68,3.43,6.31-.47,6.67-4.7,13-5.49,6.89-.86,7.91,3.92,15.43,3.09,5.28-.59,7-3.19,12-2.4,3.18.5,4.89,1.95,6.86,3.08,7.77,4.46,12.75-.12,29.14.69,8,.39,8.3,1.55,19.2,2.06,4.6.21,5.06,0,12,.34,12.69.57,15.79,1.39,17.83,2.06,2.61.85,3.47,1.43,5.83,1.71,3.71.43,5.93-.52,10.63-1.37a46.74,46.74,0,0,1,9.32-1c1.45,0,2.65.07,3.45.13"
                />
                <path
                    className="cls-1"
                    d="M98.59,607.15a42.5,42.5,0,0,0,17.14-3.09c2.91-1.21,3.37-1.83,6.17-2.74,5.66-1.83,10-1.31,15.77-1,8.83.44,13.25.66,16.8,0,10.93-2,12-8,22.29-8.91,5.61-.5,5.32,1.27,12.69,1,7.69-.25,10.88-2.28,19.2-4.12,6.48-1.43,11.11-1.66,19.2-2.06,17.26-.84,20.94,1.76,30.51-1.37,7-2.27,5.09-3.68,12.34-6.17,9.52-3.27,18.4-2.79,31.89-2.06,6,.33,10.28.86,16.8-.34,6-1.11,5.56-2.15,9.94-2.74,10.51-1.41,13.54,4.57,23.32,3.08,5.1-.77,5.12-2.53,11.31-3.42a36,36,0,0,1,13.64.71"
                />
                <path
                    className="cls-1"
                    d="M246.7,714.12c-5.44-6.26-10.27-11.31-14-15.09-3.27-3.25-5.39-5.37-8.58-8.22-4.55-4.08-6.22-5.12-9.6-8.58-3.59-3.66-3.86-4.69-6.51-7.54-5.31-5.7-9.43-7.18-13.71-10.28-4.67-3.38-6.22-6.27-14.4-19.55-13.61-22.05-8.53-14.66-17.49-28.11-3.61-5.41-5.54-8-6.51-12.69-.42-2-1.2-6.85,2.05-18.17,2.88-10,4.49-15.63,9.26-21.94,2.81-3.72,11.26-13.62,25-16.8,4.6-1.06,9.58-.71,19.54,0,9.63.68,15.11,1.13,21.6,4.11,4.7,2.17,5.25,3.51,9.6,4.8,6.47,1.93,11.45.8,15.77.35,8.53-.9,8.5,1.46,26.06,3.77,11.33,1.49,21.4,2.81,34.29,1.71,9.36-.8,11.87-2.17,18.51-1,4.6.79,8.63,2.35,16.8,3.09,1.16.1,3.65.3,6.86.34A89.74,89.74,0,0,0,378,562.93"
                />
                <path
                    className="cls-1"
                    d="M111.27,332.52a21.55,21.55,0,0,0,8.58,5.49c5.15,1.69,6.59-.28,14.05.68A60,60,0,0,1,149,343.15c1.76.71.89.43,6.17,2.74,6.12,2.68,9.19,4,13,5.49,7.46,2.84,7.31,2.17,8.91,3.43,3.87,3,3.73,6.2,7.89,8.23,1.94.94,2.12.33,5.83,1.37a41.58,41.58,0,0,1,9.94,4.45c7.18,4.08,6.09,5.06,9.94,6.52,5.59,2.12,7.15-.21,12.35,2.06,3.1,1.35,5,3.23,8.57,6.85,5.15,5.19,4.45,6.41,7.88,8.92,5,3.63,6.72,1.27,11.66,4.8,5.13,3.67,4.48,7.11,9.6,9.25,2.78,1.17,3.69.46,6.86,2.06,2.88,1.46,3.85,2.93,6.51,5.14,3.66,3.05,6.42,4.1,8.92,5.49,11.43,6.36,10,15.68,21.25,21.26,4.56,2.25,5.64,1.14,16.8,5.48,1.57.61,1.93.78,8.57,3.43,9.31,3.71,9.2,3.62,10.29,4.12,6.4,2.93,6.72,4.66,13.71,8.91,4.93,3,8.71,4.53,16.12,7.54,5.82,2.37,9.77,2.35,16,5.66a53.6,53.6,0,0,1,5.48,3.34"
                />
                <path
                    className="cls-1"
                    d="M154.47,243.72c4.05,4.05,7.7,5.37,10.29,5.83,3.46.61,5-.33,10.63.34a26.49,26.49,0,0,1,7.88,1.72c5.28,2.21,6.22,5.21,11.32,6.17,2.12.4,2-.11,4.11.34,4.11.88,6.3,3.21,9.26,5.49,3.82,2.94,3.48,1.61,11,6.17,3.1,1.89,6.07,4,12,8.23,9.43,6.72,9.42,7.1,11.66,7.88,5.85,2.06,8.28.29,12.34,3.09,3,2.06,2.93,3.89,5.83,7.54,4.25,5.37,7.07,4.82,13.71,9.94,8.88,6.85,6.86,10.16,14.4,14.75,6.58,4,8,1.43,14.06,5.48,7,4.73,6,8.86,13.37,13.72,3.88,2.56,3.88,1.22,12.69,5.14,7,3.12,12,5.35,17.14,9.94,4,3.56,2.75,3.86,8.92,9.95,4.44,4.39,6.23,5.38,7.54,8.57,1.85,4.51-.36,5.91,1.37,9.94.61,1.41,1.56,2.82,10.63,9.26,7.48,5.3,8.25,5.35,9.94,7.54,3.28,4.24,2,6.13,5.14,9.6s6.27,3.35,10.63,5.83c1.9,1.08,4.94,5.12,11.21,12.88a41.08,41.08,0,0,1,4.86,6.92c.81,1.51,1.21,2.52,2.83,5.78,1,2,1.86,3.66,2.44,4.76"
                />
                <path
                    className="cls-1"
                    d="M136.3,410a69.63,69.63,0,0,1,13,4.45c1.92.89,3.1,1.54,7.2,3.43,4.81,2.22,7.11,3.13,7.89,3.43,4.45,1.74,4.91,1.55,6.85,2.4,6,2.62,5.36,6.07,10.63,9.6,6,4.05,8.52.62,16.12,5.14,4,2.41,3.8,3.66,8.57,6.18,4.49,2.37,5.43,1.65,9.94,4.11a45.87,45.87,0,0,1,8.92,6.51c4.19,3.87,3.85,4.91,6.17,5.83,3.88,1.55,5.69-1,10.28.35,2.86.85,2.77,2,6.86,4.45,3.35,2,6.1,2.8,10.63,4.12,7.59,2.21,9.61,1.56,12.68,4.11,2.54,2.11,1.64,3,4.12,4.8,3.87,2.88,6.86,1.4,11.66,3.77,2.92,1.45,3.19,2.68,6.17,5.15,4.76,3.94,9.49,5.25,16.45,7.2,12.42,3.46,13.4.78,19.2,4.11,6,3.42,4.93,6.25,11.66,9.6,4.11,2.05,8.11,2.79,13.37,3.77,2.12.4,4.63.79,10.63,1.37,4.54.44,10.65.94,18,1.29"
                />
                <path
                    className="cls-1"
                    d="M155.85,405.89a93.79,93.79,0,0,1,22.28,9.95c3.5,2.16,3.55,2.53,11.66,7.88,9.28,6.13,14,9.22,18.17,10.63,5.78,2,5,.26,16.11,2.74,8.62,1.93,12.94,2.9,17.83,5.83,4.44,2.66,5.29,4.41,9.6,5.83,4.51,1.48,6.13.41,11.32,1,4.14.49,6.05,1.53,17.14,6.51,9.38,4.22,14.07,6.32,14.74,6.52,13.16,3.78,18.78-1.19,25.72,4.11,3.75,2.87,2.74,4.81,7.54,8.23,1.6,1.14,3.28,2,13.37,5.14,9.06,2.79,9.79,2.71,14.74,4.46,5.78,2.05,6.45,2.75,11.32,4.46,5.26,1.84,8.71,1.48,15,3.86,1.83.68,3.3,1.34,4.26,1.79"
                />
                <path
                    className="cls-1"
                    d="M130.13,457.66a40.77,40.77,0,0,0,13-6.17c3.74-2.71,4.47-4.29,9.26-7.2a92.51,92.51,0,0,1,8.57-4.45c2.49-1.17,4.44-2,5.48-2.4,5.56-2.28,14.49-5.75,17.83-6.86,9.77-3.26,20.74-2.76,36-2.06,5,.23,1.94.24,38.4,4.46,11.34,1.31,16.17,1.84,23,4.46,7.93,3.05,7.91,4.85,17.49,8.57,9.91,3.84,12.18,2.79,20.57,6.85,4.12,2,3.09,2,13,7.89,10.53,6.22,11.07,5.83,16.8,9.6,8.57,5.64,8.54,7.27,14.4,10.63,4.4,2.52,6.66,2,19.36,6.32,2.65.9,4.81,1.67,6.18,2.16"
                />
                <path
                    className="cls-1"
                    d="M150.7,305.44c2.25,5.76,5,7.81,7.2,8.57s3.61.29,5.83,1.71c2.05,1.31,2.32,2.69,4.12,5.14,2.44,3.36,5.17,5.23,8.57,7.55,5,3.44,5.68,2.39,7.88,4.8,2.95,3.22,2.15,5.52,5.15,8.23,1.2,1.08,2,1.33,6.85,3.08,0,0,7.91,2.86,13.72,5.14,5.3,2.09,7.95,3.13,9.25,4.12,4.58,3.48,4,6.36,8.23,11,2,2.16,5.43,4.07,12.35,7.89,10.07,5.56,11.43,5.28,15.42,8.57,5.66,4.65,4.12,6.17,8.92,9.94,7.45,5.85,11.51,2.45,20.23,7.89,7.49,4.67,5.15,7.59,13.37,12.34,5.59,3.23,9.34,3.42,12.34,7.88,1.94,2.89,1.39,4.31,3.77,7.2,2.11,2.56,4.51,3.83,8.92,5.83,10.54,4.79,10.71,3.81,14.05,5.83,6.44,3.9,4.72,6.89,12.69,14.06,3.11,2.8,6.65,4.95,13.71,9.26a106,106,0,0,0,13.37,7.2c2.51,1.09,5,1.68,10.38,3.8,2.46,1,4.47,1.79,5.82,2.37"
                />
                <path
                    className="cls-1"
                    d="M206.93,782a38.4,38.4,0,0,1,9.94-11.32c4.73-3.55,6.62-3.09,10.63-6.86s3.75-5.6,8.23-11a65.2,65.2,0,0,1,8.57-8.23c8.31-6.58,13.77-5,25.72-9.6,17.12-6.64,15.29-13.61,26.4-14.4,7.51-.53,13.66,2.28,20.57-2,2.72-1.71,2.35-2.51,5.14-4.8,7.88-6.45,14.58-3.1,31.2-7.2,7.61-1.88,8.77-3.22,16.46-4.46a81.12,81.12,0,0,1,13.85-1"
                />
                <path
                    className="cls-1"
                    d="M69.79,803.61a65,65,0,0,0,6.86-7.2,68.06,68.06,0,0,0,7.54-11.66,129.25,129.25,0,0,0,7.88-18.17c3.83-11.24,7.71-19.94,14.4-35,6.11-13.72,11-24.61,20.23-37.72a171.73,171.73,0,0,1,20.92-24.34c6.07-5.85,11-9.7,19.2-16.12,11.12-8.69,16.68-13,23-16.45,8.78-4.76,11.36-4.49,25-11.66,8.22-4.31,7.45-4.49,12-6.51,10.48-4.66,13.51-3.23,26.05-7.2,13.32-4.22,12.64-6.71,21.95-8.23,7.33-1.2,6.63.52,20.23-.35,6.13-.39,11.35-1.07,21.6-2.4,9.69-1.25,9.38-1.42,13-1.71,7.95-.63,13.13-.15,21.26.34,6.22.38,15.15.83,26.24,1"
                />
                <path
                    className="cls-1"
                    d="M378.75,652.67A147.64,147.64,0,0,0,347.16,639c-7-2-8.3-1.86-22.29-5.48-12.43-3.22-19.47-5-28.45-8.23-5-1.76-12.81-5.18-28.46-12-9.21-4-13.81-6-18.51-8.23-10.72-5-17.72-8.32-26.4-13.71-6.61-4.1-4.89-3.65-16.12-11-8.13-5.31-11.08-6.88-15.77-11a54.81,54.81,0,0,1-10.29-11c-2.51-3.71-8.43-12.46-7.54-24,.78-10,6.58-20.54,15.77-25.71,4.4-2.47,16.37-1.23,40.12,1.37,19.29,2.11,24.7,3.24,29.14,7.89,2.41,2.52,4.65,6.2,9.94,8.91a26.24,26.24,0,0,0,12,2.74"
                />
                <path
                    className="cls-1"
                    d="M238.47,324a34.36,34.36,0,0,0,8.92,8.57c5.49,3.59,8.12,2.91,13.37,6.86a28.6,28.6,0,0,1,7.89,8.57c3.25,5.6,2.16,8.69,6.17,13.71,2.1,2.65,2.41,1.79,4.8,4.8,2.08,2.63,3.47,5.45,6.17,11,1.9,3.9,2,4.45,3.08,5.82a23.91,23.91,0,0,0,5.83,5.15c1.93,1.25,2.25,1.11,3.77,2a19.93,19.93,0,0,1,6.86,7.55c2.07,4.22.28,5.42,2.4,10.63a16.73,16.73,0,0,0,4.12,6.51c3,2.73,5.74,2.41,13,4.11a66.38,66.38,0,0,1,13,4.12c5.65,2.56,5.33,3.73,12.69,7.2,4.65,2.19,7,3.29,10.28,3.43,3.09.13,4.24-.63,6.52.34.73.31,1.45.75,5.83,5.14,3.24,3.26,3,3.18,3.77,3.77,2.85,2.22,5.21,2.85,7.54,3.78a29.6,29.6,0,0,1,10.2,7"
                />
                <path
                    className="cls-1"
                    d="M230.59,292.75c1.45,4.83,3.72,7.07,5.48,8.23,3.23,2.11,5.33,1,7.89,3.43s1.65,4.92,4.46,7.2c2,1.6,3.13.95,5.83,2.05,4.54,1.87,4.18,4.93,9.94,10.63a45.19,45.19,0,0,0,9.6,7.2c4.37,2.51,4.81,1.83,8.57,4.12,4.28,2.6,4.38,3.88,9.94,8.23,2,1.58,4.55,3.22,9.6,6.51,8.72,5.68,8.79,5.24,11,7.2,4.8,4.32,3.88,5.94,8.58,9.6a37.49,37.49,0,0,0,9.25,5.14c5.67,2.2,7.77,1.46,9.6,3.77,2,2.57.34,4.61,2.4,8.23.93,1.64,2.16,2.77,6.52,5.49,7.09,4.42,7.84,3.93,11.65,6.51,6.35,4.3,5.19,6.3,11.32,9.95,4.71,2.8,6.06,2,8.91,4.8,3.32,3.22,2.13,4.9,5.83,8.91,2.41,2.62,3.75,3.34,7.89,6.51a93.61,93.61,0,0,1,10.79,9.65c1.59,1.64,3.75,4,6.13,6.94"
                />
                <path
                    className="cls-1"
                    d="M248.76,188.86a30.67,30.67,0,0,0,5.49,11c2,2.54,2.7,2.53,6.51,6.51,1,1,2.09,2.23,8.91,10.63,5.93,7.29,5.9,7.37,6.86,8.23,4.78,4.23,6.44,3,11.66,6.85,4.86,3.62,4,5.14,11.31,12.35,2.61,2.56,4.64,4.26,8.57,7.54,7,5.87,8.38,6.33,11.32,8.91,5.3,4.67,5.3,7,14.06,20.58s8.89,11.39,11.31,16.8c5.42,12.08,1.71,16,7.54,23,4.61,5.51,9.66,6.33,10.63,12,.57,3.33-.79,5.24.69,8.91,1.11,2.77,2.28,2.67,5.14,6.52a32,32,0,0,1,5.48,11c1.43,5.51-.28,6.48,1.38,8.91,2.94,4.3,8.74,1.91,11.65,6.17,2.16,3.15-.3,5.51,2.06,8.92,1.86,2.69,3.94,2,8.91,5.14,3.1,1.94,6.1,3.83,7.89,7.2,2.58,4.85-.19,7.46,2.74,13,.88,1.67,2,3,4.33,5.58,3.07,3.48,4.36,3.57,6.73,6.61a33.78,33.78,0,0,1,3.64,6.07c1.13,2.19,1.47,3.25,3.09,6.94,1.12,2.55,2.08,4.6,2.7,5.91"
                />
                <path
                    className="cls-1"
                    d="M206.25,31.49a22.46,22.46,0,0,0,4.8,9.6c2.29,2.65,3.71,2.87,5.48,5.83,1.88,3.15,1.08,4.25,3.09,7.2s3.37,2.51,4.8,5.14c1.79,3.32.1,4.88,1.71,7.89S230,69.36,233,72.64s1.56,5.14,4.8,9.94,5.3,3.88,7.2,7.88c2.06,4.35-.1,6,2.06,10s4.5,2.86,7.2,6.85c3.17,4.71.8,7.47,3.77,10.63s6.26,1.1,10.28,4.46c2.73,2.27,1.83,3.75,5.15,9.6,1.78,3.16,4.16,6,8.91,11.66,5.29,6.31,5.94,6.28,7.54,9.25,2.05,3.8,1.84,5.41,4.12,7.55,3,2.81,4.92,1.48,7.54,4.11s1.22,4.15,4.11,9.26c1.95,3.43,2.57,2.79,4.8,6.51,2.65,4.43,2.13,6,4.46,9.26,1.88,2.67,3.38,3.34,5.83,5.49,5.73,5,8.5,10.93,10.29,14.74,3.94,8.43,2,10.67,6.17,15.43,2.63,3,4.3,3.15,6.85,6.85,2.67,3.87,1.75,5.06,4.12,9.6,1.74,3.36,2.38,3,10.28,12.35,3.86,4.56,4.28,5.34,6.52,7.54a63,63,0,0,0,7.88,6.51c2.31,1.7,11.62,8.53,16.8,18.52,3.71,7.14,2.26,10,7.55,18.86a61.34,61.34,0,0,0,4.8,6.85c11.19,14.48,17.16,13.82,21.25,23.32,1.87,4.34,1.8,7.2,5.15,11s5.3,3.05,7.2,6.17c2.67,4.39-.16,7.54,1.37,15.43,1,5.2,2.33,4.31,3.43,9.26,1.87,8.45-1.83,11.88-.69,20.91a37.81,37.81,0,0,0,3,9.93,21.12,21.12,0,0,1,1.43,4,21.64,21.64,0,0,1,.52,3"
                />
                <path
                    className="cls-1"
                    d="M265.22,152.86a10.91,10.91,0,0,0,2,7.55c1.89,2.28,3.58,1.72,5.49,4.11,2.21,2.77.85,4.66,3.09,8.23,1.74,2.79,2.78,2,4.45,4.46,3.14,4.63.06,8.29,3.09,12.34,1.81,2.42,3,1.24,6.17,4.46,1.84,1.87,1.88,2.72,5.14,7.88a54.49,54.49,0,0,0,4.46,6.52c3.07,3.49,5.54,4.19,8.91,6.51,5.11,3.51,7.88,7.68,10.29,11.32,5.63,8.48,3.66,11.61,9.26,17.14,2.57,2.54,4,2.91,5.83,5.83,2.32,3.79,1.43,5.73,3.77,9.94,1.79,3.22,2.5,2.42,5.83,6.86,4,5.39,3.09,6.72,6.51,10.28,3.14,3.28,4.64,2.89,5.83,5.49,1.57,3.44-.55,5.23.68,8.57,1.45,3.92,5.05,3.28,8.57,7.2,3.69,4.09,1.44,6.64,4.81,15.09,3.06,7.68,5.27,6.45,7.54,13.37,2.34,7.14.26,9.34,3.43,14.4,2.26,3.61,4.07,3.7,6.17,7.88,1.74,3.49.94,4.34,2.4,9.26.9,3.05,1.41,3.41,7.2,14.74,3.79,7.43,3.58,7.29,4.45,8.57,4.39,6.43,7,6,9.6,10.63,3,5.29.67,7.83,2.75,14.4,1.89,6,6.74,6.74,9.41,13.35.31.76.94,2.44,2.07,4.78a60.57,60.57,0,0,0,3.35,6"
                />
                <path
                    className="cls-1"
                    d="M140.07,324a55.2,55.2,0,0,1,9.26,5.14c4.06,2.8,4.83,4.14,8.23,6.52,5.23,3.66,6.26,2.48,13,6.17,8.16,4.45,7.93,6.86,13.71,8.57,4.35,1.29,5.5.23,11.66,1,4.91.64,7.48,1.74,15.09,4.46,15.44,5.51,23.16,8.26,29.48,8.91,7.68.78,8.43-.6,13.37,1s6.74,3.78,16.12,8.23c2.27,1.07,3.88,1.76,6.17,2.74,11.85,5.06,15.39,5.32,23.31,8.57a131.08,131.08,0,0,1,22.63,12.34c7.09,4.72,8,6.25,12.69,7.89,5.86,2.05,9.76,1.51,13.37,5.14,2.72,2.74,1.46,4,4.11,6.86,4.27,4.59,8.29,2.15,14.75,6.51,4.13,2.8,3.18,4.27,8.57,8.23a46.65,46.65,0,0,0,9.25,5.15c4.51,2.11,10.77,5.94,17.75,13.45"
                />
                <path
                    className="cls-1"
                    d="M462,105.21A122,122,0,0,1,455.5,123a145.23,145.23,0,0,1-7.54,14c-7.49,13.11-5.63,15.12-13.37,31.55-8,17-11.09,17.15-16.12,31.2-1.2,3.37-1.85,5.66-5.83,17.14-3.19,9.23-3.84,10.83-5.48,15.77-2.81,8.45-4.6,14.89-6.17,20.57-4.16,15-6.24,22.51-7.2,28.8-3.58,23.27,2.82,25.88-1,44.92-.78,3.85-2.07,8.82,0,14.4s5.58,6.15,7.88,12.68c1.33,3.75.44,4.32,1.72,13,.89,6.08,1.34,9.12,2.4,12,3.64,9.88,9.37,9,12,17.83,1.53,5.16.16,7.41,2.74,13a27.67,27.67,0,0,0,5.19,7.56"
                />
                <path
                    className="cls-1"
                    d="M224.07,163.15c5.26,6.37,10.2,8.49,13.72,9.26,5.48,1.19,8.82-.62,17.14,1,4.26.84,4.46,1.52,8.92,2.74,11.57,3.15,15.27-.11,24.68,2.06,6.92,1.59,11.49,4.86,17.14,8.91,5.15,3.69,11.61,8.32,16.12,16.46,4.36,7.88,1.36,9.06,6.17,19.88,3.16,7.12,4.28,6.22,11.31,19.2,4.08,7.53,4.23,8.81,7.2,13,5,7,6.67,6.5,9.95,12,4.18,7,2.07,9,6.17,16.46,3.67,6.63,5.65,5.52,10.28,12.68,4.2,6.5,3,8,7.89,18.52,5.17,11,6.75,9.82,9.26,17.14,2.65,7.77,1.14,9.92,3.77,20.57.93,3.77,2.33,7.54,5.14,15.09,4.91,13.18,10.47,22.76,16.11,32.91a180.46,180.46,0,0,1,9.65,16.48c1.17,2.37,2.23,4.78,2.23,4.78.73,1.67,1.33,3.15,1.8,4.37"
                />
                <path
                    className="cls-1"
                    d="M296.07,268.75a53.94,53.94,0,0,0,5.15,13c3.28,5.86,4.65,5.59,6.85,10.63,2.88,6.56,1.74,9.73,5.15,12.34,2.21,1.7,3.59,1,6.51,3.43,2.34,1.91,2.44,3.13,4.46,5.48,1.76,2.06,2.68,2.29,9.94,6.86,5.53,3.48,8.32,5.24,9.26,6.52,2.7,3.65,1.18,5.31,4.11,9.94,2.19,3.44,3.31,3,6.86,7.2,2.86,3.42,4.38,6.42,6.51,10.63,3.26,6.42,2.38,6.62,5.15,11.65,3.23,5.88,4.17,5.13,6.85,10.63,1.8,3.67,2.11,5.5,4.46,8.57,1.9,2.48,2.26,2,4.46,4.8,3.46,4.36,3.27,6.37,5.83,7.89,2.27,1.35,3.44.37,6.17,1,5.11,1.23,7,6.1,12.34,13.71,4.48,6.35,6,6.27,9.63,11.71a72.88,72.88,0,0,1,5,8.61"
                />
                <path
                    className="cls-1"
                    d="M121.9,548.52a67.37,67.37,0,0,1,20.57-3.43c4.94,0,7.47.57,13.72,0,6.72-.61,6.47-1.54,13-2.06,7.85-.62,9.84.58,14.05-1.37,3-1.38,2.52-2.22,6.86-4.45,3.44-1.77,6.64-3.41,10.29-2.75,2.93.54,3.09,2,5.83,2.4,3.43.49,5.71-1.47,8.91-3.08,5.9-3,11.48-3,17.14-3.09,7.29-.07,6.95,1.87,15.43,2.4,6.78.43,8.26-.73,18.17-1.37a155.14,155.14,0,0,1,18.86,0c14.41.91,19,3.47,24.34,0,3.58-2.32,2.7-4.24,6.52-6.51,1.63-1,5.26-2.69,18.51-.69s17,4.78,23.66,2.4c2.62-.94,2.74-1.63,5.48-2.4,4.08-1.15,6.89.38,16.38,1.77l3.42.46"
                />
                <path
                    className="cls-1"
                    d="M167.5,607.49a66.25,66.25,0,0,1,11.32-23.66c1.62-2.11,3.48-4.15,7.2-8.22,6.47-7.1,9.81-10.71,14.4-13.72,5.48-3.59,6-2,12.34-5.48,9.65-5.28,15.45-12.78,19.54-18.17,5.4-7.13,3.94-7.52,9.95-15.78a108.19,108.19,0,0,1,15.08-16.8c7.48-6.68,14-10.43,20.92-14.4,7.42-4.23,16.87-9.62,30.17-13.37,12.86-3.62,11.32-.63,27.77-4.8,9.69-2.45,14.38-4.55,21.26-2.74,3.67,1,4.86,2.23,12.68,5.83,5.89,2.71,10.87,4.59,14.26,5.79"
                />
                <path
                    className="cls-1"
                    d="M91.05,692.18a44.64,44.64,0,0,1,3.77-6.52c4.89-7,9.39-9.14,14.74-14.74,2.33-2.44,1.46-2,8.91-12.69,8.78-12.55,13.17-18.82,17.49-22.62,5.55-4.88,7.81-5.07,12-10.29,4.74-5.9,3.74-8,7.54-10.63,6-4.09,11.11-.68,15.77-5.48,1.85-1.9,1.53-2.93,4.46-6.18a32.41,32.41,0,0,1,6.52-5.48c8.12-5.44,8.54-8.43,16.11-13.72,8.38-5.85,9.14-3.07,17.83-8.91,9.29-6.24,8.74-9.63,15.43-12,7.36-2.61,9.4,1,17.48-1.37,9.13-2.68,7.63-7.61,16.46-10.63,8.67-3,12.18,1.09,25-2.4,5-1.37,4.22-1.92,8.23-2.74,10.16-2.1,13.07,1.92,22.28.34,8-1.36,7.46-4.66,14.4-4.8,5.25-.1,6.59,1.75,15.09,2.4,3.93.3,5,0,10.28,0a151.69,151.69,0,0,1,17.84,1.06"
                />
                <path
                    className="cls-1"
                    d="M378.21,556.09c-2.18.09-5.32.21-9.11.32-9.22.25-13.89.38-17.14,0-9.87-1.16-8.45-4.26-15.77-4.46-8.25-.22-11,3.7-20.23,2.4-3.44-.49-3.52-1.1-6.86-1.37A32.38,32.38,0,0,0,294,555.72c-6.72,2.64-17.06,6.57-23,8.57-13.28,4.49-16.24,3.28-26.4,8.23A88.39,88.39,0,0,0,234,578.69c-9.87,6.66-9.73,9.35-18.52,15.77a105,105,0,0,1-20.23,11.32c-7.39,3-9.12,2.59-12.34,5.48-5,4.44-3.67,8-9.6,15.43a42.92,42.92,0,0,1-9.6,8.92c-5.25,3.45-6.71,2.54-14.06,6.51-5.78,3.13-6.13,4.38-11.31,7.2a58.5,58.5,0,0,1-14.4,5.49"
                />
                <path
                    className="cls-1"
                    d="M114.7,643.83c.25-3.1.67-7.47,1.37-12.68,1.22-9,1.83-13.47,3.43-18.52a50.63,50.63,0,0,1,11-19.88c5.82-6.27,11.91-9.12,17.49-11.66,11.07-5,20.43-6,32.23-7.2,1.72-.17,9.83-.34,26.06-.68,11.33-.24,14.6-.24,19.2,1.37,7.49,2.62,8.12,5.87,14.4,7.2,4.87,1,7.85-.23,19.2-3.77,15.52-4.85,23.36-7.26,27.08-6.86,8.68.94,7.3,5.2,21.94,9.94,6.92,2.24,14.72,4.77,24.35,3.43,7-1,7.83-3,13-2.4,7.06.81,9.08,5,17.14,7.54A35.23,35.23,0,0,0,377.25,591"
                />
                <path
                    className="cls-1"
                    d="M230.25,809.78c7.8-3.09,14-5.91,18.17-7.89,9.74-4.63,10.35-5.59,18.17-8.91,5.23-2.22,8.35-3.23,15.43-6.52,8.21-3.81,9.6-5.05,10.63-6.17,2.85-3.11,3.42-5.9,6.85-7.2,2.57-1,3.41.16,5.83-1,3.41-1.67,3-4.54,5.83-5.48,2-.67,2.6.64,7.2,1.37,3,.47,6,1,8.91-.34,3.26-1.48,2.52-3.58,5.83-5.49,1.72-1,4.71-1.13,10.63-1.37a57,57,0,0,1,8.57,0c3.44.36,3.69.84,8.23,1.37,3.79.44,4.08.16,6.52.69,3.17.69,3.8,1.41,6.17,1.71a14.54,14.54,0,0,0,6.4-.69"
                />
                <path
                    className="cls-1"
                    d="M224.07,784.75a23.26,23.26,0,0,0,10.29-9.6c2.25-4.27,1.19-6.16,3.43-8.57,3.89-4.2,9.08-.64,18.51-3.43,8.62-2.55,8.49-6.78,15.43-7.54,7.38-.82,8.3,3.87,16.12,4.11,11.12.35,12.88-9,24.68-8.57,5.34.21,7.59,2.22,14.4,1.37,3.88-.49,4.78-1.35,9.26-1.71,4.09-.34,6.09.15,12,.68,3.39.31,2.43.12,13.37.69,4.37.22,10.56.57,18.16,1.07"
                />
                <path
                    className="cls-1"
                    d="M382.77,724.17a26.93,26.93,0,0,0-8.87.58c-6.53,1.55-8.56,4.74-14.74,5.83-4.17.73-4.69-.46-9.6-.35-3.57.09-5.6.78-18.17,5.83-11.87,4.78-17.8,7.16-21.6,8.92a83.94,83.94,0,0,1-8.57,3.77c-9,3.12-10.76,1.16-15.09,3.77-1.8,1.09-4.65,5.31-10.28,13.71-5,7.43-5.16,8.07-6.86,9.26-4.35,3-7.48,1.9-13.37,4.8a28.37,28.37,0,0,0-8.23,6.17"
                />
                <path
                    className="cls-1"
                    d="M383.37,694.91c-2.84.12-6.94.33-11.87.7-8.68.64-13,1-16.8,1.71-9.23,1.82-9.74,3.75-16.11,3.77-5.36,0-5.69-1.34-10.29-.68a33.48,33.48,0,0,0-12.34,4.8c-2.11,1.28-1.67,1.28-4.46,3.08a57.84,57.84,0,0,1-11.31,5.83c-4.86,1.79-5.21.9-9.94,2.4-3.18,1-5.83,2.3-13,6.86-12.63,8-10.45,7.79-14.4,9.6-9,4.1-12.22,1.46-20.92,6.51-1.79,1-.9.72-7.54,5.14-7.51,5-8.66,5.43-11.66,7.89-4.82,4-4.42,5-7.88,7.54a30.3,30.3,0,0,1-13.72,5.49"
                />
                <path
                    className="cls-1"
                    d="M178.47,788.52a23.2,23.2,0,0,1,5.83-8.23c4.16-3.68,6.24-2.58,8.57-5.48,3.92-4.89-.36-10,3.78-15.78,1.78-2.5,2.63-1.64,6.51-5.82,2.1-2.27,3.14-3.92,5.83-7.89,3.91-5.79,5.87-8.69,6.86-9.94a59,59,0,0,1,15.77-13.72c4.7-2.9,5.17-2.34,8.57-4.45,8.25-5.13,9.32-10.8,15.43-18.52,10.48-13.25,25-19,30.85-21.26a123.11,123.11,0,0,1,14.4-4.45,139.2,139.2,0,0,1,14.75-2.75c13.83-1.94,20.72-3.09,28.11-3.08,4.48,0,3.68.28,20.82,1.44,6.67.45,12.16.77,15.86,1"
                />
                <path
                    className="cls-1"
                    d="M377.21,613.65a217.32,217.32,0,0,1-22.68,1.56c-14.81.23-19.5-1.21-34.46-1a162.65,162.65,0,0,0-27.25,2.05c-7.72,1.32-16.39,4.16-33.43,9.78-21.16,7-26.32,9.52-28.8,10.8-4.39,2.25-5.29,3.05-20.06,12.85-32.68,21.69-31.32,19.81-37,24.69-11.16,9.55-12.54,13.49-20.05,15.43-4.32,1.11-10.25,1.46-15.43,6.17a21,21,0,0,0-4.63,6.17"
                />
                <path
                    className="cls-1"
                    d="M378.27,645.57c-13.44-.7-25-.73-34-.54-5.06.11-15.82.36-29.83,1.55-12.64,1.07-15,1.81-22.63,2-12.46.4-16.53-3.51-27.77-2a61.1,61.1,0,0,0-13.89,3.6c-5.39,2-13.41,4.91-20.57,11.83-5,4.79-4.47,6.62-9.26,11.82a50.19,50.19,0,0,1-18,12.35"
                />
                <path
                    className="cls-1"
                    d="M377.75,635.24a98.85,98.85,0,0,1-31.45-2c-18.49-4.28-21.48-10.79-36.51-12.35-17.08-1.76-16.37,7.35-38.06,6.17-13.55-.73-23.69-4.55-39.6-1.54a62.31,62.31,0,0,0-15.43,4.63c-5.89,2.7-7.76,4.79-15.43,8.74-5.44,2.82-8.65,4.47-12.85,5.66-11.55,3.26-14.17-1.66-27.26,1a44,44,0,0,0-17,7.2,40.74,40.74,0,0,0-7.2,6.68"
                />
                <path
                    className="cls-1"
                    d="M381.54,500.19c-6.61-1.35-16.69-3.41-29.07-5.84-22.05-4.34-20.9-3.89-26.22-5.14-23.7-5.57-24.65-9.25-42.18-11.83a94.89,94.89,0,0,0-29.82.51c-5.74,1-10.39,2.49-14.92,5.66a27.31,27.31,0,0,0-9.26,10.29c-1.78,3.62-.74,3.88-3.08,12.34-1.48,5.33-2.18,6.27-4.12,12.86-1.22,4.14-2.06,7.56-2.57,9.77"
                />
                <path
                    className="cls-1"
                    d="M183.27,457.84c5.81,4.18,10.59,7.5,13.89,9.77,7.75,5.32,11.62,8,12.86,8.74,4.91,3,6.87,3.83,9.77,6.69s3.15,4.26,5.66,6.17c4.14,3.16,8.8,3.42,10.8,3.6,14.36,1.3,19.78,10.35,40.11,13.37,11.75,1.74,11.19-1.09,25.71,1a178.14,178.14,0,0,1,23.66,5.14c5.64,1.72,7.45,2.66,12.34,3.6a84.06,84.06,0,0,0,17.49,1c5.23-.33,13.58.12,27.26,3.6"
                />
                <path
                    className="cls-1"
                    d="M219.27,540.63a80.5,80.5,0,0,1,11.32-20c6.52-8.4,10.16-9.45,13.88-17,1.24-2.5,2.39-8.22,4.63-19.55,3.31-16.7,3.84-19.37,5.15-24.17,2.88-10.59,5.2-15.48,7.71-19.54a45.5,45.5,0,0,1,6.69-8.74c9-8.9,21.14-10.25,26.74-10.8,14.76-1.45,26.28,3.18,40.11,8.74,7.53,3,14.95,6.86,21.6,10.29a115.82,115.82,0,0,1,11.83,6.68c7.08,4.71,6.26,5.2,10.29,7.2,7.2,3.58,10.84,2.11,16.2,6.17a21.65,21.65,0,0,1,5.14,5.66"
                />
                <path
                    className="cls-1"
                    d="M205.9,314.86c8.43,12.77,15.75,16,21.09,16.46,3.36.27,6.91-.52,11.83,1.54,1.32.56,4.61,2.11,9.77,7.72,6.67,7.26,5.36,9.26,9.26,12.86,7.15,6.6,13,1.21,25.71,7.71,7.46,3.82,6.79,6.36,14.4,9.26,8.21,3.12,10.51.73,15.43,4.11,6.36,4.37,4.54,9.73,11.31,13.89,4.34,2.66,5.51.71,11.32,3.6,5.06,2.51,4.91,4.37,12.34,10.28,5.48,4.37,8.53,6.8,13.37,8.75,6.73,2.7,10,1.69,12.86,5.14,2.7,3.21.91,5.3,3.6,8.74,3,3.89,6.94,3.3,12.86,6.17,6.09,3,9.76,7.65,11.38,9.48a54.85,54.85,0,0,1,4,5.18,57.66,57.66,0,0,1,4.12,6.94"
                />
            </g>
        </g>
    </svg>
);

export const Mask2 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 885.5 1318.53">
        <g id="Mask2" data-name="Calque 2">
            <g id="Calque_10" data-name="Calque 10">
                <path
                    className="cls-1"
                    d="M607.31,545.81l-5.4,115h-43.2A1738,1738,0,0,0,546.36,835.1c-1.07,54.54,1.06,81.87-.77,165.08-1.17,53.26-1.82,80.63-5.4,116.49,0,0-7.05,70.71-47.06,165.08-2.29,5.42-7.4,17-19.28,25.46-1.5,1.07-18,12.45-38.57,9.26-22.22-3.45-34.93-21.56-44.75-35.49-9.6-13.62-14-26.29-20.22-43.88a291,291,0,0,1-9.86-34.8c-12.08-55.68-13.12-112.63-13.12-112.63-1.07-59.06-2.45-135.76-8.4-211.37-2-25.66-3.37-36.12-5.13-55.54-1.18-13.07-1.84-21.91-2.67-33.18-1.33-18.17-4-53.46-9.69-127.67-1.58-.11-4.16-.28-7.36-.47s-5.65-.31-6.17-.34L281,659.21l-.76-112.63,37.45-.17c-.35-4.76-.69-11.65-.56-20,.14-8.87.77-15.59.9-16.88,1.71-17,13-43.76,34.71-65.58a111.1,111.1,0,0,1,30.09-22.37c4.29-2.14,18.51-8.41,41.49-11.14,16.37-2,53.34-6.34,88.88,16.54,27.73,17.85,44.58,45.86,48.43,64.89.59,2.89,2.21,12.19,3.26,23.83a262.27,262.27,0,0,1,1,26.86Z"
                />
                <path
                    className="cls-1"
                    d="M389.33,1174l51.09,38.06,48-30.86-44.23-31.71Z"
                />
                <path
                    className="cls-1"
                    d="M411.62,1176.58l27.94,21.09,31.89-15.26-26.74-19Z"
                />
                <path
                    className="cls-1"
                    d="M299,576.24l15.41.34q1.29,29.92,2.57,59.83l-16.92.35Q299.52,606.49,299,576.24Z"
                />
                <path
                    className="cls-1"
                    d="M559.05,536.81c-3.65-4.67-16.68-20.13-37.89-23.14a60.29,60.29,0,0,0-24.34,1.89c-6.31,1.67-15.85,4.21-24.51,12.51-11.55,11.06-13.89,25-14.4,28.11a100.49,100.49,0,0,0-1,12.78,145.36,145.36,0,0,0,.34,16.37c1.24,13.26,2.94,33.8,4.11,59,2,42.24.67,59.44,1,110.23.33,46,1.76,81.07,2.92,109.71.59,14.62,2.14,50.77,5.14,97.54,1.08,16.85,2.1,31.22,2.91,42.17l-25.71,11.49-24.69-14.57c1.32-58.56,4.25-106,6.69-138.69,5-66.74,9.05-85.87,10.46-142.11.74-29.58.13-44.54,2.4-75.94,1.62-22.45,2.25-45,4.45-67.37,0-.32.82-8.31.86-19,0-6.84,0-10.32-.68-13.89-2-10-7.68-16.7-10.29-19.71-9.78-11.3-22.37-15-30.69-17.32-8.75-2.44-40.07-9.69-67,8.57a69.11,69.11,0,0,0-14.06,12.69,127.93,127.93,0,0,1,5.31-29.49c2.9-9.49,8.93-28.48,25.89-46.45a108.89,108.89,0,0,1,42-27.43c15.87-5.69,29.26-5.62,56.06-5.49,24,.12,32.95,3,37.88,5,12.14,4.76,20.61,11.74,25.72,15.95A119.75,119.75,0,0,1,539,462.93c4.64,6.5,12.3,17.45,17,33.77A101.45,101.45,0,0,1,559.05,536.81Z"
                />
                <path
                    className="cls-1"
                    d="M353.63,580.72c2,.6,6.74,1.86,15.64,2.41a115.83,115.83,0,0,0,41.37-4.39,109,109,0,0,0,17.85-7.11,46.75,46.75,0,0,0-13.21-9.21c-10.63-4.93-20.38-4.62-33.84-4.18-11.42.37-19,.69-28.16,4.5a54.52,54.52,0,0,0-16,10.24A57,57,0,0,0,353.63,580.72Z"
                />
                <path
                    className="cls-1"
                    d="M475.13,574.22a47.37,47.37,0,0,0,19.15,11.44c8.12,2.43,14.83,2,24.05,1.42,6.85-.46,11.51-.77,17.35-3a45.63,45.63,0,0,0,16.46-10.8,50.14,50.14,0,0,0-23.91-14.66c-18.19-4.76-33.48,3-38.83,5.66A61.26,61.26,0,0,0,475.13,574.22Z"
                />
                <path
                    className="cls-1"
                    d="M336.73,591.43c8.45,7.56,18.29,9.82,26.34,11.51,11.19,2.34,32.5,6.91,52.55-3.56a52.27,52.27,0,0,0,23.69-25.13,47.09,47.09,0,0,0-20.75-30.4C396.71,530,365,534.61,346,548.35a52,52,0,0,0-19.58,27.94A37.16,37.16,0,0,0,336.73,591.43Z"
                />
                <path
                    className="cls-1"
                    d="M465.16,574c13.54,25.59,27.25,31.9,35.14,33.71l1.19.25c21.48,4.4,39.39-8,42.82-10.41a64,64,0,0,0,20-22.69c-1.33-5.91-5.11-18.68-16.43-29-14.19-12.92-39.25-21.07-59.66-9.13C468.75,548.09,465.55,570.78,465.16,574Z"
                />
                <path
                    className="cls-1"
                    d="M568.8,603.09c-.87,12.25-1.15,23.77-1,34.46l16.52.19q1.74-31.82,3.47-63.64l-15.7-1.18C570.7,582.32,569.56,592.39,568.8,603.09Z"
                />
            </g>
            <g id="Calque_11" data-name="Calque 11">
                <path
                    className="cls-1"
                    d="M611,499.3c0,4.36-.15,8.39-.33,12.06l-46.13.68a109.07,109.07,0,0,0-11.9-42.34,112.63,112.63,0,0,0-31.77-37.3c-35.48-26.73-76.25-23.69-90.08-22.66-12.31.92-31,2.51-51.59,13.67,0,0-32.1,17.35-51,54.63a114.13,114.13,0,0,0-7,17.58A98,98,0,0,0,318,511.26c-.22,1.84-.36,3.39-.44,4.52l-43.81-3.24c-.16-3.29-.31-6.57-.47-9.85"
                />
                <path
                    className="cls-1"
                    d="M610.09,475.52c.4,5,.65,9.76.78,14.26"
                />
                <path
                    className="cls-1"
                    d="M606.85,450.49c1.19,6.68,2.07,13.11,2.7,19.23"
                />
                <path
                    className="cls-1"
                    d="M601.12,425.34c1.71,6.11,3.14,12.07,4.34,17.87"
                />
                <path
                    className="cls-1"
                    d="M588.18,388.1c2.69,6.66,5.05,13.27,7.36,19.74q1.23,3.42,2.33,6.8"
                />
                <path
                    className="cls-1"
                    d="M571.2,355.42a149.78,149.78,0,0,1,9.08,15.3"
                />
                <path
                    className="cls-1"
                    d="M546.9,328.19q3.63,3.13,7.16,6.62t6.82,7.25"
                />
                <path
                    className="cls-1"
                    d="M508.24,304.29a151,151,0,0,1,20.33,10.48"
                />
                <path
                    className="cls-1"
                    d="M455.78,291.4A183,183,0,0,1,488,297.24"
                />
                <path
                    className="cls-1"
                    d="M405.64,294.61a158.63,158.63,0,0,1,19.5-3.35c1.22-.13,4.65-.46,9.76-.58"
                />
                <path
                    className="cls-1"
                    d="M357.46,314.64A166,166,0,0,1,391,298.81"
                />
                <path
                    className="cls-1"
                    d="M321.46,346.13c9.69-11.82,16.94-17.84,16.94-17.84,2.26-1.87,5-4.06,8.27-6.42"
                />
                <path
                    className="cls-1"
                    d="M300.51,377A216.17,216.17,0,0,1,314,355.82"
                />
                <path
                    className="cls-1"
                    d="M289.1,400.64c2.24-5.44,4.6-10.59,7-15.43"
                />
                <path
                    className="cls-1"
                    d="M280,427.69c1.69-6.38,3.61-12.45,5.69-18.23"
                />
                <path
                    className="cls-1"
                    d="M274.13,458.58c.84-7.09,2-13.9,3.39-20.42"
                />
                <path
                    className="cls-1"
                    d="M272.62,490.63c-.1-4.11-.16-10.89.36-19.28"
                />
            </g>
            <g id="Calque_9" data-name="Calque 9">
                <path
                    className="cls-1"
                    d="M583.56,501.37q26-2,52.27-3.88,37.7-2.69,74.94-5l-5.17-2.68,1.64-4.52q-5.55.87-11.15,1.69-18.66,2.74-36.78,4.89l42.86-12.35-6.34-1.8,4.37-6.43L690.69,473l3.08-5.32a637.75,637.75,0,0,1-61.54,17.83C614.18,489.75,597,493,581,495.4Q582.27,498.39,583.56,501.37Z"
                />
                <path
                    className="cls-1"
                    d="M562.66,498.32a8,8,0,0,0,4.82-.85c1.5-.85,1.89-1.86,3.63-2.42a10.58,10.58,0,0,1,3.15-.26,12.24,12.24,0,0,0,5.66-1.86q2.51,5.79,5,11.59a36.68,36.68,0,0,0-5.82,1.37c-2.52.82-3.65,1.51-5.15,2.06a18.13,18.13,0,0,1-9.73.56C563.69,505.11,563.18,501.72,562.66,498.32Z"
                />
                <path
                    className="cls-1"
                    d="M586.8,482.58c13.85-4.48,28.73-8.78,44.57-12.69,21-5.18,40.88-9,59.32-11.74l-8.75-2,8.23-4.2-6.86-2.66,5.32-7.11c-19.11,7.52-39.46,14.89-61,21.85q-22.54,7.29-43.89,13.12Z"
                />
                <path
                    className="cls-1"
                    d="M558.09,482.12a15.17,15.17,0,0,1,8.14-4.43c1.51-.24,2.25-.05,5.66-.51a37.63,37.63,0,0,0,5.4-1,31.4,31.4,0,0,0,4.88-1.8q3,5.4,5.92,10.8l-7.72,1.29a19.16,19.16,0,0,1-5.91,2.82c-2.34.65-3.65.5-6.69,1a38.08,38.08,0,0,0-6.15,1.61Z"
                />
                <path
                    className="cls-1"
                    d="M550.62,466a17.67,17.67,0,0,0,7.12-3.26c1.76-1.37,2.25-2.33,3.86-3.6a16.76,16.76,0,0,1,8.23-3.34l5.4,13.62L569,471.61a21.13,21.13,0,0,1-12.3,5.57Z"
                />
                <path
                    className="cls-1"
                    d="M571.2,459.61q30.48-14,63.09-31.2c18.78-10,36.61-20,53.48-30q-1,3.51-2.06,7l15.09-7.89-2.74,7.71-13,7,6.68.86,6.18,3.09q-28.85,10.13-58.8,21.6-33.93,13-65.66,26.4C572.69,462.69,571.94,461.15,571.2,459.61Z"
                />
                <path
                    className="cls-1"
                    d="M541.37,451.38a14.78,14.78,0,0,1,5-5.83c1.81-1.2,3.2-1.46,6.69-2.74,1.84-.68,4.38-1.68,7.37-3.09l5.14,6.69a9.17,9.17,0,0,1-1.54,5c-.57.81-1.14,1.29-5.49,3.94-2.93,1.79-4.43,2.7-6.17,3.6s-3.41,1.67-4.71,2.23Z"
                />
                <path
                    className="cls-1"
                    d="M528.05,438.32c.68-.77,1.71-2,2.87-3.57,1.66-2.29,1.72-2.81,2.91-4.11,1.85-2,2.42-1.55,4.11-3.43,1.92-2.14,1.67-3.27,3.26-4.46a7,7,0,0,1,4.46-1.2L553,427a34.48,34.48,0,0,1-2.92,7.54,16.69,16.69,0,0,1-3.6,5.14c-1.65,1.5-2.49,1.49-4.45,2.92a18.94,18.94,0,0,0-4.72,5Z"
                />
                <path
                    className="cls-1"
                    d="M561.55,441.22A467.88,467.88,0,0,0,669.26,343c-.69,3.14-1.37,6.28-2.06,9.42l6.34-3.6q-5.31,8.75-10.63,17.49L690,360.18l-5.1,5.92,20.87-7.46-19.71,22.62,20.74-1.37-20.4,7.2L711.94,389a493.21,493.21,0,0,0-147.8,55.6Z"
                />
                <path
                    className="cls-1"
                    d="M584.4,334.64c5.72-9.34,11.29-18.43,18.51-29.66,5.76-8.94,10.61-16.22,13.89-21.09l-9.26,3.77.69-7.88-6.34,3.43-3.09-9.77-7.54,7.37-3.6-7.89a823.33,823.33,0,0,1-31.72,80.4c-9.11,20-18.53,38.48-27.94,55.54l3.33,2.05c4.15-5.18,9.82-12.37,16.38-21.07,7-9.34,12.38-16.41,18.52-25.55C571.52,356.42,570.85,356.76,584.4,334.64Z"
                />
                <path
                    className="cls-1"
                    d="M550.62,425.24c36.86-36.74,75.61-74.43,112.47-111.18l-13.89,5.49,10.46-12.17H648.51l-12.17,10.46-16.45,11.65,17.48-30.17-7.54,2.4q-.18-3.51-.34-7l-6.69,6.86.17-11q-13.26,27.3-29.14,56.23c-15.18,27.5-30.63,53-45.92,76.41"
                />
                <path
                    className="cls-1"
                    d="M525.77,407.49a12.56,12.56,0,0,1-3.08,4.46c-1.85,1.69-3,1.57-4.63,3.43a13.7,13.7,0,0,0-2.4,4.11,37.19,37.19,0,0,0-2.47,7.52l7.66,5.39a12.88,12.88,0,0,0,6.12-4.51c1.57-2.22,1.17-3.46,3.43-6.68,1.29-1.84,1.92-2.27,2.57-3.77a8.67,8.67,0,0,0,.34-5.32Z"
                />
                <path
                    className="cls-1"
                    d="M493.82,417.47a21.32,21.32,0,0,1,2.64-9.46c1-1.73,2-3.11,3.6-6a26.39,26.39,0,0,0,2.05-4.8l11.66,4.11c.71,3.64,0,5.76-.85,7-1.23,1.89-2.44,1.58-4.12,4.11-.71,1.07-.86,1.57-2.4,5.15-.85,2-1.56,3.54-2,4.54Z"
                />
                <path
                    className="cls-1"
                    d="M511.11,400.38c9.88-18.32,19.74-37.72,29.4-58.2,13.82-29.26,25.72-57.5,36-84.34l-9.77,11.82L568,251.41l-5.92-2.57L558,267.35q-.39-7.46-.77-14.91l-7,3.51-5.31-3.51-6.09,5-3.94-6.77a452.77,452.77,0,0,1-7.2,69.68,451.74,451.74,0,0,1-21.76,78.37Z"
                />
                <path
                    className="cls-1"
                    d="M481.89,413.6a75.66,75.66,0,0,1,1.76-7.52c.68-2.37,1.93-6.29,1.86-9.52a10.07,10.07,0,0,0-.64-3.4,10.46,10.46,0,0,0-1-1.87l-10.48-1.09a35.11,35.11,0,0,0-3.66,8.81,35.49,35.49,0,0,0-1.06,11.81Z"
                />
                <path
                    className="cls-1"
                    d="M443.74,408.87a48.15,48.15,0,0,1-1.13-10.25,47.66,47.66,0,0,1,1-9.64l12.6.32c.7,2.28,1.23,4.22,1.61,5.66,1,3.78,1.41,5.75,1.29,8.1a17,17,0,0,1-1.51,6.05Z"
                />
                <path
                    className="cls-1"
                    d="M435.17,409.44a86.31,86.31,0,0,0-2.14-9,57.88,57.88,0,0,0-2.57-7.46c-.24-.53-.89-2-1.67-4-.39-1-.69-1.85-.88-2.4l-8.63,1.27-2,.29a17.87,17.87,0,0,0-.58,9.58c.54,2.29,1.42,3.64,2.32,7.27a39.57,39.57,0,0,1,1,5.76Z"
                />
                <path
                    className="cls-1"
                    d="M390.61,418.06l13.07-4.28A27.64,27.64,0,0,0,397,395.86l-11.32,5a55.87,55.87,0,0,0,5,17.19Z"
                />
                <path
                    className="cls-1"
                    d="M482,391.09c1.49-8.11,4-20.36,8.14-35a419.76,419.76,0,0,1,14.74-42.81A558.29,558.29,0,0,0,528,237.14l-3-.62-4.14,5.12c-.54-2.1-1.09-4.2-1.63-6.3q-2.84,10.22-5.66,20.44-.57-11.13-1.15-22.24l-3.63,2.15-2.33,8.09q-1.14-5-2.27-10-2.5,16-5,32L497,254.88l-2.45,4.5q-.63-2.44-1.28-4.89-1.08,3.83-2.14,7.63l-4.8-5.7c.58,6,1.24,14.47,1.54,24.56a373.05,373.05,0,0,1-2.7,57.34,369.29,369.29,0,0,1-10.37,52Z"
                />
                <path
                    className="cls-1"
                    d="M446.65,389.06q-6.34-50.07-12.08-101.4-3.44-30.92-6.55-61.45l4.24,3.85q.57-3.41,1.16-6.81,2.56,8.55,5.14,17.1.39-5.72.77-11.44l2.83,3.85q1.09-4,2.18-8.1l4.38,22.89L453.34,227q1.17,3.35,2.32,6.68l3.21-4.75,3.86,1.8,3.73-3.6a373.26,373.26,0,0,0-8.23,40.63c-1.58,10.84-3,24.1-4.63,69-.46,13.11-1,31-1.41,52.42Z"
                />
                <path
                    className="cls-1"
                    d="M419.28,387.85c-5.13-17.7-9.55-31.92-12.48-41.17-8.17-25.82-12.64-37.82-21.73-66.22-5-15.67-8.88-28.35-11.18-36l4.5,2.58-.13-7.72,5.27,3.47,4.76,9.13-.9-15.94,4.24,3.6c.94-1.37,1.89-2.74,2.83-4.12l5,13.25c1.84,16.37,4.12,33.32,6.95,50.78,5,31.24,11.21,60.49,18,87.61Z"
                />
                <path
                    className="cls-1"
                    d="M393.9,397.21q-15.7-34-31.2-70.2-14.56-34.17-27.51-67.12c-.22,1.93-.43,3.86-.65,5.79-.17,1.63-.35,3.27-.53,4.9q-3.33-7-6.67-14-1.08,3-2.18,5.91l-6.82-2.95c1,2.83,2,5.65,3,8.48l-6.49-3.23c2.14,4,5.35,10,9.32,17.25,13.44,24.54,23.74,41.91,28,49.24,8.8,15,21.28,37.33,36.64,68Z"
                />
                <path
                    className="cls-1"
                    d="M367.27,431.44a23.13,23.13,0,0,1-7.2-6.4,27.23,27.23,0,0,1-3.09-5.78c-.42-1.05-.7-2-.9-2.64l9.71-6.68a10,10,0,0,1,5.46,3.66c.88,1.25.6,1.62,2.06,4,1,1.62,1.59,2.19,2.7,4.05.68,1.15,1.17,2.13,1.46,2.76Z"
                />
                <path
                    className="cls-1"
                    d="M350.33,446.74a38,38,0,0,0-4.79-4.6c-2.6-2.09-4.66-3.17-5.79-5.78a8.69,8.69,0,0,1-.64-2.38l8.23-8.94a27.52,27.52,0,0,1,4.63,3.67c1.63,1.62,2.36,2.47,4.24,4.3a55.66,55.66,0,0,0,4.4,3.87Z"
                />
                <path
                    className="cls-1"
                    d="M340.3,458.64a18.27,18.27,0,0,1-5.24-3.25c-1.66-1.48-2.39-2.73-4.82-4.89-1.07-.95-2-1.66-2.59-2.1l8.05-8.7a10.76,10.76,0,0,1,4.95,3.73c1,1.43,1.08,2.44,2.25,4a11.15,11.15,0,0,0,3.91,3.21Z"
                />
                <path
                    className="cls-1"
                    d="M330.17,474a18.13,18.13,0,0,1-6.55-2.25,20.92,20.92,0,0,1-5.66-4.95c-.5-.59-.89-1.11-1.16-1.47l7.27-9.39a11.17,11.17,0,0,1,5.4,2.7c1,.92,1.24,1.57,2.31,2.57a11.91,11.91,0,0,0,4.63,2.64Z"
                />
                <path
                    className="cls-1"
                    d="M322.33,491.58a47.33,47.33,0,0,1-7.2-1.22c-4.41-1.12-5.84-2.19-6.41-2.65a11.31,11.31,0,0,1-2.68-3.23l5.1-10.07a54.4,54.4,0,0,1,7.59,2.77,55,55,0,0,1,7.85,4.34Q324.45,486.56,322.33,491.58Z"
                />
                <path
                    className="cls-1"
                    d="M318.28,508.94a35.63,35.63,0,0,1-16-3l1.35-11.25q3.44.3,7.14.83c3.52.51,6.83,1.14,9.91,1.82Q319.49,503.17,318.28,508.94Z"
                />
                <path
                    className="cls-1"
                    d="M359,414.64l-18.32-24.12C305.1,343.79,298.21,335,288.17,324a401,401,0,0,0-34.63-33.6l8.75,1.54-5.15-7.71L267.6,289q-1.11-4.64-2.23-9.26l8.23,6.51V274.64l7.2,9.6c.74-2.46,1.49-4.92,2.23-7.38,10.56,20.71,22.39,42.34,35.66,64.63,15,25.26,30.21,48.53,45.1,69.82Z"
                />
                <path
                    className="cls-1"
                    d="M342.54,430.25q-27-26.89-56.34-54.39-26.77-25-53-48.08l8.23,1q-2.58-4.24-5.14-8.49l9.68,3.86-5.31-12.09L252,317.24q-.76-5.67-1.54-11.32c8.6,14.11,18.24,28.76,29.06,43.72a666.2,666.2,0,0,0,65.75,77.65Z"
                />
                <path
                    className="cls-1"
                    d="M330.49,445.33c-21.12-17.91-44.56-36.32-70.43-54.64-17.27-12.23-34.19-23.4-50.57-33.6l6.51-.85-5.83-6.69,9.43,2.06q-.94-4.61-1.88-9.2l8.91,3.37c-.74-2.69-1.49-5.37-2.23-8.06q7.93,7.89,15.94,15.77,46.52,45.75,93.1,88.65C332.46,443.21,331.47,444.27,330.49,445.33Z"
                />
                <path
                    className="cls-1"
                    d="M318.54,462.72a567,567,0,0,0-62.6-35.17,568.66,568.66,0,0,0-63.25-25.89l10.28-.85-9.25-7.2,14.57,1.2-8.57-10.29,11.48,1.37q-1.71-4.45-3.43-8.91,26.32,18,53.15,37,31.31,22.26,61,44.4Z"
                />
                <path
                    className="cls-1"
                    d="M307.89,480.86C285.06,475.49,267,470,254.57,466c-14.87-4.87-30.2-10.52-56.23-18.17-6.36-1.87-11.61-3.35-15.08-4.31l7.71-2.55-5.48-4.28,7-2.4q-1.72-4.38-3.43-8.75l14.51,2.73.57-5.81q19.13,10.39,38.92,20.74,34,17.75,67,33.77Z"
                />
                <path
                    className="cls-1"
                    d="M302.06,503.32A413.57,413.57,0,0,0,179,514.81l5-4.8-5.65-1.37,5.31-3.26L179.66,502l4.28-4.63-6.17-3.43,6-4.11-3.6-8.92,7.89-1.2-6.17-7.2,6-.51-3.95-7.2c20.18,7.4,42.44,14.57,66.69,20.91,18.28,4.79,35.77,8.61,52.29,11.66Q302.49,500.32,302.06,503.32Z"
                />
                <path className="cls-1" d="M282.09,421.76l-20.84-7.52" />
                <path className="cls-1" d="M294.54,389.39l-46.15-28.1" />
            </g>
            <g id="Calque_12" data-name="Calque 12">
                <path
                    className="cls-1"
                    d="M432.26,230.06,397.37,37.55l5.15,2.4-1.38-10.63,11.66,1-1.37-19.89,17.83,1,11-4.45,4.11,217.62"
                />
                <path
                    className="cls-1"
                    d="M440.35,13.21,445,7.45l3.17,3,4.11-7.88,5.14-.69,7.2-.34L466.63,7l4.46,6.17,11.65,1,6.52,5.82-.69,6.86h5.49l7.54,3.77q-19.44,100-38.87,200"
                />
                <path className="cls-1" d="M454.65,230.75,471.09,13.21" />
                <path
                    className="cls-1"
                    d="M510.34,234.18q5.69-29.31,12.35-59.49C533.72,124.86,546,77.35,559,32.24L567.77,36c-.34,2.68-.68,5.37-1,8.05l4.8-7,6.52,1.54q-1.12,7.55-2.23,15.08L582,38.06,585.6,41l-.51,6,4.8.68,2.91-7.54L600.51,43l-2.91,7.2,9.09-2.75c.28.92.57,1.83.85,2.75L525,236.52"
                />
                <path
                    className="cls-1"
                    d="M575.83,53.66q-28.29,90.84-56.57,181.68"
                />
                <path
                    className="cls-1"
                    d="M557.33,254.39q24.1-45.81,48-96.15,21.44-45.36,39.78-88.63l7.54,5,7.71-13.2,14.75,7.37-.18,4.8,11.49,9.77L566.91,264.69"
                />
                <path
                    className="cls-1"
                    d="M568,251.41q53.44-88.93,106.88-177.86"
                />
                <path
                    className="cls-1"
                    d="M600.14,277.68l129.8-173.1,11.15,3.77q3.17,6.69,6.34,13.37l12.17,3.77L684,205.21l83.83-73.89q5.56,6.26,11.14,12.52-40.53,37.8-82.63,75.94Q650.79,261,605.78,300.55"
                />
                <path className="cls-1" d="M608,282.89l76-77.68" />
                <path className="cls-1" d="M555.34,336.1l16.24-19.35" />
                <path
                    className="cls-1"
                    d="M648.51,307.38,786.17,178.12l1.89,6.69,5-3.09.51,5.66,5.32-4.46,3.25,2.57c-.22,1.6-.45,3.2-.68,4.8l7.37-4.63,3.09,6,1.71,7.72-38.74,30.51,32.57-20.74,6.34,1q-18.54,14-37.37,27.94-67.51,50.08-134.3,96.78"
                />
                <path className="cls-1" d="M659.66,307.38,801.43,190.29" />
                <path className="cls-1" d="M590,392.64l67.52-35.49" />
                <path
                    className="cls-1"
                    d="M669.16,351.35,835.37,241.21l2.92,7L787.2,284.75l51.6-30.86,3.94,7.55,3.09,18,3.08.34c.52,1.54,1,3.08,1.55,4.63l-18,7.88,15.43-3.08c.22,1.08.45,2.17.68,3.25h5.66l2.4,12.18a984.29,984.29,0,0,1-99.09,43.54c-20.88,7.83-41.1,14.6-60.49,20.46"
                />
                <path className="cls-1" d="M594.59,405.17l25.52-9.6" />
                <path className="cls-1" d="M679,362.69l153.48-70.4" />
                <path
                    className="cls-1"
                    d="M690.17,413l188.4-44.4-2.91-28.8-29,6.68,19.54-9.08-2.4-9.43-167.19,59.9"
                />
                <path className="cls-1" d="M603.48,434.39l40.27-11" />
                <path
                    className="cls-1"
                    d="M639.7,460.1q-14.69,1.39-30.88,3.31"
                />
                <path
                    className="cls-1"
                    d="M607.37,453.52c21.15-4.61,88.49-19.12,156-30.77,18.45-3.18,43.6-7.31,77-8.06,15.81-.35,28.95.15,38.06.69l-3.77,4.8,6.51,2.23.17,5.48-1,5.49-69.26.17,63.94,4.8,7.72,3.6-8.75,7,5.32,3.77-6.86,2.74c-15.93-.39-38.93-.86-66.69-1-13.23-.08-50.45-.16-99.25,1-10.23.25-18.71.51-24.52.69"
                />
                <path
                    className="cls-1"
                    d="M610.81,507.44c35.29,3.38,74,2.08,115.36,2.05a1197.94,1197.94,0,0,0,147.94-9.26l-.17-5.82-6.85.17c1.08-1.77,2.17-3.54,3.25-5.32q-.35-7.11-.68-14.22c-28.12,4.5-58.35,8.32-90.52,11-25.69,2.11-50.25,3.3-73.54,3.77"
                />
                <path
                    className="cls-1"
                    d="M383.53,242.79,327.77,59.84l8.23-.35,7.2-7.2,9.26-2.74q.35,3.6.68,7.2l3.09-11,6.51-1.37q-.17,6.68-.34,13.37,14.61,90.9,29.23,181.8"
                />
                <path className="cls-1" d="M343.2,52.29,387.39,236" />
                <path
                    className="cls-1"
                    d="M320.31,265.16,211.2,102.35l7.89-2.06,9.25-6.85c.12-2.63.23-5.26.35-7.89l8.23-.34L241.37,79l15.77,1q35.22,80.52,73.72,162.86,16.14,34.49,32.42,68.3"
                />
                <path className="cls-1" d="M236.92,85.21l92.18,175" />
                <path
                    className="cls-1"
                    d="M273.6,281.66Q218.05,208.3,162.52,134.92L156,140.41q-.69,5.66-1.37,11.31l-6.17-2.4-7.2,4.12-7.54,2.05-4.8-3.43-15.09,4.8L253.54,290.41"
                />
                <path className="cls-1" d="M154.63,151.72l112.11,132" />
                <path className="cls-1" d="M324.16,342.92l-13.58-15.28" />
                <path className="cls-1" d="M578.33,367.07l8.37-7.54" />
                <path
                    className="cls-1"
                    d="M273.26,506.06c-32.3,2.83-59.16,6-78.68,8.57-49.34,6.43-88.79,13.6-126.86,20.57-21.74,4-39.73,7.48-52.11,9.94l8.91-9.94-6.85-7.54L29,518.4l-11.66-8.23,9.6-8.57L16,488.91l6.86,1A1023.06,1023.06,0,0,0,126,497.49c20,.44,39.32.28,57.77-.35"
                />
                <path
                    className="cls-1"
                    d="M272.57,475.93c-54.51-13.22-113.17-25.11-175.7-34.67q-45.78-7-89.15-11.66L2.24,416.23H17l-8.91-12,11-2.74L4.64,389.83,3.27,379.2l189.08,54.86"
                />
                <path className="cls-1" d="M8.07,404.23,187,442" />
                <path
                    className="cls-1"
                    d="M196.6,395.8,34.47,334.29l3.08-7.89-8.91-5.83c.23-2.17.45-4.34.68-6.51l7.55-.35,2.74-5.14c36.74,15.52,83.27,35.2,120,50.74,39.1,16.56,85.8,36.36,124.89,52.94"
                />
                <path
                    className="cls-1"
                    d="M218.2,345.4,65.67,278.06q.33-12.53.68-25L88,261.6,70.81,244.46q1.71-5,3.43-10,.67-6.16,1.37-12.34,23.36,14.06,47,28.8,61.79,38.58,118.71,77.66"
                />
                <path
                    className="cls-1"
                    d="M88,261.6a918,918,0,0,1,123.43,63.77A917.77,917.77,0,0,1,292,381.25"
                />
                <path className="cls-1" d="M29,518.4l154.46-13.2" />
            </g>
            <g id="Calque_13" data-name="Calque 13">
                <path
                    className="cls-1"
                    d="M255.69,486.9c6.85-.16,12.53-.38,16.45-.55"
                />
                <path
                    className="cls-1"
                    d="M186.94,469.43,61.72,455.84l21.85,17-15.94-2.57,12.29,7.28,1.6.94c7.42.87,18,2,30.6,3.09,11.42,1,21.28,1.63,69.85,3.69"
                />
                <path className="cls-1" d="M272.65,478.74l-71.66-8" />
                <path
                    className="cls-1"
                    d="M203.47,426.57,80.68,387.89l-3.36-20.06q23.63,9.29,47.67,18.43,77.12,29.28,152,54.56"
                />
                <path className="cls-1" d="M275.52,448.78l-50.61-15.22" />
                <path
                    className="cls-1"
                    d="M248.05,315.44,129.6,229.29q-1.66-10-3.34-20l8.23,4.88-5.92-8.74-.25-7.72a1284.33,1284.33,0,0,1,110.57,89.75c26.77,24.31,51,48.37,72.83,71.63-1.27,1.89-2.54,3.77-3.8,5.66l-41.78-34.26"
                />
                <path
                    className="cls-1"
                    d="M332.44,333.9c-30.61-36.81-61.49-77.26-91.75-121.58q-15.33-22.44-29.32-44.48l4.89-18q54.18,76.18,107.48,153,8.29,11.94,16.56,23.9"
                />
                <path
                    className="cls-1"
                    d="M376.22,304.67,305.74,112.55l10.55-23.4q33.49,106.26,67,212.51"
                />
                <path
                    className="cls-1"
                    d="M423.31,291.47,395.74,104.84l-12.85,14.91,31,173.14"
                />
                <path
                    className="cls-1"
                    d="M470.06,292Q490,175.16,509.92,58.29l13.37,14.4Q522,80,520.72,87.35l6.94-13.63,6.94,8.49L475.82,294.32"
                />
                <path
                    className="cls-1"
                    d="M511.41,305.67q43-100.8,85.93-201.61,4.13,14.28,8.23,28.55L547,253.85"
                />
                <path className="cls-1" d="M517,308.31l16.68-34" />
                <path
                    className="cls-1"
                    d="M565.25,286.17l79.32-120q2.05,12.34,4.12,24.69l-100.62,135"
                />
                <path className="cls-1" d="M603.12,329.64,573,358.11" />
                <path
                    className="cls-1"
                    d="M622.86,297.57,693.64,233q1.88,6.75,3.73,13.5l-61.29,55"
                />
                <path className="cls-1" d="M586.57,331.09l29.95-27.43" />
                <path
                    className="cls-1"
                    d="M596.86,379.49c19.55-9.66,39.94-20.58,60.91-32.94a940.54,940.54,0,0,0,92.57-62.23q-1.73-7.9-3.47-15.81L620.7,356"
                />
                <path
                    className="cls-1"
                    d="M670.07,426.22l128.1-30.56q-5.52,8.37-11.06,16.72L628.18,442.05"
                />
                <path className="cls-1" d="M678.57,472.7,610.2,477" />
                <path
                    className="cls-1"
                    d="M696.87,481l85.36-3.58,20.06-12.6L700.8,471.25"
                />
                <path className="cls-1" d="M610.6,483.1l35.51-1" />
            </g>
        </g>
    </svg>
);
