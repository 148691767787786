import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { color3, color1, mediumWidth, bigWidth } from "./ui/Constants";

const NavbarStyled = styled.div`
    user-select: none;
    border-bottom: 3px double ${color3};
    margin-bottom: 3vh;
    padding-bottom: 1vh;
    z-index: 1000;

    .NavbarItems {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .burgerMenu {
        display: none;
        width: 30px;
        height: 23px;
        position: relative;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: ${color3};
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
        }

        span:nth-child(1) {
            top: 0px;
        }

        span:nth-child(2),
        span:nth-child(3) {
            top: 9px;
        }

        span:nth-child(4) {
            top: 18px;
        }
    }

    .burgerMenu.open {
        span:nth-child(1) {
            top: 9px;
            width: 0%;
            left: 50%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
        }

        span:nth-child(4) {
            top: 9px;
            width: 0%;
            left: 50%;
        }
    }

    @media only screen and (max-width: ${mediumWidth}) {
        @keyframes expand {
            0% {
                height: 0;
            }

            100% {
                height: 33vh;
            }
        }

        .animate {
            animation: expand 0.5s linear;
        }

        background: ${color1};
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        border-bottom: 2px solid #e6d3a3;

        .burgerMenu {
            display: block;
            margin: 3vh 2vh 2vh auto;
        }

        .NavbarItems {
            display: none;
        }

        .NavbarItems.open {
            display: flex;
            flex-direction: column;
            width: 100vw;
            height: 50vh;
        }

        a {
            font-size: 5vh;
        }
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
    }

    @media only screen and (min-width: ${bigWidth}) {
    }
`;

const Navbar = () => {
    const [burgerOpen, setBurgerOpen] = useState(false);
    let open = burgerOpen ? "open" : "";

    return (
        <NavbarStyled>
            <div
                className="Navbar"
                onClick={() => {
                    setBurgerOpen(!burgerOpen);
                }}
            >
                <div className={"burgerMenu " + open}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={"NavbarItems " + open}>
                    <Item>
                        <Link to={"/"}>Home</Link>
                    </Item>
                    <Item>
                        <Link to={"/sculptures"}>Sculptures</Link>
                    </Item>

                    <Item>
                        <Link to={"/peintures"}>Peintures</Link>
                    </Item>
                    <Item>
                        <Link to={"/nature-brothers"}>Nature Brothers</Link>
                    </Item>
                    <Item>
                        <Link to={"/contact"}>Contact</Link>
                    </Item>
                    <Item>
                        <Link to={"/expos"}>Expos</Link>
                    </Item>
                </div>
            </div>
        </NavbarStyled>
    );
};

const Item = (props: { children: React.ReactNode }) => (
    <div className="top-menu-item">{props.children}</div>
);

export default Navbar;
