import React from "react";

import in2 from "./images/Sculptures/In2.jpg";
import in3 from "./images/Sculptures/In3.jpg";
import in4 from "./images/Sculptures/In4.jpg";

import out1 from "./images/Sculptures/Out1.jpg";
import out2 from "./images/Sculptures/Out2.jpg";
import out3 from "./images/Sculptures/Out3.jpg";
import out4 from "./images/Sculptures/Out4.jpg";
import out5 from "./images/Sculptures/Out5.jpg";
import out6 from "./images/Sculptures/Out6.jpg";
import out7 from "./images/Sculptures/Out7.jpg";
import out8 from "./images/Sculptures/Out8.jpg";

import gallerie1XS from "./images/Sculptures/Gallerie/XS/Gallerie-1.png";
import gallerie1S from "./images/Sculptures/Gallerie/S/Gallerie-1.png";
import gallerie2XS from "./images/Sculptures/Gallerie/XS/Gallerie-2.png";
import gallerie2S from "./images/Sculptures/Gallerie/S/Gallerie-2.png";
import gallerie3XS from "./images/Sculptures/Gallerie/XS/Gallerie-3.png";
import gallerie3S from "./images/Sculptures/Gallerie/S/Gallerie-3.png";
import gallerie4XS from "./images/Sculptures/Gallerie/XS/Gallerie-4.png";
import gallerie4S from "./images/Sculptures/Gallerie/S/Gallerie-4.png";
import gallerie5XS from "./images/Sculptures/Gallerie/XS/Gallerie-5.png";
import gallerie5S from "./images/Sculptures/Gallerie/S/Gallerie-5.png";
import gallerie6XS from "./images/Sculptures/Gallerie/XS/Gallerie-6.png";
import gallerie6S from "./images/Sculptures/Gallerie/S/Gallerie-6.png";
import gallerie7XS from "./images/Sculptures/Gallerie/XS/Gallerie-7.png";
import gallerie7S from "./images/Sculptures/Gallerie/S/Gallerie-7.png";
import gallerie8XS from "./images/Sculptures/Gallerie/XS/Gallerie-8.png";
import gallerie8S from "./images/Sculptures/Gallerie/S/Gallerie-8.png";
import gallerie9XS from "./images/Sculptures/Gallerie/XS/Gallerie-9.png";
import gallerie9S from "./images/Sculptures/Gallerie/S/Gallerie-9.png";
import gallerie10XS from "./images/Sculptures/Gallerie/XS/Gallerie-10.png";
import gallerie10S from "./images/Sculptures/Gallerie/S/Gallerie-10.png";
import gallerie11XS from "./images/Sculptures/Gallerie/XS/Gallerie-11.png";
import gallerie11S from "./images/Sculptures/Gallerie/S/Gallerie-11.png";
import gallerie12XS from "./images/Sculptures/Gallerie/XS/Gallerie-12.png";
import gallerie12S from "./images/Sculptures/Gallerie/S/Gallerie-12.png";
import gallerie13XS from "./images/Sculptures/Gallerie/XS/Gallerie-13.png";
import gallerie13S from "./images/Sculptures/Gallerie/S/Gallerie-13.png";
import styled from "styled-components";
import { TotemFull } from "./ui/SVGS";
import Oeuvres from "./ui/Oeuvres/Oeuvres";

const SculpturesStyled = styled.div`
    .separator {
        svg {
            width: auto;
            height: 100%;

            .cls-1 {
                fill: none;
                stroke: @Color2;
                stroke-miterlimit: 10;
                stroke-width: 10px;
            }

            .cls-2 {
                fill: @Color2;
                stroke: @Color2;
                stroke-miterlimit: 10;
                stroke-width: 10px;
            }

            .cls-3 {
                fill: none;
                stroke: black;
                stroke-miterlimit: 10;
                stroke-width: 10px;
            }
        }
    }
`;

const outSculptures = [
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [in2, "", ""],
        imgsS: [in2, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [in3, "", ""],
        imgsS: [in3, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [in4, "", ""],
        imgsS: [in4, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [out1, "", ""],
        imgsS: [out1, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [out2, "", ""],
        imgsS: [out2, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [out3, "", ""],
        imgsS: [out3, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [out4, "", ""],
        imgsS: [out4, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [out5, "", ""],
        imgsS: [out5, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [out6, "", ""],
        imgsS: [out6, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [out7, "", ""],
        imgsS: [out7, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [out8, "", ""],
        imgsS: [out8, "", ""],
    },
];

const gallerie = [
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie1XS, "", ""],
        imgsS: [gallerie1S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie2XS, "", ""],
        imgsS: [gallerie2S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie3XS, "", ""],
        imgsS: [gallerie3S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie4XS, "", ""],
        imgsS: [gallerie4S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie5XS, "", ""],
        imgsS: [gallerie5S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie6XS, "", ""],
        imgsS: [gallerie6S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie7XS, "", ""],
        imgsS: [gallerie7S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie8XS, "", ""],
        imgsS: [gallerie8S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie9XS, "", ""],
        imgsS: [gallerie9S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie10XS, "", ""],
        imgsS: [gallerie10S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie11XS, "", ""],
        imgsS: [gallerie11S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie12XS, "", ""],
        imgsS: [gallerie12S, "", ""],
    },
    {
        title: "Sculpture",
        description: "Description",
        weight: "123kg",
        height: "90cm",
        width: "40cm",
        imgsXS: [gallerie13XS, "", ""],
        imgsS: [gallerie13S, "", ""],
    },
];

const Sculptures = () => (
    <SculpturesStyled>
        <Oeuvres
            title="Sculptures"
            separator={<TotemFull />}
            inOeuvres={gallerie}
            outOeuvres={outSculptures}
        />
    </SculpturesStyled>
);

export default Sculptures;
