import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { createGlobalStyle } from "styled-components";
import { renderRoutes } from "./Routes";
import {
    bigWidth,
    color1,
    color3,
    color4,
    color5,
    mediumWidth,
} from "./ui/Constants";

import "./index.css";

const GlobalStyle = createGlobalStyle`

body,
html {
    height: 100%;
    margin: 0;
    padding: 0;
    background: ${color1};
}

.footer {
    height: 80px;
    bottom: 0;
    background-color: ${color4};
    width: 100%;
    z-index: 1234112341234;
}

#header {
    margin-top: 1em;
    text-align: center;
    z-index: 10000;

    .div {
        display: inline-block;
    }
}

.content {
    width: 100%;
    flex-grow: 1;

    .row {
        display: flex;
        flex-direction: row;
    }

    .column {
        flex: 50%;
        text-align: center;
    }

    .centerText {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
    }
}

#footer {
    background-color: ${color5};
    min-height: 100px;
    text-align: center;
}


.Title {
    color: ${color3};
    font-family: 'Amatic SC', cursive;
    font-size: 3vw;
    letter-spacing: 0.9px;
    font-size: 4vh;
    margin-bottom: 1em;
}



@media only screen and (max-width: ${mediumWidth}) {

    #content {
        margin-top: 9vh;
    }

}

@media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {}

@media only screen and (min-width: ${bigWidth}) {}


a {
  box-sizing: border-box;
  color: ${color4};
  cursor: pointer;
  display: block;
  font-family: 'Amatic SC', cursive;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.9px;
  text-decoration-color: rgb(0, 0, 0);
  text-decoration-line: none;
  text-decoration-style: solid;
}

`;

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyle />
        {renderRoutes()}
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
