import React from "react";
import { Route, Router } from "react-router";
import { createBrowserHistory } from "history";

import Sculptures from "./Sculptures";
import Peintures from "./Peintures";

import Expos from "./Expos";

import styled from "styled-components";
import Navbar from "./Navbar";
import Contact from "./Contact";
import NatureBrothers from "./NatureBrothers";
import HomePage from "./HomePage";

const RoutesStyled = styled.div`
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;

    #content {
        -webkit-flex-grow: 1;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
`;

const browserHistory = createBrowserHistory();
export const renderRoutes = () => (
    <Router history={browserHistory}>
        <RoutesStyled>
            <div id="header">
                <Navbar />
            </div>
            <div id="content">
                <Route exact path="/" component={HomePage} />
                <Route path="/sculptures" component={Sculptures} />
                <Route path="/peintures" component={Peintures} />
                <Route path="/nature-brothers" component={NatureBrothers} />
                <Route path="/contact" component={Contact} />
                <Route path="/expos" component={Expos} />
            </div>
        </RoutesStyled>
    </Router>
);
