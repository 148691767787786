import React, { useState } from "react";
import Oeuvre, { OeuvreType } from "./Oeuvre";

import { CloseCross } from "../SVGS";
import styled from "styled-components";
import { mediumWidth, bigWidth, color2, color3, color4 } from "../Constants";

const OeuvresStyled = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;

    .InOutSelection {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4vh;

        h1 {
            flex-grow: 1;
            color: ${color3};
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            font-family: "Amatic SC", cursive;
            letter-spacing: 0.3vw;
            font-size: 3vh;
        }

        .selected {
            border-bottom: 3px dashed ${color4};
        }

        .separator {
            padding: 0 3vw;
            flex-grow: 1;
            height: 8vh;
        }
    }

    .OeuvresList {
        display: grid;
    }

    .FullScreen {
        background: rgba(0, 0, 0, 0.8);
        z-index: 10000000;
        height: 100vh;
        width: 100%;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;

        .waiting-page-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80vw;
            height: 80vh;

            .imgContainer {
                flex-grow: 1;

                img {
                    width: auto;
                    height: auto;
                    max-height: 80vh;
                    max-width: 80vw;
                    border: 3px solid ${color2};
                    border-top-left-radius: 255px 15px;
                    border-top-right-radius: 15px 225px;
                    border-bottom-right-radius: 225px 15px;
                    border-bottom-left-radius: 15px 255px;
                }
            }

            .close {
                font-size: 3em;
                color: ${color3};
                margin-left: auto;
                margin-right: 0;

                svg {
                    width: 10vmin;
                    height: auto;

                    polygon {
                        fill: ${color3};
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${mediumWidth}) {
        .OeuvresList {
            grid-template-columns: 100%;
        }

        .InOutSelection {
            width: 100%;
        }
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
        .OeuvresList {
            grid-template-columns: 33% 33% 33%;
        }

        .InOutSelection {
            width: 50vw;
        }
    }

    @media only screen and (min-width: ${bigWidth}) {
        .OeuvresList {
            grid-template-columns: 20% 20% 20% 20%;
            grid-gap: 5%;
        }

        .InOutSelection {
            width: 30vw;
        }
    }
`;

interface OeuvresProps {
    title: string;
    separator: any;
    inOeuvres: any;
    outOeuvres: any;
}

const Oeuvres = (props: OeuvresProps) => {
    const [inSelected, setInSelected] = useState(true);
    const [isOeuvreSelected, setIsOeuvreSelected] = useState(false);
    const [oeuvreSelected, setOeuvreSelected] = useState({} as OeuvreType);

    const togglePopin = (oeuvre: OeuvreType) => {
        setIsOeuvreSelected(true);
        setOeuvreSelected(oeuvre);
    };

    const renderOeuvres = (oeuvres: Array<OeuvreType>) => {
        return oeuvres.map((oeuvre: OeuvreType, key) => {
            return (
                <Oeuvre
                    onClick={() => {
                        togglePopin(oeuvre);
                    }}
                    key={key}
                    oeuvre={oeuvre}
                />
            );
        });
    };

    const displayOeuvreFullScreen = () => {
        if (isOeuvreSelected && oeuvreSelected) {
            return (
                <div className="FullScreen">
                    <div className="waiting-page-body">
                        <div
                            onClick={() => {
                                setIsOeuvreSelected(false);
                            }}
                            className="close"
                        >
                            <CloseCross />
                        </div>
                        <div className="imgContainer">
                            <img alt="img" src={oeuvreSelected.imgsS[0]}></img>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const gridSmallTemplateNeeded = inSelected
        ? props.inOeuvres.length < 4
        : props.outOeuvres.length < 4;

    const cssGrid = gridSmallTemplateNeeded
        ? {
              gridTemplateColumns: "100%",
          }
        : {};

    return (
        <OeuvresStyled>
            {displayOeuvreFullScreen()}
            <div className="Title">{props.title}</div>
            <div className="InOutSelection">
                <h1
                    className={inSelected ? "selected" : ""}
                    onClick={() => {
                        setInSelected(true);
                    }}
                >
                    Galerie
                </h1>
                <div className="separator">{props.separator}</div>
                <h1
                    className={inSelected ? "" : "selected"}
                    onClick={() => {
                        setInSelected(false);
                    }}
                >
                    Exterieur
                </h1>
            </div>
            <div className="OeuvresList" style={cssGrid}>
                {inSelected
                    ? renderOeuvres(props.inOeuvres)
                    : renderOeuvres(props.outOeuvres)}
            </div>
        </OeuvresStyled>
    );
};

export default Oeuvres;
