import React from "react";

import in1 from "./images/Peintures/In1.png";
import out1 from "./images/Peintures/Out1.jpg";
import styled from "styled-components";
import Oeuvres from "./ui/Oeuvres/Oeuvres";
import { Art } from "./ui/SVGS";
import { color2 } from "./ui/Constants";

const inPeintures = [
    {
        title: "Peinture",
        description: "Description",
        height: "90cm",
        width: "40cm",
        imgsXS: [in1, "", ""],
        imgsS: [in1, "", ""],
    },
];
const outPeintures = [
    {
        title: "Peinture",
        description: "Description",
        height: "90cm",
        width: "40cm",
        imgsXS: [out1, "", ""],
        imgsS: [out1, "", ""],
    },
];

const PeinturesStyled = styled.div`
    .separator {
        svg {
            width: auto;
            height: 100%;

            .art {
                fill: none;
                stroke: ${color2};
                stroke-miterlimit: 10;
                stroke-width: 3px;
            }
        }
    }
`;

const Peintures = () => (
    <PeinturesStyled>
        <Oeuvres
            title="Peintures"
            separator={<Art />}
            inOeuvres={inPeintures}
            outOeuvres={outPeintures}
        />
    </PeinturesStyled>
);

export default Peintures;
