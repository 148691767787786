import React from "react";

import NatureBrothersPics from "./images/NatureBrothers.jpg";
import styled from "styled-components";
import { color2, color3 } from "./ui/Constants";
import { Mask1, Mask2 } from "./ui/SVGS";

const NatureBrothersStyled = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    .imgBlock {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;

        img {
            margin-left: auto;
            margin-right: auto;
            border: 3px solid ${color2};
            border-top-left-radius: 255px 15px;
            border-top-right-radius: 15px 225px;
            border-bottom-right-radius: 225px 15px;
            border-bottom-left-radius: 15px 255px;
            width: 100%;
        }
    }

    .test {
        display: flex;
        justify-content: space-around;
        height: 15vh;
        margin-bottom: 3vh;
        width: 45%;

        .Title {
            color: #cea07e;
            font-family: "Amatic SC", cursive;
            font-size: 3vw;
            letter-spacing: 0.9px;
            font-size: 4vh;
            margin-bottom: 0;
            align-items: center;
            display: flex;
        }

        .svg-box {
            height: 100%;
            svg {
                width: auto;
                height: 100%;
            }
            .cls-1 {
                fill: none;
                stroke: ${color3};
                stroke-miterlimit: 10;
                stroke-width: 3px;
            }
        }
    }
`;

const NatureBrothers = () => (
    <NatureBrothersStyled className="NatureBrothers">
        <div className="test">
            <div className="svg-box">
                <Mask1 />
            </div>
            <div className="Title">Nature Brothers</div>
            <div className="svg-box">
                <Mask2 />
            </div>
        </div>

        <div className="imgBlock">
            <img alt="img" src={NatureBrothersPics}></img>
        </div>
    </NatureBrothersStyled>
);

export default NatureBrothers;
