import React from "react";
import styled from "styled-components";
import { color2, color5, mediumWidth, bigWidth } from "./ui/Constants";

import portrait from "./images/Portrait.jpg";
import couverture from "./images/Couverture.jpg";

const HomePageStyled = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 70vw;
    text-align: center;

    .photo {
        margin-left: auto;
        margin-right: auto;
        border: 3px solid ${color2};
        border-top-left-radius: 255px 15px;
        border-top-right-radius: 15px 225px;
        border-bottom-right-radius: 225px 15px;
        border-bottom-left-radius: 15px 255px;
    }

    .Couverture {
        background-size: 127%;
        background-repeat: no-repeat;
        background-position-y: -9px;
        background-position-x: -37px;
    }

    .Portrait {
        background-size: 145%;
        background-repeat: no-repeat;
    }

    .textContent {
        font-family: "Open Sans Condensed", sans-serif;
        color: ${color5};
        letter-spacing: 1.4px;
    }

    @media only screen and (max-width: ${mediumWidth}) {
        .Couverture {
            width: 70vw;
            height: 40vw;
            background-position-x: -43px;
            margin-bottom: 5vh;
        }

        .Portrait {
            width: 20vh;
            height: 30vh;
            background-position-x: -30px;
            background-position-y: -49px;
        }

        .textContent {
            font-size: 4vw;
        }
    }

    @media only screen and (min-width: ${mediumWidth}) and (max-width: ${bigWidth}) {
        .Couverture {
            width: 70vw;
            height: 40vw;
            background-position-x: -90px;
        }

        .Portrait {
            width: 30vh;
            height: 40vh;
            background-position-x: -90px;
            background-position-y: -49px;
        }

        .textContent {
            font-size: 3vw;
        }
    }

    @media only screen and (min-width: ${bigWidth}) {
        .Couverture {
            width: 70vw;
            height: 40vw;
            background-position-x: -90px;
        }

        .Portrait {
            width: 30vh;
            height: 40vh;
            background-position-x: -90px;
            background-position-y: -49px;
        }

        .textContent {
            font-size: 2vw;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

const HomePage = () => (
    <HomePageStyled className="HomePage">
        <h1 className="Title">Benoit Bertetto Sculpteur</h1>
        <div
            style={{ backgroundImage: `url("${portrait}")` }}
            className="Portrait photo"
        ></div>
        <div className="textContent">
            <p>
                "Ben Bertetto est un sculpteur passionné par la mise en valeur
                des essences de bois locaux."
            </p>
        </div>
        <div
            style={{ backgroundImage: `url("${couverture}")` }}
            className="Couverture photo"
        ></div>
    </HomePageStyled>
);

export default HomePage;
